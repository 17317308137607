// Input.js
import React from 'react';
import '../styles/BaseNoData.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFrown} from "@fortawesome/free-solid-svg-icons";
function BaseNoData(props) {
    return (
        <div className="row align-center full-size">
            <FontAwesomeIcon className="no-data-icon" icon={faFrown} />
            <p className="no-data-text">데이터가 존재하지 않습니다.</p>
        </div>
    );
}

export default BaseNoData;
