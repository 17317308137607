import React from 'react';
import '../styles/BaseButton.css';
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function BaseButton({ className, ...props}) {
    const combinedClassName = `button-base ${className || ''}`;
    return (
        <button className={combinedClassName} {...props} />
    );
}

function BaseButtonField({ className, ...props}) {
    const combinedClassName = `button-base-field ${className || ''}`;
    return (
        <button className={combinedClassName} {...props} />
    );
}

function BaseButtonFieldAwesome({icon, ...props}) {
    return (
        <button className="button-base-awesome-field" {...props}>
            <FontAwesomeIcon icon={icon} />
        </button>
    );
}

function BaseButtonAwesome({icon, ...props}) {
    return (
        <button className="button-base-awesome" {...props}>
            <FontAwesomeIcon icon={icon} />
        </button>
    );
}

function BaseButtonAwesomeTrans({icon, ...props}) {
    return (
        <button className="button-base-awesome-trans" {...props}>
            <FontAwesomeIcon icon={icon} />
        </button>
    );
}

export { BaseButton, BaseButtonField, BaseButtonAwesome, BaseButtonAwesomeTrans, BaseButtonFieldAwesome };
