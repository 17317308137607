import "../../styles/Technician.css"
import Main from "./main";
import MainOwner from "./main_Owner";
import {useState} from "react";
function WorkHistory({type, ...props}) {
    return (
        <>
            {type === 1 ? (
                <MainOwner/>
            ) : (
                <Main/>
            )}
        </>
    );
}

export default WorkHistory;
