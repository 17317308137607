import {useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useRef, useState} from "react";
import BaseInput from "../BaseComponents/BaseInput";
import {BaseButton, BaseButtonAwesomeTrans} from "../BaseComponents/BaseButton";
import {BaseFixedImage, BaseImage, BaseImageCircle} from "../BaseComponents/BaseImage";
import {BaseAlert, BaseAlertWarn, BaseAlertDanger, BaseFullAlert} from "../BaseComponents/BaseAlert";
import logo from "../images/logo.png";
import {backendServer, WorkContext} from "../App";
import {BaseLoading} from "../BaseComponents/BaseLoading";
import {faClose, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import uploadSVG from "../images/upload-solid.svg";
import BaseTextArea from "../BaseComponents/BaseTextArea";

function WorkReportPage() {
    const { machineID, machineName } = useContext(WorkContext);

    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDone, setIsDone ] = useState(false);
    const selectedIndex = useRef(0);
    const fileInputRef = useRef(null);

    const [ alertTimeout, setAlertTimeout ] = useState(null);
    const [isAlert, setIsAlert] = useState(-1);
    const [alertMsg, setAlertMsg] = useState("");
    const setIsAlertWithTimeout = (alertType, timeOut = 3500) => {
        // 이미 설정된 setTimeout이 있다면 취소합니다.
        if (alertTimeout) {
            clearTimeout(alertTimeout);
        }

        setIsAlert(alertType);

        // 3.5초 후에 알림을 사라지게 만듭니다.
        const timeoutId = setTimeout(() => {
            setIsAlert(-1);
        }, timeOut);

        // 새로 설정된 setTimeout의 ID를 저장합니다.
        setAlertTimeout(timeoutId);
    }

    const [ values, setValues ] = useState({
        maintenance: "", special_note: ""
    });

    const loadImage = (data) => {
        return `data:image/png;base64,${data}`;
    }

    const handleImageClick = (index) => {
        selectedIndex.current = index;
        fileInputRef.current.click(); // fileInputRef를 사용하여 file input을 클릭합니다.
    }

    const [ uploadImageValues, setUploadImageValues ] = useState([
        {url: '', binary: ''},
        {url: '', binary: ''},
        {url: '', binary: ''},
    ]);


    const handleImageUpload = (imageData, binaryData, index) => {
        setUploadImageValues(prevState => {
            const newState = [...prevState]; // 이전 상태의 복사본 생성
            newState[index] = {url: imageData.split(",")[1], binary: binaryData};
            return newState;
        });
    }

    function resizeImage(file, maxWidth, maxHeight, quality = 0.8, callback) {
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');

            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            // 여기서 'image/jpeg'는 결과 이미지의 형식이며,
            // quality는 JPEG 압축률을 나타냅니다 (0~1 사이의 값).
            canvas.toBlob((blob) => {
                callback(blob);
            }, 'image/jpeg', quality);
        };
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            resizeImage(file, 800, 600, 0.8, (resizedBlob) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    handleImageUpload(e.target.result, resizedBlob, selectedIndex.current);
                };
                reader.readAsDataURL(resizedBlob);
            });
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }

    const handleSubmit = async () => {
        if(values.maintenance === ""){
            setAlertMsg("작업 내용은 필수 입력 항목입니다.");
            setIsAlertWithTimeout(1);
        } else {
            setIsLoading(true);

            try {
                const formData = new FormData();
                uploadImageValues.forEach((imageValue, index) => {
                    if (imageValue.binary) {
                        formData.append(`image${index + 1}`, imageValue.binary);
                    }
                });
                formData.append("data", JSON.stringify({...values, machine_id: machineID })); // 기타 JSON 데이터 추가

                const response = await fetch(`https://${backendServer}/work/done`, {
                    method: 'POST',
                    body: formData,
                    credentials: 'include',
                });

                if (response.ok) {
                    setIsDone(true);
                } else {
                    const errorData = await response.json();
                    if(errorData.error === "HistoryNotFound"){
                        setAlertMsg("예약된 작업이 없거나 완료된 작업입니다.");
                    }else{
                        setAlertMsg(errorData.error);
                    }
                    setIsAlertWithTimeout(0);
                }
            } catch (error) {
                setAlertMsg("서버에 연결할 수 없습니다. 관리자에게 문의해보세요.");
                setIsAlertWithTimeout(2);
            } finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <>
            {isDone ? (
                <>
                    <BaseFullAlert msg={"작업이 성공적으로 등록되었습니다."}/>
                </>
            ) : (
                <>
                    {isLoading ? (
                        <>
                            <BaseLoading/>
                        </>
                    ) : (
                        <div className="column" style={{padding: "1rem"}}>
                            <div className="row align-center full-size-width register-form-header">
                                <p className="medium-title bold">{machineName} 작업 일지</p>
                            </div>
                            <div className="row full-size-width">
                                <div className="column register-form-item">
                                    <span className="normal-info bold text-align-left">작업 내용</span>
                                    <BaseTextArea
                                        name="maintenance"
                                        placeholder="작업 내용"
                                        value={values.maintenance}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row full-size-width">
                                <div className="column register-form-item">
                                    <span className="normal-info bold text-align-left">특이사항</span>
                                    <BaseTextArea
                                        name="special_note"
                                        placeholder="특이사항"
                                        value={values.special_note}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row full-size-width">
                                <div className="column register-form-item align-center">
                                    <div className="row full-size-width">
                                        <span className="grow-size normal-info bold text-align-left">작업사진1</span>
                                        <FontAwesomeIcon icon={faTrash} onClick={() => {
                                            setUploadImageValues(prevState => {
                                                const newState = [...prevState]; // 이전 상태의 복사본 생성
                                                newState[0] = {url: '', binary: ''};
                                                return newState;
                                            });
                                        }} style={{cursor: "pointer"}}/>
                                    </div>
                                    <div className="column align-center upload-box"
                                         onClick={()=> {handleImageClick(0)}}
                                         style={!uploadImageValues[0].url ? { padding: "4rem 0" } : {}}>
                                        <BaseImage
                                            src={uploadImageValues[0].url ? loadImage(uploadImageValues[0].url) : uploadSVG}
                                            alt="machine-picture"
                                            width="100%"
                                            height="100%"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row full-size-width">
                                <div className="column register-form-item align-center">
                                    <div className="row full-size-width">
                                        <span className="grow-size normal-info bold text-align-left">작업사진2</span>
                                        <FontAwesomeIcon icon={faTrash} onClick={() => {
                                            setUploadImageValues(prevState => {
                                                const newState = [...prevState]; // 이전 상태의 복사본 생성
                                                newState[1] = {url: '', binary: ''};
                                                return newState;
                                            });
                                        }} style={{cursor: "pointer"}}/>
                                    </div>
                                    <div className="column align-center upload-box"
                                         onClick={()=> {handleImageClick(1)}}
                                         style={!uploadImageValues[1].url ? { padding: "4rem 0" } : {}}>
                                        <BaseImage
                                            src={uploadImageValues[1].url ? loadImage(uploadImageValues[1].url) : uploadSVG}
                                            alt="machine-picture"
                                            width="100%"
                                            height="100%"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row full-size-width">
                                <div className="column register-form-item align-center">
                                    <div className="row full-size-width">
                                        <span className="grow-size normal-info bold text-align-left">작업사진3</span>
                                        <FontAwesomeIcon icon={faTrash} onClick={() => {
                                            setUploadImageValues(prevState => {
                                                const newState = [...prevState]; // 이전 상태의 복사본 생성
                                                newState[2] = {url: '', binary: ''};
                                                return newState;
                                            });
                                        }} style={{cursor: "pointer"}}/>
                                    </div>
                                    <div className="column align-center upload-box"
                                         onClick={()=> {handleImageClick(2)}}
                                         style={!uploadImageValues[2].url ? { padding: "4rem 0" } : {}}>
                                        <BaseImage
                                            src={uploadImageValues[2].url ? loadImage(uploadImageValues[2].url) : uploadSVG}
                                            alt="machine-picture"
                                            width="100%"
                                            height="100%"
                                        />
                                    </div>
                                </div>
                            </div>
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                ref={fileInputRef} // fileInputRef를 file input element에 연결합니다.
                                onChange={handleImageChange}
                                accept=".png, .jpg, .jpeg"
                            />
                            <div className="seperator"></div>
                            <BaseButton onClick={handleSubmit}>제출하기</BaseButton>
                        </div>
                    )}
                    {isAlert === 0 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                        <>
                            <BaseAlert msg={alertMsg}></BaseAlert>
                        </>
                    )}
                    {isAlert === 1 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                        <>
                            <BaseAlertWarn msg={alertMsg}></BaseAlertWarn>
                        </>
                    )}
                    {isAlert === 2 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                        <>
                            <BaseAlertDanger msg={alertMsg}></BaseAlertDanger>
                        </>
                    )}
                </>
            )}

        </>

    );
}

export default WorkReportPage;
