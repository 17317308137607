import {BaseButtonAwesomeTrans} from "../../../BaseComponents/BaseButton";
import {faClose} from "@fortawesome/free-solid-svg-icons";

import React, { useRef } from "react";
import BaseSliderImageViewer from "../../../BaseComponents/BaseSliderImageViewer";

function InfoModal(props) {
    const { selectedHistory, closeClick } = props;

    function formatDate(isoString) {
        if(isoString === ""){
            return "";
        }
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');  // 월은 0부터 시작하기 때문에 1을 더해줍니다.
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    return (
        <>
            <div className="row">
                <div className="column full-size-width">
                    <div className="row align-center full-size-width register-form-header">
                        <div className="column">
                            <p className="medium-title bold" style={{whiteSpace: "nowrap", margin: "0.5rem 0"}}>최근 작업 보고서</p>
                            <p className="normal-info" style={{whiteSpace: "nowrap", margin: 0}}>플랜트명 : '{selectedHistory.machine_name}'</p>
                        </div>
                    </div>
                    <div className="row full-size-width">
                        <div className="column register-form-item">
                            <span className="normal-info bold text-align-left">유지보수 내용</span>
                            <div className="column full-size-width textarea-field">
                                <p>{selectedHistory.end_time ? selectedHistory.maintenance_details : "작 업 중"}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column register-form-item">
                            <span className="normal-info bold text-align-left">특이사항</span>
                            <div className="column full-size-width textarea-field">
                                <p>{selectedHistory.end_time ? selectedHistory.special_notes : "작 업 중"}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row full-size-width">
                        <div className="column register-form-item">
                            <span className="normal-info bold text-align-left">작업 수행 회사</span>
                            <div className="column full-size-width text-field">
                                <p style={{whiteSpace: "nowrap"}}>{selectedHistory.company_name}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row full-size-width">
                        <div className="column register-form-item">
                            <span className="normal-info bold text-align-left">작업자</span>
                            <div className="column full-size-width text-field">
                                <p style={{whiteSpace: "nowrap"}}>{selectedHistory.user_name}</p>
                            </div>
                        </div>
                        <div className="column register-form-item">
                            <span className="normal-info bold text-align-left">연락처</span>
                            <div className="column full-size-width text-field">
                                <p style={{whiteSpace: "nowrap"}}>{selectedHistory.user_phone}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row full-size-width">
                        <div className="column register-form-item">
                            <span className="normal-info bold text-align-left">작업 시작</span>
                            <div className="column full-size-width text-field">
                                <p style={{whiteSpace: "nowrap"}}>{formatDate(selectedHistory.start_time)}</p>
                            </div>
                        </div>
                        <div className="column register-form-item">
                            <span className="normal-info bold text-align-left">작업 종료</span>
                            <div className="column full-size-width text-field">
                                <p style={{whiteSpace: "nowrap"}}>{formatDate(selectedHistory.end_time)}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column full-size-width">
                    <div className="row align-center full-size-width register-form-header">
                        <p className="medium-title bold" style={{whiteSpace: "nowrap", margin: "0.5rem 0"}}>작업사진</p>
                        <BaseButtonAwesomeTrans icon={faClose} onClick={closeClick} />
                    </div>
                    <BaseSliderImageViewer data={[selectedHistory.image1,selectedHistory.image2,selectedHistory.image3]} />
                </div>
            </div>
        </>
    );
}
export default InfoModal;


