// BaseAutocompleteInput.js
import React, {useState, useRef, useEffect} from 'react';
import '../styles/BaseAutocompleteInput.css';
import {tr} from "date-fns/locale";

function BaseAutocompleteInput({ data, name, placeholder, value, onChange, displayFunc, suggestionFunc, suggestionClick }) {
    const [inputValue, setInputValue] = useState(value || '');
    const [suggestions, setSuggestions] = useState(data);
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef(null);
    const [preventBlur, setPreventBlur] = useState(false);
    const inputValueRef = useRef(inputValue);

    useEffect(() => {
        inputValueRef.current = inputValue;
    }, [inputValue]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        if (!value) {
            setSuggestions(data.slice(0, 50));
        } else {
            const newSuggestions = data
                .filter(option => suggestionFunc(option).toLowerCase().includes(value.toLowerCase())) // 수정된 부분
                .slice(0, 50);
            setSuggestions(newSuggestions);
        }

        if (onChange) {
            onChange(e);
        }
    };

    const handleInputClick = (e) => {
        const value = e.target.value;

        if (!value) {
            setSuggestions(data.slice(0, 50));
        } else {
            const newSuggestions = data
                .filter(option => suggestionFunc(option).toLowerCase().includes(value.toLowerCase())) // 수정된 부분
                .slice(0, 50);
            setSuggestions(newSuggestions);
        }
    }

    const handleMouseDown = () => {
        setPreventBlur(true);
    };

    const handleSuggestionClick = (suggestion) => {
        if(suggestionClick){
            suggestionClick(suggestion);
        }
        setInputValue(displayFunc(suggestion));
        setTimeout(() => handleBlurLogic(), 0);  // 직접 호출
        inputRef.current.blur();
    };

    const handleBlurLogic = () => {
        if (preventBlur) {
            setPreventBlur(false);
            return;
        }

        const companyNames = suggestions.map((item) => displayFunc(item));
        // 입력값이 추천 목록에 없는 경우 필드 비우기
        if (!companyNames.includes(inputValueRef.current)) {
            if(suggestionClick){
                suggestionClick(null);
            }
            setSuggestions([]);
            setInputValue('');
        }else{
            setSuggestions([suggestions.find(item => displayFunc(item) === inputValueRef.current)]);
        }

        // 목록 숨기기
        setTimeout(() => setIsFocused(false), 50);
    };

    const handleBlur = () => {
        handleBlurLogic();
    };

    return (
        <div className="column full-size">
            <input
                ref={inputRef}
                className="auto-input-base"
                name={name}
                placeholder={placeholder}
                value={inputValue}
                onChange={handleInputChange}
                onFocus={() => setIsFocused(true)}
                onBlur={handleBlur}
                onClick={handleInputClick}
                autoComplete="off"
            />
            {isFocused && (
                <ul>
                    {suggestions.length > 0 ? (
                        suggestions.map(item => (
                            <li key={displayFunc(item)} onMouseDown={handleMouseDown} onClick={() => handleSuggestionClick(item)}>
                                <div className="row full-size-width">
                                    <div className="row full-size-width suggestion-item">
                                        <p>{item.company_name}</p>
                                    </div>
                                    <div className="row full-size-width align-center-left suggestion-item">
                                        <p>{item.company_number}</p>
                                    </div>
                                </div>
                            </li>
                        ))
                    ) : (
                        <li>결과가 없습니다.</li>
                    )}
                </ul>
            )}
        </div>
    );
}

function BaseAutocompleteInput2({ data, name, placeholder, value, onChange, displayFunc, suggestionFunc, suggestionClick }) {
    const [inputValue, setInputValue] = useState(value || '');
    const [suggestions, setSuggestions] = useState(data);
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef(null);
    const [preventBlur, setPreventBlur] = useState(false);
    const inputValueRef = useRef(inputValue);

    const selectRef = useRef(null);

    useEffect(() => {
        inputValueRef.current = inputValue;
    }, [inputValue]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        if (!value) {
            setSuggestions(data.slice(0, 50));
        } else {
            const newSuggestions = data
                .filter(option => suggestionFunc(option).toLowerCase().includes(value.toLowerCase())) // 수정된 부분
                .slice(0, 50);
            setSuggestions(newSuggestions);
        }

        if (onChange) {
            onChange(e);
        }
    };

    const handleInputClick = (e) => {
        const value = e.target.value;
        selectRef.current = null;

        if (!value) {
            setSuggestions(data.slice(0, 50));

        } else {
            const newSuggestions = data
                .filter(option => suggestionFunc(option).toLowerCase().includes(value.toLowerCase())) // 수정된 부분
                .slice(0, 50);
            setSuggestions(newSuggestions);
        }
    }

    const handleMouseDown = () => {
        setPreventBlur(true);
    };

    const handleSuggestionClick = (suggestion) => {
        if(suggestionClick){
            selectRef.current = suggestion;
            suggestionClick(suggestion);
        }
        setInputValue(displayFunc(suggestion));
        setTimeout(() => handleBlurLogic(), 0);  // 직접 호출
        inputRef.current.blur();
    };

    const handleBlurLogic = () => {
        if (preventBlur) {
            setPreventBlur(false);
            return;
        }

        const companyNames = suggestions.map((item) => displayFunc(item));
        // 입력값이 추천 목록에 없는 경우 필드 비우기
        if (!companyNames.includes(inputValueRef.current)) {
            if(suggestionClick){
                suggestionClick(null);
            }
            setSuggestions([]);
            setInputValue('');
        }else{
            if(selectRef.current){
                setSuggestions([suggestions.find(item => displayFunc(item) === inputValueRef.current)]);
            }else {
                if(suggestionClick){
                    suggestionClick(null);
                }
                setSuggestions([]);
                setInputValue('');
            }
        }

        // 목록 숨기기
        setTimeout(() => setIsFocused(false), 50);
    };

    const handleBlur = () => {
        handleBlurLogic();
    };

    return (
        <div className="column full-size">
            <input
                ref={inputRef}
                className="auto-input-base"
                name={name}
                placeholder={placeholder}
                value={inputValue}
                onChange={handleInputChange}
                onFocus={() => setIsFocused(true)}
                onBlur={handleBlur}
                onClick={handleInputClick}
                autoComplete="off"
            />
            {isFocused && (
                <ul>
                    {suggestions.length > 0 ? (
                        suggestions.map(item => (
                            <li key={displayFunc(item)} onMouseDown={handleMouseDown} onClick={() => handleSuggestionClick(item)}>
                                <div className="row full-size-width">
                                    <div className="row full-size-width suggestion-item">
                                        <p>{item.name}</p>
                                    </div>
                                    <div className="row full-size-width align-center-left suggestion-item">
                                        <p>{item.phone}</p>
                                    </div>
                                </div>
                            </li>
                        ))
                    ) : (
                        <li>결과가 없습니다.</li>
                    )}
                </ul>
            )}
        </div>
    );
}


export { BaseAutocompleteInput, BaseAutocompleteInput2 };
