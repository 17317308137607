import {BaseLoadingInContainer} from "../../BaseComponents/BaseLoading";
import {BaseButton, BaseButtonAwesomeTrans} from "../../BaseComponents/BaseButton";
import {faClose, faTrash} from "@fortawesome/free-solid-svg-icons";
import BaseInput from "../../BaseComponents/BaseInput";
import React, {useRef, useState} from "react";
import {BaseImage, BaseImageSquare} from "../../BaseComponents/BaseImage";
import uploadSVG from "../../images/upload-solid.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BaseTextArea from "../../BaseComponents/BaseTextArea";
import {backendServer} from "../../App";

function ReportModal(props) {

    const [ isLoading, setIsLoading ] = useState(false);
    const fileInputRef = useRef(null); // useRef를 사용하여 참조를 생성합니다.
    const { selectedWork, closeClick, handleDone } = props;
    const [ values, setValues ] = useState({
        maintenance: "", special_note: ""
    });
    const [ uploadImageValues, setUploadImageValues ] = useState([
        {url: '', binary: ''},
        {url: '', binary: ''},
        {url: '', binary: ''},
    ]);
    const selectedIndex = useRef(0);
    const [ alertTimeout, setAlertTimeout ] = useState(null);
    const [isAlert, setIsAlert] = useState(-1);
    const [alertMsg, setAlertMsg] = useState("");
    const setIsAlertWithTimeout = (alertType, timeOut = 3500) => {
        // 이미 설정된 setTimeout이 있다면 취소합니다.
        if (alertTimeout) {
            clearTimeout(alertTimeout);
        }

        setIsAlert(alertType);

        // 3.5초 후에 알림을 사라지게 만듭니다.
        const timeoutId = setTimeout(() => {
            setIsAlert(-1);
        }, timeOut);

        // 새로 설정된 setTimeout의 ID를 저장합니다.
        setAlertTimeout(timeoutId);
    }


    const loadImage = (data) => {
        return `data:image/png;base64,${data}`;
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }

    const handleImageClick = (index) => {
        selectedIndex.current = index;
        fileInputRef.current.click(); // fileInputRef를 사용하여 file input을 클릭합니다.
    }

    const handleImageUpload = (imageData, binaryData, index) => {
        setUploadImageValues(prevState => {
            const newState = [...prevState]; // 이전 상태의 복사본 생성
            newState[index] = {url: imageData.split(",")[1], binary: binaryData};
            return newState;
        });
    }

    const handleSubmit = async () => {
        if(values.maintenance === ""){
            setAlertMsg("작업 내용은 필수 입력 항목입니다.");
            setIsAlertWithTimeout(1);
        } else {
            setIsLoading(true);

            try {
                const formData = new FormData();
                uploadImageValues.forEach((imageValue, index) => {
                    if (imageValue.binary) {
                        formData.append(`image${index + 1}`, imageValue.binary);
                    }
                });
                formData.append("data", JSON.stringify({...values, machine_id: selectedWork.machine_id, checksum: true })); // 기타 JSON 데이터 추가

                const response = await fetch(`https://${backendServer}/work/done`, {
                    method: 'POST',
                    body: formData,
                    credentials: 'include',
                });

                if (response.ok) {
                    handleDone();
                } else {
                    const errorData = await response.json();
                    setAlertMsg(errorData.error);
                    setIsAlertWithTimeout(0);
                }
            } catch (error) {
                setAlertMsg("서버에 연결할 수 없습니다. 관리자에게 문의해보세요.");
                setIsAlertWithTimeout(2);
            } finally {
                setIsLoading(false);
            }
        }
    }

    function resizeImage(file, maxWidth, maxHeight, quality = 0.8, callback) {
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');

            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob((blob) => {
                callback(blob);
            }, 'image/png', quality);
        };
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            resizeImage(file, 800, 600, 0.8, (resizedBlob) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    handleImageUpload(e.target.result, resizedBlob, selectedIndex.current);
                };
                reader.readAsDataURL(resizedBlob);
            });
        }
    }

    return (
        <>
            <div className="row align-center full-size-width register-form-header">
                <p className="medium-title bold">작업 일지 작성</p>
                <BaseButtonAwesomeTrans icon={faClose} onClick={closeClick} />
            </div>
            <div className="row full-size-width">
                <div className="column full-size-width">
                    <div className="row full-size-width">
                        <div className="row full-size-width">
                            <div className="column register-form-item">
                                <span className="normal-info bold text-align-left">작업 내용</span>
                                <BaseTextArea
                                    style={{height: 300}}
                                    name="maintenance"
                                    placeholder="작업 내용"
                                    value={values.maintenance}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="row full-size-width">
                            <div className="column register-form-item">
                                <span className="normal-info bold text-align-left">특이사항</span>
                                <BaseTextArea
                                    style={{height: 300}}
                                    name="special_note"
                                    placeholder="특이사항"
                                    value={values.special_note}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="row full-size-width">
                            <div className="column register-form-item align-center">
                                <div className="row full-size-width">
                                    <span className="grow-size normal-info bold text-align-left">작업사진1</span>
                                    <FontAwesomeIcon icon={faTrash} onClick={() => {
                                        setUploadImageValues(prevState => {
                                            const newState = [...prevState]; // 이전 상태의 복사본 생성
                                            newState[0] = {url: '', binary: ''};
                                            return newState;
                                        });
                                    }} style={{cursor: "pointer"}}/>
                                </div>
                                <div className="column align-center upload-box-web"
                                     onClick={()=> {handleImageClick(0)}}
                                     style={!uploadImageValues[0].url ? { padding: "4rem 0" } : {}}>
                                    <BaseImage
                                        src={uploadImageValues[0].url ? loadImage(uploadImageValues[0].url) : uploadSVG}
                                        alt="machine-picture"
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row full-size-width">
                            <div className="column register-form-item align-center">
                                <div className="row full-size-width">
                                    <span className="grow-size normal-info bold text-align-left">작업사진2</span>
                                    <FontAwesomeIcon icon={faTrash} onClick={() => {
                                        setUploadImageValues(prevState => {
                                            const newState = [...prevState]; // 이전 상태의 복사본 생성
                                            newState[1] = {url: '', binary: ''};
                                            return newState;
                                        });
                                    }} style={{cursor: "pointer"}}/>
                                </div>
                                <div className="column align-center upload-box-web"
                                     onClick={()=> {handleImageClick(1)}}
                                     style={!uploadImageValues[1].url ? { padding: "4rem 0" } : {}}>
                                    <BaseImage
                                        src={uploadImageValues[1].url ? loadImage(uploadImageValues[1].url) : uploadSVG}
                                        alt="machine-picture"
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row full-size-width">
                            <div className="column register-form-item align-center">
                                <div className="row full-size-width">
                                    <span className="grow-size normal-info bold text-align-left">작업사진3</span>
                                    <FontAwesomeIcon icon={faTrash} onClick={() => {
                                        setUploadImageValues(prevState => {
                                            const newState = [...prevState]; // 이전 상태의 복사본 생성
                                            newState[2] = {url: '', binary: ''};
                                            return newState;
                                        });
                                    }} style={{cursor: "pointer"}}/>
                                </div>
                                <div className="column align-center upload-box-web"
                                     onClick={()=> {handleImageClick(2)}}
                                     style={!uploadImageValues[2].url ? { padding: "4rem 0" } : {}}>
                                    <BaseImage
                                        src={uploadImageValues[2].url ? loadImage(uploadImageValues[2].url) : uploadSVG}
                                        alt="machine-picture"
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={fileInputRef} // fileInputRef를 file input element에 연결합니다.
                    onChange={handleImageChange}
                    accept=".png, .jpg, .jpeg"
                />
            </div>
            <div className="seperator"></div>
            <BaseButton onClick={handleSubmit}>제출하기</BaseButton>
        </>
    );
}
export default ReportModal;


