// BaseImage.js
import React from 'react';
import '../styles/BaseImage.css';

function BaseImage({src, alt, width, height, ...props}) {
    return (
        <img
            className="image-base"
            src={src}
            alt={alt}
            width={width}
            height={height}
            {...props}
        />
    );
}

function BaseFixedImage({src, alt, width, height, ...props}) {
    return (
        <img
            src={src}
            alt={alt}
            width={width}
            height={height}
            style={{margin: "8px"}}
            {...props}
        />
    );
}

function BaseImageCircle({src, alt, width, height, ...props}) {
    return (
        <img className="image-base circle"
               src={src}
               alt={alt}
               width={width}
               height={height}
               {...props}
        />
    );
}

function BaseImageCircleTrans({src, alt, width, height, ...props}) {
    return (
        <img className="image-base circle"
             src={src}
             alt={alt}
             width={width}
             height={height}
             {...props}
        />
    );
}

function BaseImageSquare({src, alt, width, height, ...props}) {
    return (
        <img className="square"
               src={src}
               alt={alt}
               width={width}
               height={height}
               {...props}
        />
    );
}

export { BaseImage, BaseFixedImage, BaseImageCircle, BaseImageSquare };
