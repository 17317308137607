import React, {useState, useEffect, useContext, useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAdd,
    faArrowLeft,
    faCogs,
    faCrown,
    faDownload,
    faHome,
    faSearch,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import BaseInput from "../../BaseComponents/BaseInput";
import {BaseButtonAwesome, BaseButtonField} from "../../BaseComponents/BaseButton";
import {GlobalContext, MachineContext, TechnicianContext} from "../../pages/AdmHomePage";
import { BaseLoading, BaseLoadingInContainer } from "../../BaseComponents/BaseLoading";
import {FixedSizeList as List} from "react-window";
import ResizeObserver from "resize-observer-polyfill";
import BaseNoData from "../../BaseComponents/BaseNoData";
import InfoModal from "../../modals/machine/history/InfoModal";
import SliderImageView from "../../modules/Slider";
import {backendServer} from "../../App";
import * as XLSX  from 'xlsx-js-style';
import {BaseAlert, BaseAlertDanger, BaseAlertWarn} from "../../BaseComponents/BaseAlert";

const ITEM_SIZE = 44;
const widthPercentage = ['100px','150px','250px','200px','250px','250px','200px','250px','250px','200px','250px','250px','600px','400px','200px'];
function History() {
    const { activeMCHistory, setActiveMCHistory } = useContext(MachineContext);
    const { userData, setUserData, isInit, setIsInit, timeRemainingRef } = useContext(GlobalContext);

    const [ isLoading, setIsLoading ] = useState(false);
    const [ isRegLoading, setIsRegLoading ] = useState(false);

    const divRef = useRef(null);
    const [listHeight, setListHeight] = useState(468);
    const [containerWidth, setContainerWidth] = useState(0);
    const containerRef = useRef(null);

    const [ isModal, setIsModal ] = useState(false);
    const [ modalType, setModalType ] = useState(-1);
    const [ selectedHistory, setSelectedHistory ] = useState(null);

    const [ alertTimeout, setAlertTimeout ] = useState(null);
    const [ isAlert, setIsAlert] = useState(-1);
    const [alertMsg, setAlertMsg] = useState("");
    const setIsAlertWithTimeout = (alertType, time = 3500) => {
        // 이미 설정된 setTimeout이 있다면 취소합니다.
        if (alertTimeout) {
            clearTimeout(alertTimeout);
        }

        setIsAlert(alertType);

        // 3.5초 후에 알림을 사라지게 만듭니다.
        const timeoutId = setTimeout(() => {
            setIsAlert(-1);
        }, time);

        // 새로 설정된 setTimeout의 ID를 저장합니다.
        setAlertTimeout(timeoutId);
    }

    const handleCloseModal = () => {
        setModalType(-1);
        setIsModal(false);
    }

    const handleOpenModal = (type) => {
        setModalType(type);
        setIsModal(true);
    }

    const [ searchValues, setSearchValues ] = useState({
        machine_name: "", requested_company_name: "", user_name : '',
        start_time : '', start_end_time : '', end_time : '', end_end_time : ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;

        setSearchValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setContainerWidth(entry.contentRect.width);
            }
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [containerRef]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setListHeight(entry.contentRect.height);
            }
        });

        if (divRef.current) {
            resizeObserver.observe(divRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [divRef]);

    const handleExportExcel = () => {
        if(activeMCHistory.datas.length === 1){
            setAlertMsg("출력할 데이터가 없습니다.");
            setIsAlertWithTimeout(1);
            return;
        }

        function formatDate(isoString) {
            if (!isoString || isNaN(new Date(isoString).getTime())) {
                return "";
            }

            const date = new Date(isoString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');  // 월은 0부터 시작하기 때문에 1을 더해줍니다.
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }

        const borderStyle = {
            style: 'thick',
            color: { rgb: '548235' }
        };

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([[]]);

        const columnWidths = [
            { wch: 1.86 },
            { wch: 3.00 },
            { wch: 19.43 },
            { wch: 5.29 },
            { wch: 14.14 },
            { wch: 28.14 },
            { wch: 2.71 },
            { wch: 2.71 },
            { wch: 2.71 },
            { wch: 2.71 },
            { wch: 9.51 },
            { wch: 10.71 },
            { wch: 3.29 },
            { wch: 4.00 },
            { wch: 2.71 },
            { wch: 4.43 },
            { wch: 5.41 },
            { wch: 9.57 },
            { wch: 8.00 },
        ];

        ws['!cols'] = columnWidths;

        ws['!rows'] = [
            {}, // 인덱스 0
            {}, // 인덱스 1
            {}, // 인덱스 2
            { hpx: 24.75 }, // B4, 인덱스 3
            { hpx: 24.75 }, // B5, 인덱스 4
            { hpx: 24.75 }, // B6, 인덱스 5
            { hpx: 24.75 }, // B7, 인덱스 6
            { hpx: 24.75 }, // B8, 인덱스 7
            { hpx: 24.75 }, // B9, 인덱스 8
            { hpx: 24.75 }, // B10, 인덱스 9
            { hpx: 24.75 }, // B11, 인덱스 10
            { hpx: 10.50 }, // B12, 인덱스 11
            { hpx: 10.50 }, // B13, 인덱스 12
            { hpx: 24.75 },
        ];

        // A1부터 S1까지의 범위를 하나의 셀로 병합
        ws['!merges'] = [
            { s: { r: 1, c: 1 }, e: { r: 1, c: 18 } },
            { s: { r: 3, c: 1 }, e: { r: 3, c: 2 } },
            { s: { r: 4, c: 1 }, e: { r: 4, c: 2 } },
            { s: { r: 5, c: 1 }, e: { r: 5, c: 2 } },
            { s: { r: 6, c: 1 }, e: { r: 6, c: 2 } },
            { s: { r: 7, c: 1 }, e: { r: 7, c: 2 } },
            { s: { r: 8, c: 1 }, e: { r: 8, c: 2 } },
            { s: { r: 9, c: 1 }, e: { r: 9, c: 2 } },
            { s: { r: 10, c: 1 }, e: { r: 10, c: 2 } },
            { s: { r: 3, c: 3 }, e: { r: 3, c: 11 } }, // D4-L4
            { s: { r: 4, c: 3 }, e: { r: 4, c: 11 } }, // D5-L5
            { s: { r: 5, c: 3 }, e: { r: 5, c: 11 } }, // D6-L6
            { s: { r: 6, c: 3 }, e: { r: 6, c: 11 } }, // D7-L7
            { s: { r: 7, c: 3 }, e: { r: 7, c: 11 } }, // D8-L8
            { s: { r: 8, c: 3 }, e: { r: 8, c: 11 } }, // D9-L9
            { s: { r: 9, c: 3 }, e: { r: 9, c: 11 } }, // D10-L10
            { s: { r: 10, c: 3 }, e: { r: 10, c: 11 } }, // D11-L11
            { s: { r: 3, c: 12 }, e: { r: 10, c: 18 } }, // M4-S11
            { s: { r: 13, c: 3 }, e: { r: 13, c: 4 } }, // D11-E11
            { s: { r: 13, c: 6 }, e: { r: 13, c: 10 } }, // G11-K11
            { s: { r: 13, c: 11 }, e: { r: 13, c: 13 } }, // M11-N11
            { s: { r: 13, c: 14 }, e: { r: 13, c: 16 } }, // O11-P11
            { s: { r: 13, c: 17 }, e: { r: 13, c: 18 } }, // O11-P11
        ];

        const data = [
            [activeMCHistory.machine_name, '', '', '', '', '', '', '', ''],
            [activeMCHistory.serial_number, '', '', '', '', '', '', '', ''],
            [activeMCHistory.installation_date.split("T")[0], '', '', '', '', '', '', '', ''],
            [activeMCHistory.purchase_date.split("T")[0], '', '', '', '', '', '', '', ''],
            [activeMCHistory.model_name, '', '', '', '', '', '', '', ''],
            [activeMCHistory.manager_name, '', '', '', '', '', '', '', ''],
            [activeMCHistory.manager_contact, '', '', '', '', '', '', '', ''],
            [activeMCHistory.location, '', '', '', '', '', '', '', ''],
        ];

        const header = [
            ['No', '시작시간', '종료시간', '', '유지보수내용', '특이사항', '', '', '' , '' , '작업회사', '', '', '작업자', '', '', '작업자연락처', ''],
        ];

        const body = [];

        for (let i = 1; i < activeMCHistory.datas.length; i++) {
            ws['!merges'].push(
                { s: { r: 13+i, c: 3 }, e: { r: 13+i, c: 4 } },
                { s: { r: 13+i, c: 6 }, e: { r: 13+i, c: 10 } },
                { s: { r: 13+i, c: 11 }, e: { r: 13+i, c: 13 } },
                { s: { r: 13+i, c: 14 }, e: { r: 13+i, c: 16 } },
                { s: { r: 13+i, c: 17 }, e: { r: 13+i, c: 18 } },
            )

            ws['!rows'].push(
                { hpx: 23.25 }
            )

            const history = activeMCHistory.datas[i];
            const row = [
                i,
                formatDate(history.start_time),
                history.end_time ? formatDate(history.end_time) : "미완료",
                '',
                history.maintenance_details ? history.maintenance_details : "작업 중",
                history.special_notes ? history.special_notes : "작업 중",
                '',
                '',
                '',
                '',
                history.company_name,
                '',
                '',
                history.user_name,
                '',
                '',
                history.user_phone,
                '',
            ];
            body.push(row);
        }

        // 수정된 부분: B2 셀에 데이터 설정
        XLSX.utils.sheet_add_aoa(ws, [['플랜트 이력 카드', '플랜트 이력 카드']], { origin: 'B2' });

        XLSX.utils.sheet_add_aoa(ws, [['플랜트명', '플랜트명']], { origin: 'B4' });
        XLSX.utils.sheet_add_aoa(ws, [['시리얼 넘버', '시리얼 넘버']], { origin: 'B5' });
        XLSX.utils.sheet_add_aoa(ws, [['설치일', '설치일']], { origin: 'B6' });
        XLSX.utils.sheet_add_aoa(ws, [['구매일', '구매일']], { origin: 'B7' });
        XLSX.utils.sheet_add_aoa(ws, [['모델명', '모델명']], { origin: 'B8' });
        XLSX.utils.sheet_add_aoa(ws, [['장비담당자', '장비담당자']], { origin: 'B9' });
        XLSX.utils.sheet_add_aoa(ws, [['장비담당자연락처', '장비담당자연락처']], { origin: 'B10' });
        XLSX.utils.sheet_add_aoa(ws, [['플랜트 위치', '플랜트 위치']], { origin: 'B11' });

        XLSX.utils.sheet_add_aoa(ws, data, { origin: 'D4' });
        XLSX.utils.sheet_add_aoa(ws, header, { origin: 'B14' });
        XLSX.utils.sheet_add_aoa(ws, body, { origin: 'B15' });

        // 스타일 적용
        let cell = ws['B2'];
        cell.s = {
            font: { name: '맑은 고딕', bold: true, sz: 22, color: { rgb: '548235' } },
            alignment: { horizontal: 'center' },
        };

        let range = XLSX.utils.decode_range('B4:C11');
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
                const cell = ws[cellRef];

                if (!cell) {
                    ws[cellRef] = {
                        s: {
                            font: { name: '맑은 고딕', bold: true, sz: 9 },
                            alignment: { horizontal: 'center', vertical: 'center' },
                            fill: { fgColor: { rgb: 'F2F2F2' } },
                            border: {
                                bottom: {
                                    style: 'thin',
                                    color: { rgb: 'C7CCD9' },
                                }
                            }
                        }
                    };
                } else {
                    if (!cell.s) {
                        cell.s = {
                            font: { name: '맑은 고딕', bold: true, sz: 9 },
                            alignment: { horizontal: 'center', vertical: 'center' },
                            fill: { fgColor: { rgb: 'F2F2F2' } },
                            border: {
                                bottom: {
                                    style: 'thin',
                                    color: { rgb: 'C7CCD9' },
                                }
                            }
                        };
                    }
                }
            }
        }

        range = XLSX.utils.decode_range('M4:S11');
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
                const cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = {
                        s: {
                            font: { name: '맑은 고딕', bold: true, sz: 9 },
                            alignment: { horizontal: 'center', vertical: 'center' },
                            fill: { fgColor: { rgb: 'F2F2F2' } },
                        }
                    }
                } else {
                    if (!cell.s) {
                        cell.s = {
                            font: { name: '맑은 고딕', bold: true, sz: 9 },
                            alignment: { horizontal: 'center', vertical: 'center' },
                            fill: { fgColor: { rgb: 'F2F2F2' } },
                        };
                    }
                }
            }
        }


        for (let col = 1; col <= 18; col++) {
            const cellRef = XLSX.utils.encode_cell({ r: 3, c: col });
            const cell = ws[cellRef];
            if (!cell) {
                ws[cellRef] = {
                    s: {
                        border: {
                            top: borderStyle
                        }
                    }
                };
            } else {
                if (!cell.s) {
                    cell.s = {};
                }
                if (!cell.s.border) {
                    cell.s.border = {};
                }
                cell.s.border.top = borderStyle;
            }
        }

        range = XLSX.utils.decode_range('D4:L11');

        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
                const cell = ws[cellRef];
                if(!cell){
                    ws[cellRef] = {
                        s: {
                            font: { name: '맑은 고딕', sz: 9 },
                            alignment: { horizontal: 'left', vertical: 'center' },
                            border: {
                                bottom: {
                                    style: 'thin',
                                    color: { rgb: 'C7CCD9' }
                                },
                            }
                        }
                    };
                } else {
                    if (!cell.s) {
                        cell.s = {
                            font: { name: '맑은 고딕', sz: 9 },
                            alignment: { horizontal: 'left', vertical: 'center' },
                        };
                    }else{
                        cell.s.font = { name: '맑은 고딕', sz: 9 };
                        cell.s.alignment = { horizontal: 'left', vertical: 'center' };
                    }
                    if (!cell.s.border) {
                        cell.s.border = {};
                    }
                    cell.s.border.bottom = {
                        style: 'thin',
                        color: { rgb: 'C7CCD9' }
                    };
                }
            }
        }

        range = XLSX.utils.decode_range('B14:S14');
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
                const cell = ws[cellRef];
                if(!cell){
                    ws[cellRef] = {
                        s: {
                            font: { name: '맑은 고딕', bold: true, sz: 9 },
                            alignment: { horizontal: 'center', vertical: 'center' },
                            fill: { fgColor: { rgb: 'F2F2F2' } },
                            border: {
                                top: borderStyle,
                                right: {
                                    style: 'thin',
                                    color: { rgb: 'C7CCD9' },
                                },
                                bottom: {
                                    style: 'thin',
                                    color: { rgb: 'C7CCD9' },
                                }
                            }
                        }
                    }
                }else{
                    if (!cell.s) {
                        cell.s = {
                            font: { name: '맑은 고딕', bold: true, sz: 9 },
                            alignment: { horizontal: 'center', vertical: 'center' },
                            fill: { fgColor: { rgb: 'F2F2F2' } },
                            border: {
                                top: borderStyle,
                                right: {
                                    style: 'thin',
                                    color: { rgb: 'C7CCD9' },
                                },
                                bottom: {
                                    style: 'thin',
                                    color: { rgb: 'C7CCD9' },
                                }
                            }
                        }
                    }
                }
            }
        }

        if(activeMCHistory.datas.length > 1){
            for(let i=1; i<activeMCHistory.datas.length; i++){
                let cellRef = XLSX.utils.encode_cell({ r: 13 + i, c: 1 });
                let cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'center', vertical: 'center' },
                };
                cell.s.border = {
                    right: { style: 'thin', color: { rgb: 'C7CCD9' } },
                    bottom: (i+1) === activeMCHistory.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };

                cellRef = XLSX.utils.encode_cell({ r: 13 + i, c: 2 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    right: { style: 'thin', color: { rgb: 'C7CCD9' } },
                    bottom: (i+1) === activeMCHistory.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };

                cellRef = XLSX.utils.encode_cell({ r: 13 + i, c: 3 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    bottom: (i+1) === activeMCHistory.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };
                cellRef = XLSX.utils.encode_cell({ r: 13 + i, c: 4 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    right: { style: 'thin', color: { rgb: 'C7CCD9' } },
                    bottom: (i+1) === activeMCHistory.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };

                cellRef = XLSX.utils.encode_cell({ r: 13 + i, c: 5 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    right: { style: 'thin', color: { rgb: 'C7CCD9' } },
                    bottom: (i+1) === activeMCHistory.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };

                cellRef = XLSX.utils.encode_cell({ r: 13 + i, c: 6 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    bottom: (i+1) === activeMCHistory.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };
                cellRef = XLSX.utils.encode_cell({ r: 13 + i, c: 7 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    bottom: (i+1) === activeMCHistory.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };
                cellRef = XLSX.utils.encode_cell({ r: 13 + i, c: 8 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    bottom: (i+1) === activeMCHistory.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };
                cellRef = XLSX.utils.encode_cell({ r: 13 + i, c: 9 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    bottom: (i+1) === activeMCHistory.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };
                cellRef = XLSX.utils.encode_cell({ r: 13 + i, c: 10 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    right: { style: 'thin', color: { rgb: 'C7CCD9' } },
                    bottom: (i+1) === activeMCHistory.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };

                cellRef = XLSX.utils.encode_cell({ r: 13 + i, c: 11 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    bottom: (i+1) === activeMCHistory.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };
                cellRef = XLSX.utils.encode_cell({ r: 13 + i, c: 12 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    bottom: (i+1) === activeMCHistory.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };
                cellRef = XLSX.utils.encode_cell({ r: 13 + i, c: 13 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    right: { style: 'thin', color: { rgb: 'C7CCD9' } },
                    bottom: (i+1) === activeMCHistory.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };

                cellRef = XLSX.utils.encode_cell({ r: 13 + i, c: 14 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'center', vertical: 'center' },
                };
                cell.s.border = {
                    bottom: (i+1) === activeMCHistory.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };
                cellRef = XLSX.utils.encode_cell({ r: 13 + i, c: 15 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'center', vertical: 'center' },
                };
                cell.s.border = {
                    bottom: (i+1) === activeMCHistory.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };
                cellRef = XLSX.utils.encode_cell({ r: 13 + i, c: 16 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'center', vertical: 'center' },
                };
                cell.s.border = {
                    right: { style: 'thin', color: { rgb: 'C7CCD9' } },
                    bottom: (i+1) === activeMCHistory.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };

                cellRef = XLSX.utils.encode_cell({ r: 13 + i, c: 17 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'center', vertical: 'center' },
                };
                cell.s.border = {
                    bottom: (i+1) === activeMCHistory.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };
                cellRef = XLSX.utils.encode_cell({ r: 13 + i, c: 18 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'center', vertical: 'center' },
                };
                cell.s.border = {
                    right: { style: 'thin', color: { rgb: 'C7CCD9' } },
                    bottom: (i+1) === activeMCHistory.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };
            }
        }

        // 워크북에 시트 추가
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        const baseFileName = `[${activeMCHistory.machine_name}]이력 카드 ${formattedDate}`
        // 엑셀 파일로 저장
        XLSX.writeFile(wb, `${baseFileName}.xlsx`);

        // 이미지 다운로드
        const imageBase64 = activeMCHistory.equipment_image;
        const imageSrc = `data:image/jpeg;base64,${imageBase64}`;
        const link = document.createElement('a');
        link.href = imageSrc;
        link.download = `${baseFileName}_플랜트사진.jpeg`; // 이미지 파일명 설정
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const getMachineHistories = async () => {
        const historyHeader = {
            status: "상태",
            user_name: "작업자",
            user_phone: "연락처",
            company_name: "작업 회사",
            company_phone: "회사 번호",
            machine_name: "작업 플랜트",
            start_time: "시작 시간",
            end_time: "종료 시간",
            maintenance_details: "유지보수 내용",
            special_notes: "특이사항",
            images: "작업사진"
        }
        setIsLoading(true);
        try{
            const requestBody = {
                machine_id: activeMCHistory.machine_id,
                searchCriteria: searchValues  // 추가된 부분
            };

            const response = await fetch(`https://${backendServer}/get-histories/machine`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody),
                credentials: 'include'
            });

            if (response.ok) {
                const newData = await response.json();
                const updatedMCHistory = { ...activeMCHistory };
                updatedMCHistory.datas = [ historyHeader, ...newData ];
                setActiveMCHistory(updatedMCHistory);
            } else {
                const errorData = await response.json();
                if(errorData.error === 'SessionExpired') {
                    setUserData(null);
                    setIsInit(true);
                    throw new Error('Session has expired.'); // 이후의 처리를 중단하고 catch로 이동하기 위해 에러를 발생시킵니다.
                }else if(errorData.error === 'NotFound'){
                    setAlertMsg("사용자를 찾을 수 없습니다.");
                    setIsAlertWithTimeout(2);
                }else if(errorData.error === 'NotLoggedIn'){
                    setUserData(null);
                    setIsInit(true);
                }
            }
        }catch (e){
            setAlertMsg("서버에 연결할 수 없습니다. 관리자에게 문의해보세요.");
            setIsAlertWithTimeout(2);
        }finally {
            setIsLoading(false);
        }
    }

    function TableRow({ index, style, data }) {
        const history = data[index];

        const rowStyle = {
            ...style,
            top: ITEM_SIZE * index - 16,
        };

        const headerStyle = {
            height: '28px'
        };

        const bodyStyle = {
            backgroundColor: index % 2 === 1 ? '#e8e8e8' : 'transparent',
        }

        if (containerWidth < 1510) {
            headerStyle.width = '1500px';
            bodyStyle.width = '1500px';
        }

        const getStyle = (widthPercentage, isFirst) => {
            if (containerWidth < 1510){
                if(isFirst){
                    return { width: widthPercentage,
                        backgroundColor: index % 2 === 1 ? '#e8e8e8' : '#FFFFFF',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }else{
                    return { width: widthPercentage,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }
            }else{
                if(isFirst){
                    return { flex: widthPercentage,
                        backgroundColor: index % 2 === 1 ? '#e8e8e8' : '#FFFFFF',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }else{
                    return { flex: widthPercentage,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }
            }
        }

        function formatDate(isoString) {
            if (!isoString || isNaN(new Date(isoString).getTime())) {
                return "";
            }

            const date = new Date(isoString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');  // 월은 0부터 시작하기 때문에 1을 더해줍니다.
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }

        const openImage = (data) => {
            if (data && (data[0] || data[1] || data[2])) {
                const imageHTML = new SliderImageView(data);
                const imageWindow = window.open();
                imageWindow.document.write(imageHTML.getHTML());
            } else {
                setAlertMsg("저장된 이미지가 없습니다.");
                setIsAlertWithTimeout(1);
            }
        }

        return (
            <>
                {index === 0 ? (
                    <>
                        <div className="row full-size-width table-header" style={headerStyle}>
                            <div className="column align-center first-child" style={getStyle(widthPercentage[0])}>
                                <p>{history.status}</p>
                            </div>
                            <div className="column align-center" style={getStyle(widthPercentage[9])}>
                                <p>{history.machine_name}</p>
                            </div>
                            <div className="column align-center" style={getStyle(widthPercentage[9])}>
                                <p>{history.company_name}</p>
                            </div>
                            <div className="column align-center" style={getStyle(widthPercentage[12])}>
                                <p>{history.maintenance_details}</p>
                            </div>
                            <div className="column align-center" style={getStyle(widthPercentage[13])}>
                                <p>{history.special_notes}</p>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div style={rowStyle}>
                            <div className="row full-size-width table-item" onClick={()=> {
                                setSelectedHistory(history);
                                handleOpenModal(0);
                            }} style={bodyStyle}>
                                <div className="column align-center first-column" style={getStyle(widthPercentage[0], true)}>
                                    <p>{(history.start_time && history.end_time) ? "완료" : "작업 중"}</p>
                                </div>
                                <div className="column align-center" style={getStyle(widthPercentage[9])}>
                                    <p>{history.machine_name}</p>
                                </div>
                                <div className="column align-center" style={getStyle(widthPercentage[9])}>
                                    <p>{history.company_name}</p>
                                </div>
                                <div className="column align-left-center" style={getStyle(widthPercentage[12])}>
                                    <p>{history.maintenance_details}</p>
                                </div>
                                <div className="column align-left-center" style={getStyle(widthPercentage[13])}>
                                    <p>{history.special_notes}</p>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    }

    return (
        <div className="full-size column align-center">
            {isLoading && (
                <>
                    <BaseLoading/>
                </>
            )}
            <div className="seperator-half"></div>
            <div className="row align-center-left main-table-header full-size-width">
                <FontAwesomeIcon className="menu" icon={faArrowLeft} onClick={() => setActiveMCHistory(null)}/>
                <div className="seperator-horz"></div>
                <p className="medium-title bold">플랜트정보관리</p>
                <p className="medium-info bold">/</p>
                {activeMCHistory.datas ? (
                    <>
                        <p className="medium-info bold">{activeMCHistory.machine_name} 작업이력</p>
                    </>
                ) : (
                    <>
                        <p className="medium-info bold">작업내역 없음</p>
                    </>
                )}
            </div>
            <div className="row full-size-width">
                <div className="column grow-size align-center search-info-container">
                    <p className="normal-info bold text-align-left full-size-width">검색 기준</p>
                    <div className="row full-size-width">
                        <BaseInput
                            name="requested_company_name"
                            aria-description="condition"
                            value={searchValues.requested_company_name}
                            placeholder="유지보수업체"
                            onChange={handleChange}
                        />
                        <div className="seperator-horz"></div>
                        <BaseInput
                            name="user_name"
                            aria-description="condition"
                            value={searchValues.user_name}
                            placeholder="작업자"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="column grow-size align-center search-info-container">
                    <p className="normal-info bold text-align-left full-size-width">작업 시작일 기준</p>
                    <div className="row full-size-width">
                        <BaseInput
                            name="start_time"
                            type="date"
                            data-placeholder="검색시작일"
                            aria-description="condition"
                            value={searchValues.start_time}
                            required
                            aria-required="true"
                            onChange={handleChange}
                        />
                        <div className="seperator-horz"></div>
                        <BaseInput
                            name="start_end_time"
                            type="date"
                            data-placeholder="검색종료일"
                            aria-description="condition"
                            value={searchValues.start_end_time}
                            required
                            aria-required="true"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="column grow-size align-center search-info-container">
                    <p className="normal-info bold text-align-left full-size-width">작업 종료일 기준</p>
                    <div className="row full-size-width">
                        <BaseInput
                            name="end_time"
                            type="date"
                            data-placeholder="검색시작일"
                            aria-description="condition"
                            value={searchValues.end_time}
                            required
                            aria-required="true"
                            onChange={handleChange}
                        />
                        <div className="seperator-horz"></div>
                        <BaseInput
                            name="end_end_time"
                            type="date"
                            data-placeholder="검색종료일"
                            aria-description="condition"
                            value={searchValues.end_end_time}
                            required
                            aria-required="true"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="column align-center buttons">
                    <BaseButtonAwesome icon={faSearch} onClick={getMachineHistories}/>
                </div>
            </div>
            <div className="column grow-size full-size-width">
                <div className="row full-size-width">
                    <div className="column full-size-width align-right-center">
                        <div className="buttons">
                            <BaseButtonAwesome onClick={handleExportExcel} className="column align-center button-base-awesome" aria-description="attach" icon={faDownload}/>
                        </div>
                    </div>
                </div>
                <div className="column main-table-body" ref={containerRef}>
                    <div className="table-container-h2" ref={divRef}>
                        <>
                            {activeMCHistory.datas.length === 1 ? (
                                <>
                                    <BaseNoData/>
                                </>
                            ) : (
                                <>
                                    <div className="row table-body-container">
                                        <List
                                            width={containerWidth}
                                            height={listHeight}
                                            className="table full-size-width"
                                            itemCount={activeMCHistory.datas.length}
                                            itemSize={ITEM_SIZE}
                                            itemData={activeMCHistory.datas}
                                        >
                                            {TableRow}
                                        </List>
                                    </div>
                                </>
                            )}
                        </>
                    </div>
                </div>
            </div>
            { isModal && modalType === 0 && selectedHistory && (
                <>
                    <div className="modal full-size column align-center">
                        <div className="register-form" style={{width: "50%"}}>
                            {isRegLoading ? (
                                <>
                                    <BaseLoadingInContainer/>
                                </>
                            ):(
                                <>
                                    <InfoModal
                                        selectedHistory = {selectedHistory}
                                        closeClick={() => {
                                            handleCloseModal()
                                            setSelectedHistory(null)
                                        }}
                                    />
                                </>
                            )}

                        </div>
                    </div>
                </>
            )}
            <div className="seperator"></div>
            {isAlert === 0 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                <>
                    <BaseAlert msg={alertMsg}></BaseAlert>
                </>
            )}
            {isAlert === 1 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                <>
                    <BaseAlertWarn msg={alertMsg}></BaseAlertWarn>
                </>
            )}
            {isAlert === 2 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                <>
                    <BaseAlertDanger msg={alertMsg}></BaseAlertDanger>
                </>
            )}
        </div>
    );
}

export default History;
