import {BaseLoadingInContainer} from "../../BaseComponents/BaseLoading";
import {BaseButton, BaseButtonAwesomeTrans} from "../../BaseComponents/BaseButton";
import {faClose, faTrash} from "@fortawesome/free-solid-svg-icons";
import BaseInput from "../../BaseComponents/BaseInput";
import React, { useRef } from "react";
import {BaseImage, BaseImageSquare} from "../../BaseComponents/BaseImage";
import uploadSVG from "../../images/upload-solid.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BaseTextArea from "../../BaseComponents/BaseTextArea";
import {BaseAutocompleteInput} from "../../BaseComponents/BaseAutocompleteInput";
import * as XLSX from "xlsx-js-style";

function RequestModal(props) {
    const { selectedRequest, handleAccept, handleReject, closeClick } = props;

    return (
        <>
            <div className="row align-center full-size-width register-form-header">
                <p className="medium-title bold">유지보수 요청서</p>
                <BaseButtonAwesomeTrans icon={faClose} onClick={closeClick} />
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">요청 회사</span>
                    <div className="column full-size-width text-field">
                        <p>{selectedRequest.company_name}</p>
                    </div>
                </div>
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">회사 번호</span>
                    <div className="column full-size-width text-field">
                        <p>{selectedRequest.company_phone ? selectedRequest.company_phone : "없음"}</p>
                    </div>
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">요청 플랜트</span>
                    <div className="column full-size-width text-field">
                        <p>{selectedRequest.machine_name}</p>
                    </div>
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">위치</span>
                    <div className="column full-size-width text-field">
                        <p>{selectedRequest.location}</p>
                    </div>
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">요청 내용</span>
                    <div className="column full-size-width textarea-field">
                        <p>{selectedRequest.request_description}</p>
                    </div>
                </div>
            </div>
            <div className="seperator"></div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <BaseButton onClick={handleAccept}>수락하기</BaseButton>
                </div>
                <div className="column register-form-item">
                    <BaseButton aria-description="cancle" onClick={handleReject}>거절하기</BaseButton>
                </div>
            </div>

        </>
    );
}
export default RequestModal;


