import React, { useContext, useEffect } from 'react';

import { auth } from "../modules/firebase.js";
import { isSignInWithEmailLink, signInWithEmailLink, EmailAuthProvider, linkWithCredential } from "firebase/auth";
import { UserContext } from '../App.js';
import {BaseLoading} from "../BaseComponents/BaseLoading";
import { useNavigate } from "react-router-dom";

function FinishSignUp() {
    const { uid, setUid } = useContext(UserContext);
    let navigate = useNavigate();

    useEffect(() => {
        if (uid) {
            navigate(`/adm/home`);
        }
    }, [uid]);

    useEffect(() => {
        setTimeout(() => {
            if (isSignInWithEmailLink(auth, window.location.href)) {
                const email = window.localStorage.getItem('emailForSignIn');

                if (email) {
                    if(auth.currentUser){
                        let credential = EmailAuthProvider.credentialWithLink(email, window.location.href);
                        linkWithCredential(auth.currentUser, credential)
                            .then((usercred) => {
                                // The email provider is linked to the current user
                                const user = usercred.user;
                                setUid(user.uid); // 이제 uid는 연결된 계정의 uid가 됩니다
                            })
                            .catch((error) => {
                                // An error occurred
                                console.error("Account linking error", error);
                            });
                    }else{
                        signInWithEmailLink(auth, email, window.location.href)
                            .then((result) => {
                                // User is signed in.
                                const user = result.user;
                                setUid(user.uid);
                            })
                            .catch((error) => {
                                console.error("Error signing in with email link", error);
                            });
                    }
                }else{
                    console.log("Not Find Email");
                }
            }
        }, 3000);
    }, []);


    return (
        <div className="column align-center full-size login-body-theme">
            {!uid && (
                <>
                    <BaseLoading/>
                </>
            )}
        </div>
    );
}

export default FinishSignUp;
