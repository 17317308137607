import {BaseLoadingInContainer} from "../../BaseComponents/BaseLoading";
import {BaseButton, BaseButtonAwesomeTrans} from "../../BaseComponents/BaseButton";
import {faClose, faTrash} from "@fortawesome/free-solid-svg-icons";
import BaseInput from "../../BaseComponents/BaseInput";
import React, { useRef } from "react";
import {BaseImage, BaseImageSquare} from "../../BaseComponents/BaseImage";
import uploadSVG from "../../images/upload-solid.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function RegModal(props) {
    const fileInputRef = useRef(null); // useRef를 사용하여 참조를 생성합니다.
    const { values, imageValue, closeClick, handleChange, handleSubmit, handleClick, onImageUpload, deleteImage } = props;

    const loadImage = (data) => {
        return `data:image/png;base64,${data}`;
    }

    const handleImageClick = () => {
        fileInputRef.current.click(); // fileInputRef를 사용하여 file input을 클릭합니다.
    }

    function resizeImage(file, maxWidth, maxHeight, quality = 0.8, callback) {
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');

            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            // 여기서 'image/jpeg'는 결과 이미지의 형식이며,
            // quality는 JPEG 압축률을 나타냅니다 (0~1 사이의 값).
            canvas.toBlob((blob) => {
                callback(blob);
            }, 'image/jpeg', quality);
        };
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            resizeImage(file, 800, 600, 0.8, (resizedBlob) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    onImageUpload(e.target.result, resizedBlob);
                };
                reader.readAsDataURL(resizedBlob);
            });
        }
    }

    return (
        <>
            <div className="row align-center full-size-width register-form-header">
                <p className="medium-title bold">플랜트 등록</p>
                <BaseButtonAwesomeTrans icon={faClose} onClick={closeClick} />
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">플랜트명</span>
                    <BaseInput
                        name="machine_name"
                        placeholder="플랜트명"
                        value={values.machine_name}
                        onChange={handleChange}
                    />
                </div>
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left full-size-width">시리얼 넘버</span>
                    <BaseInput
                        name="serial_number"
                        placeholder="시리얼 넘버"
                        value={values.serial_number}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">구매일</span>
                    <BaseInput
                        name="purchase_date"
                        placeholder="플랜트 구매일"
                        type="date"
                        value={values.purchase_date}
                        onChange={handleChange}
                    />
                </div>
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left full-size-width">설치일</span>
                    <BaseInput
                        name="installation_date"
                        placeholder="플랜트 설치일"
                        type="date"
                        value={values.installation_date}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">위치</span>
                    <BaseInput
                        name="location"
                        placeholder="플랜트 위치"
                        value={values.location}
                        onChange={handleChange}
                        onClick={handleClick}
                    />
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">담당자</span>
                    <BaseInput
                        name="manager_name"
                        placeholder="담당자 이름"
                        value={values.manager_name}
                        onChange={handleChange}
                    />
                </div>
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">연락처</span>
                    <BaseInput
                        name="manager_contact"
                        placeholder="담당자 연락처"
                        value={values.manager_contact}
                        onChange={handleChange}
                    />
                </div>
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">모델명</span>
                    <BaseInput
                        name="model_name"
                        placeholder="모델명"
                        value={values.model_name}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item align-center">
                    <div className="row full-size-width">
                        <span className="grow-size normal-info bold text-align-left">플랜트사진</span>
                        <FontAwesomeIcon icon={faTrash} onClick={deleteImage} style={{cursor: "pointer"}}/>
                    </div>
                    <div className="column align-center upload-box"
                         onClick={handleImageClick}
                         style={!imageValue.url ? { padding: "4rem 0" } : {}}>
                        <BaseImage
                            src={imageValue.url ? loadImage(imageValue.url) : uploadSVG}
                            alt="machine-picture"
                            width="100%"
                            height="100%"
                        />
                    </div>
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={fileInputRef} // fileInputRef를 file input element에 연결합니다.
                        onChange={handleImageChange}
                        accept=".png, .jpg, .jpeg"
                    />
                </div>
            </div>
            <div className="seperator"></div>
            <BaseButton onClick={handleSubmit}>등록하기</BaseButton>
        </>
    );
}
export default RegModal;


