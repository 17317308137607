import React, {useState, useEffect, useContext, useRef} from "react";
import ResizeObserver from 'resize-observer-polyfill';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendarDay,
    faRefresh
} from "@fortawesome/free-solid-svg-icons";
import BaseInput from "../../BaseComponents/BaseInput";
import {
    BaseButtonAwesome,
    BaseButtonField,
} from "../../BaseComponents/BaseButton";
import {GlobalContext, ReservationContext} from "../../pages/AdmHomePage";
import {BaseLoading, BaseLoadingInContainer} from "../../BaseComponents/BaseLoading";
import { FixedSizeList as List } from 'react-window';
import BaseNoData from '../../BaseComponents/BaseNoData';
import {BaseAlert, BaseAlertDanger, BaseAlertWarn} from "../../BaseComponents/BaseAlert";
import {backendServer} from "../../App";
import RequestModal from "../../modals/reservation/RequestModal";
import SelectModal from "../../modals/reservation/SelectModal";
const widthPercentage = ['100px', '200px','600px','250px','150px', '150px', '150px'];

// 상수 정의
const ITEM_SIZE = 44;  // 각 항목의 높이를 조정합니다.

function Main() {
    const { currentReservations, setCurrentReservations, activeRVHistory, setActiveRVHistory } = useContext(ReservationContext);
    const { userData, setUserData, isInit, setIsInit, timeRemainingRef } = useContext(GlobalContext);

    const [ isLoading, setIsLoading ] = useState(false);

    const [ modalType, setModalType ] = useState(-1);
    const [ isRegLoading, setIsRegLoading ] = useState(false);

    const divRef = useRef(null);
    const [listHeight, setListHeight] = useState(468);
    const [containerWidth, setContainerWidth] = useState(0);
    const containerRef = useRef(null);

    const [ isModal, setIsModal ] = useState(false);

    const [ selectedRequest, setSelectedRequest ] = useState(null);
    const [ searchValues, setSearchValues ] = useState({
        company_name: '', start_date: '', end_date: ''
    });

    const [ alertTimeout, setAlertTimeout ] = useState(null);
    const [ isAlert, setIsAlert] = useState(-1);
    const [alertMsg, setAlertMsg] = useState("");

    const [ currentUsers, setCurrentUsers ] = useState(null);
    const [ selectedUser, setSelectedUser ] = useState({
        name: "", phone: "", email: "", user_id: 0,
    });

    const headerData = {
        status: "요청처리",
        machine_name : '요청 플랜트',
        request_description : '요청 내용',
        company_name : '요청 회사',
        company_phone : '회사 번호',
        admin_contact : '요청 담당자',
        contact_person : '담당자 번호',
    };
    const setIsAlertWithTimeout = (alertType, time = 3500) => {
        // 이미 설정된 setTimeout이 있다면 취소합니다.
        if (alertTimeout) {
            clearTimeout(alertTimeout);
        }

        setIsAlert(alertType);

        // 3.5초 후에 알림을 사라지게 만듭니다.
        const timeoutId = setTimeout(() => {
            setIsAlert(-1);
        }, time);

        // 새로 설정된 setTimeout의 ID를 저장합니다.
        setAlertTimeout(timeoutId);
    }

    const getTechnicians = async () => {
        setIsLoading(true);
        try {
            const requestBody = {
                company_id: userData.company_id,
            };

            const response = await fetch(`https://${backendServer}/get-technicians`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody),
                credentials: 'include'
            });

            if (response.ok) {
                const technicians = await response.json();
                console.log("Users: ", technicians);
                setCurrentUsers(technicians);
            } else {
                const errorData = await response.json();
                if(errorData.error === 'SessionExpired') {
                    setUserData(null);
                    setIsInit(true);
                    throw new Error('Session has expired.'); // 이후의 처리를 중단하고 catch로 이동하기 위해 에러를 발생시킵니다.
                }else if(errorData.error === 'NotLoggedIn'){
                    setUserData(null);
                    setIsInit(true);
                }
            }
        } catch (error) {
            setAlertMsg("서버에 연결할 수 없습니다. 관리자에게 문의해보세요.");
            setIsAlertWithTimeout(2);
        } finally {
            setIsLoading(false);
            timeRemainingRef.current = (60 * 60);
        }
    }

    const getReservations = async () => {
        setIsLoading(true);
        try {
            const requestBody = {
                company_id: userData.company_id,
                searchCriteria: searchValues  // 추가된 부분
            };

            const response = await fetch(`https://${backendServer}/get-requests`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody),
                credentials: 'include'
            });

            if (response.ok) {
                const reservations = await response.json();
                console.log(reservations);
                setCurrentReservations(prevState => [headerData, ...reservations]);
            } else {
                const errorData = await response.json();
                if(errorData.error === 'SessionExpired') {
                    setUserData(null);
                    setIsInit(true);
                } else if(errorData.error === 'NotLoggedIn') {
                    setUserData(null);
                    setIsInit(true);
                }
            }
        } catch (error) {
            setAlertMsg("서버에 연결할 수 없습니다. 관리자에게 문의해보세요.");
            setIsAlertWithTimeout(2);
        } finally {
            setIsLoading(false);
            timeRemainingRef.current = (60 * 60);
        }
    }

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setContainerWidth(entry.contentRect.width);
            }
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [containerRef]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setListHeight(entry.contentRect.height);
            }
        });

        if (divRef.current) {
            resizeObserver.observe(divRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [divRef]);

    useEffect(() => {
        getReservations();
    }, [])

    const handleOpenModal = async (type) => {
        setModalType(type);
        setIsModal(true);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        let prevValue;

        if(modalType === -1){
            prevValue = searchValues[name];
        }

        if (name === "manager_contact") {
            // 입력값이 줄어들었을 때는 형식화를 건너뛰기
            if (value.length < prevValue.length) {
                setSearchValues(prevValues => ({
                    ...prevValues,
                    manager_contact: value
                }));
            } else {
                if(prevValue.replaceAll(/-/g,"").length === 11) return;

                setSearchValues(prevValues => ({
                    ...prevValues,
                    manager_contact: formatPhoneNumber(value)
                }));
            }
        } else {
            if(modalType === -1){
                setSearchValues(prevValues => ({
                    ...prevValues,
                    [name]: value
                }));
            }
        }
    }

    const formatPhoneNumber = (input) => {
        const nums = input.replace(/\D/g, "");  // 숫자만 추출

        // 휴대폰 번호 형식
        if ((nums.startsWith("010") || nums.startsWith("011") || nums.startsWith("019")) && nums.length > 3) {
            return `${nums.slice(0, 3)}-${nums.slice(3, 7)}-${nums.slice(7)}`;
        }

        return nums;  // 기본적인 형태를 유지
    };

    const handleAccept = async (request) => {
        const isConfirmed = window.confirm(`정말 ${request.machine_name}의 작업을 수락 하시겠습니까?`);
        if (!isConfirmed) {
            return;  // 사용자가 확인을 누르지 않으면 함수를 종료합니다.
        }

        setIsModal(true);
        setModalType(1);
        getTechnicians();
    }

    const handleReject = async (request) => {
        const isConfirmed = window.confirm(`정말 ${request.machine_name}의 작업을 거절 하시겠습니까?`);
        if (!isConfirmed) {
            return;  // 사용자가 확인을 누르지 않으면 함수를 종료합니다.
        }

        if(modalType === -1){
            setIsLoading(true);
        }else{
            setIsRegLoading(true);
        }

        try {
            const requestBody = {
                id: request.id,
                requesting_admin_id: request.requesting_admin_id,
            };

            const response = await fetch(`https://${backendServer}/work/reject`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody),
                credentials: 'include'
            });

            if (response.ok) {
                if(modalType === -1){
                    setIsLoading(false);
                }else{
                    handleCloseModal();
                }
                getReservations();
            } else {
                const errorData = await response.json();
                if(errorData.error === 'SessionExpired') {
                    setUserData(null);
                    setIsInit(true);
                } else if(errorData.error === 'NotLoggedIn') {
                    setUserData(null);
                    setIsInit(true);
                } else if(errorData.error === 'FailedSMSSended') {
                    handleCloseModal();
                    getReservations();
                    setAlertMsg("요청은 성공헀으나, 문자 발송에 실패했습니다. [수동 연락 요망]");
                    setIsAlertWithTimeout(2, 7000);
                }
            }
        } catch (error) {
            setAlertMsg("서버에 연결할 수 없습니다. 관리자에게 문의해보세요.");
            setIsAlertWithTimeout(2);
        } finally {
            setIsRegLoading(false);
            timeRemainingRef.current = (60 * 60);
        }
    }

    const handleConfirm = async (request) => {
        if(modalType === -1){
            setIsLoading(true);
        }else{
            setIsRegLoading(true);
        }
        try {
            const requestBody = {
                id: request.id,
                user_id: selectedUser.user_id,
                machine_id: request.machine_id,
                requested_company_id: userData.company_id,
                requesting_company_id: request.requesting_company_id,
                requesting_admin_id: request.requesting_admin_id,
                request_date: request.request_date,
            };

            const response = await fetch(`https://${backendServer}/work/accept`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody),
                credentials: 'include'
            });

            if (response.ok) {
                if(modalType === -1){
                    setIsLoading(false);
                }else{
                    handleCloseModal();
                }
                getReservations();
            } else {
                const errorData = await response.json();
                if(errorData.error === 'SessionExpired') {
                    setUserData(null);
                    setIsInit(true);
                } else if(errorData.error === 'NotLoggedIn') {
                    setUserData(null);
                    setIsInit(true);
                } else if(errorData.error === 'FailedSMSSended') {
                    handleCloseModal();
                    getReservations();
                    setAlertMsg("요청은 성공헀으나, 문자 발송에 실패했습니다. [수동 연락 요망]");
                    setIsAlertWithTimeout(2, 7000);
                }
            }
        } catch (error) {
            setAlertMsg("서버에 연결할 수 없습니다. 관리자에게 문의해보세요.");
            setIsAlertWithTimeout(2);
        } finally {
            setIsRegLoading(false);
            timeRemainingRef.current = (60 * 60);
        }
    }

    const handleCloseModal = () => {
        if(modalType === 1){
            setCurrentUsers(null);
        }
        setModalType(-1);
        setIsModal(false);
    }

    function formatDate(isoString) {
        return isoString.split('T')[0];
    }

    const formatLocation = (location) => {
        return location.split(" ")[0];
    }

    function TableRow({ index, style, data }) {
        const reservation = data[index];

        const rowStyle = {
            ...style,
            top: ITEM_SIZE * index - 16,
        };

        const headerStyle = {
            height: '28px'
        };

        const bodyStyle = {
            backgroundColor: index % 2 === 1 ? '#e8e8e8' : 'transparent',
        }

        if (containerWidth < 910) {
            headerStyle.width = '900px';
            bodyStyle.width = '900px';
        }

        const getStyle = (widthPercentage, isFirst) => {
            if (containerWidth < 910){
                if(isFirst){
                    return { width: widthPercentage,
                        backgroundColor: index % 2 === 1 ? '#e8e8e8' : '#FFFFFF',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }else{
                    return { width: widthPercentage,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }
            }else{
                if(isFirst){
                    return { flex: widthPercentage,
                        backgroundColor: index % 2 === 1 ? '#e8e8e8' : '#FFFFFF',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }else{
                    return { flex: widthPercentage,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }
            }
        }

        return (
            <>
                {index === 0 ? (
                    <>
                        <div className="row full-size-width table-header" style={headerStyle}>
                            <div className="row first-child align-center" style={{width: "200px", margin: 0, padding: 0}}>
                                <div className="column" style={getStyle(widthPercentage[0])}>
                                    <p className="text-align-center">수락처리</p>
                                </div>
                                <div className="column" style={getStyle(widthPercentage[0])}>
                                    <p className="text-align-center">거절처리</p>
                                </div>
                            </div>
                            <div className="column align-center" style={getStyle(widthPercentage[3])}>
                                <p className="text-align-center">{reservation.company_name}</p>
                            </div>
                            <div className="column" style={getStyle(widthPercentage[3])}>
                                <p className="text-align-center">요청 날짜</p>
                            </div>
                            <div className="column" style={getStyle(widthPercentage[1])}>
                                <p className="text-align-center">유지보수요청서 보기</p>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div style={rowStyle}>
                            <div className="row full-size-width table-item"  style={bodyStyle}>
                                <div className="row first-column" style={{width: "200px", margin: 0, padding: 0}}>
                                    <div name="equipment-image" className="column align-center" style={getStyle(widthPercentage[0], true)}>
                                        <BaseButtonField onClick={(e) => {
                                            e.stopPropagation()
                                            setSelectedRequest(reservation);
                                            handleAccept(reservation);
                                        }}>
                                            수락
                                        </BaseButtonField>
                                    </div>
                                    <div name="equipment-image" className="column align-center" style={getStyle(widthPercentage[0], true)}>
                                        <BaseButtonField onClick={(e) => {
                                            e.stopPropagation()
                                            setSelectedRequest(reservation);
                                            handleReject(reservation);
                                        }}
                                            aria-description="cancle" >
                                            거절
                                        </BaseButtonField>
                                    </div>
                                </div>
                                <div className="column align-center" style={getStyle(widthPercentage[3])}>
                                    <p>{reservation.company_name}</p>
                                </div>
                                <div className="column align-center" style={getStyle(widthPercentage[3])}>
                                    <p>{formatDate(reservation.request_date)}</p>
                                </div>
                                <div className="column align-center" style={getStyle(widthPercentage[1], true)}>
                                    <BaseButtonField onClick={()=> {
                                        setSelectedRequest(reservation);
                                        handleOpenModal(0);
                                    }}>보기</BaseButtonField>
                                </div>
                            </div>
                        </div>
                    </>
                )}

            </>
        );
    }

    return (
        <div className="full-size column align-center">
            <div className="seperator-half"></div>
            <div className="row align-center-left main-table-header full-size-width">
                <FontAwesomeIcon className="menu" icon={faCalendarDay} onClick={() => setActiveRVHistory(null)}/>
                <div className="seperator-horz"></div>
                <p className="medium-title bold">요청 처리</p>
            </div>
            <div className="row full-size-width">
                <div className="column grow-size search-info-container">
                    <p className="normal-info bold text-align-left full-size-width">정보 기준</p>
                    <div className="row full-size-width">
                        <BaseInput
                            name="company_name"
                            onChange={handleChange}
                            value={searchValues.company_name}
                            aria-description="condition"
                            placeholder="요청 회사"
                        />
                    </div>
                </div>
                <div className="column grow-size align-center search-info-container">
                    <p className="normal-info bold text-align-left full-size-width">요청일 기준</p>
                    <div className="row full-size-width">
                        <BaseInput
                            name="start_date"
                            onChange={handleChange}
                            value={searchValues.start_date}
                            type="date"
                            data-placeholder="검색시작일"
                            aria-description="condition"
                        />
                        <div className="seperator-horz"></div>
                        <BaseInput
                            name="end_date"
                            onChange={handleChange}
                            value={searchValues.end_date}
                            type="date"
                            data-placeholder="검색종료일"
                            aria-description="condition"
                        />
                    </div>
                </div>
                <div className="column align-center buttons">
                    <BaseButtonAwesome onClick={getReservations} icon={faRefresh}/>
                </div>
            </div>
            <div className="column grow-size full-size-width">
                <div className="seperator-half"></div>
                <div className="column main-table-body" ref={containerRef}>
                    {isLoading && (
                        <>
                            <BaseLoading/>
                        </>
                    )}
                    <div className="table-container-h1" ref={divRef}>
                        <>
                            {currentReservations.length === 1 ? (
                                <>
                                    <BaseNoData/>
                                </>
                            ) : (
                                <>
                                    <div className="row table-body-container" >
                                        <List
                                            width={containerWidth}
                                            height={listHeight}
                                            className="table full-size-width"
                                            itemCount={currentReservations.length}
                                            itemSize={ITEM_SIZE}
                                            itemData={currentReservations}
                                        >
                                            {TableRow}
                                        </List>
                                    </div>
                                </>
                            )}
                        </>
                    </div>
                </div>
            </div>
            <div className="seperator"></div>
            { isModal && modalType === 0 && (
                <>
                    <div className="modal full-size column align-center">
                        <div className="register-form">
                            {isRegLoading ? (
                                <>
                                    <BaseLoadingInContainer/>
                                </>
                            ):(
                                <>
                                    <RequestModal
                                        selectedRequest={selectedRequest}
                                        handleAccept={() => {
                                            handleAccept(selectedRequest);
                                        }}
                                        handleReject={() => {
                                            handleReject(selectedRequest);
                                        }}
                                        closeClick={handleCloseModal}
                                    />
                                </>
                            )}

                        </div>
                    </div>
                </>
            )}
            { isModal && modalType === 1 && currentUsers && (
                <>
                    <div className="modal full-size column align-center">
                        <div className="register-form">
                            {isRegLoading ? (
                                <>
                                    <BaseLoadingInContainer/>
                                </>
                            ):(
                                <>
                                    <SelectModal
                                        currentUsers={currentUsers}
                                        handleConfirm={() => {
                                            if(!selectedUser){
                                                setAlertMsg("작업자를 선택해주세요.");
                                                setIsAlertWithTimeout(1);
                                                return;
                                            }
                                            handleConfirm(selectedRequest);
                                        }}
                                        handleSuggestionClick={(user) => {
                                            if(user){
                                                setSelectedUser(user);
                                            }else{
                                                setSelectedUser(null);
                                            }
                                        }}
                                        closeClick={handleCloseModal}
                                    />
                                </>
                            )}

                        </div>
                    </div>
                </>
            )}
            {isAlert === 0 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                <>
                    <BaseAlert msg={alertMsg}></BaseAlert>
                </>
            )}
            {isAlert === 1 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                <>
                    <BaseAlertWarn msg={alertMsg}></BaseAlertWarn>
                </>
            )}
            {isAlert === 2 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                <>
                    <BaseAlertDanger msg={alertMsg}></BaseAlertDanger>
                </>
            )}
        </div>
    );
}

export default Main;
