import {BaseLoadingInContainer} from "../../BaseComponents/BaseLoading";
import {BaseButton, BaseButtonAwesomeTrans} from "../../BaseComponents/BaseButton";
import {faClose, faTrash} from "@fortawesome/free-solid-svg-icons";
import BaseInput from "../../BaseComponents/BaseInput";
import React, { useRef } from "react";
import {BaseImage, BaseImageSquare} from "../../BaseComponents/BaseImage";
import uploadSVG from "../../images/upload-solid.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BaseTextArea from "../../BaseComponents/BaseTextArea";
import {BaseAutocompleteInput} from "../../BaseComponents/BaseAutocompleteInput";

function RequestModal(props) {
    const { values, companies, closeClick, handleChange, handleSubmit, handleSuggestionClick } = props;

    const displayNames = () => {
        const MAX_DISPLAY_COUNT = 4;

        let displayText = "";

        if (values.machine_names.length <= MAX_DISPLAY_COUNT) {
            displayText = values.machine_names.join(", ");
        } else {
            const displayedMachines = values.machine_names.slice(0, MAX_DISPLAY_COUNT).join(", ");
            const remainingCount = values.machine_names.length - MAX_DISPLAY_COUNT;
            displayText = `${displayedMachines} 외 ${remainingCount}개`;
        }

        return displayText;
    }

    return (
        <>
            <div className="row align-center full-size-width register-form-header">
                <div className="column">
                    <p className="medium-title bold" style={{margin: "0.5rem 0"}}>유지보수 요청서</p>
                    <p className="normal-info" style={{margin: 0}}>({(values.machine_names && values.machine_names.length > 0) ? displayNames() : values.machine_name})</p>
                </div>
                <BaseButtonAwesomeTrans icon={faClose} onClick={closeClick} />
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">유지보수 회사</span>
                    <BaseAutocompleteInput
                        data={companies}
                        displayFunc={company => `${company.company_name}`}
                        suggestionFunc={company => `${company.company_name}\t(${company.company_number})`}
                        suggestionClick={handleSuggestionClick}
                        value={values.requested_company_name}
                        name="requested_company_name"
                        placeholder="기업명을 입력해주세요."
                    />
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">요청 내용</span>
                    <BaseTextArea
                        name="requested_text"
                        placeholder="요청 내용"
                        value={values.requested_text}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="seperator"></div>
            <BaseButton onClick={handleSubmit}>요청하기</BaseButton>
        </>
    );
}
export default RequestModal;


