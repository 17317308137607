import "../../styles/Reservation.css"
import React, {useContext, useState} from "react";
import {ReservationHistoryContext} from "../../pages/AdmHomePage";
import Main from "./main";
import MainOwner from "./mainOwner";

function ReservationHistory({type, ...props}) {
    const { activeRVHHistory, setActiveRVHHistory, selectedRVHItem, setSelectedRVHItem } = useContext(ReservationHistoryContext);

    return (
        <>
            {!activeRVHHistory && !selectedRVHItem && (
                <>
                    {type === 1 ? (
                        <MainOwner/>
                    ) : (
                        <Main/>

                    )}
                </>
            )}
        </>
    );
}

export default ReservationHistory;
