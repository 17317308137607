import {BaseLoadingInContainer} from "../../BaseComponents/BaseLoading";
import {BaseButton, BaseButtonAwesomeTrans} from "../../BaseComponents/BaseButton";
import {faClose, faTrash} from "@fortawesome/free-solid-svg-icons";
import BaseInput from "../../BaseComponents/BaseInput";
import React, { useRef } from "react";
import {BaseImage, BaseImageSquare} from "../../BaseComponents/BaseImage";
import uploadSVG from "../../images/upload-solid.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BaseTextArea from "../../BaseComponents/BaseTextArea";
import {BaseAutocompleteInput} from "../../BaseComponents/BaseAutocompleteInput";
import BaseSliderImageViewer from "../../BaseComponents/BaseSliderImageViewer";
import * as XLSX from "xlsx-js-style";

function InfoModal(props) {
    const { selectedRequest, closeClick } = props;

    const handleExportExcel = () => {
        function formatDate(isoString) {
            if (!isoString || isNaN(new Date(isoString).getTime())) {
                return "";
            }

            const date = new Date(isoString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');  // 월은 0부터 시작하기 때문에 1을 더해줍니다.
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }

        function formatStatus(status) {
            if(status === 'pending'){
                return '대기';
            }else if(status === 'accepted'){
                return '수락';
            }else if(status === 'rejected'){
                return '거절';
            }
        }

        const borderStyle = {
            style: 'medium',
            color: { rgb: '548235' }
        };

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([[]]);

        const columnWidths = [
            { wch: 1.86 },
            { wch: 3.00 },
            { wch: 11.57 },
            { wch: 5.29 },
            { wch: 5.29 },
            { wch: 11.57 },
            { wch: 2.71 },
            { wch: 2.71 },
            { wch: 2.71 },
            { wch: 2.71 },
            { wch: 2.71 },
            { wch: 10.71 },
            { wch: 3.29 },
            { wch: 4.00 },
            { wch: 2.71 },
            { wch: 4.43 },
            { wch: 11.57 },
            { wch: 9.57 },
            { wch: 15.43 },
        ];

        ws['!cols'] = columnWidths;

        ws['!rows'] = [
            { hpx: 15.00 },
            { hpx: 30.00 },
            { hpx: 10.50 },
            { hpx: 24.75 },
            { hpx: 24.75 },
            { hpx: 24.75 },
            { hpx: 24.75 },
            { hpx: 24.75 },
            { hpx: 24.75 },
            { hpx: 24.75 },
            { hpx: 24.75 },
            { hpx: 24.75 },
            { hpx: 24.75 },
            { hpx: 24.75 },
            { hpx: 10.50 },
        ];

        // A1부터 S1까지의 범위를 하나의 셀로 병합
        ws['!merges'] = [
            { s: { r: 1, c: 1 }, e: { r: 1, c: 18 } },
            { s: { r: 3, c: 1 }, e: { r: 3, c: 2 } },
            { s: { r: 3, c: 3 }, e: { r: 3, c: 11 } },
            { s: { r: 3, c: 12 }, e: { r: 3, c: 18 } },
            { s: { r: 4, c: 1 }, e: { r: 4, c: 2 } },
            { s: { r: 4, c: 3 }, e: { r: 4, c: 11 } },
            { s: { r: 5, c: 1 }, e: { r: 5, c: 2 } },
            { s: { r: 5, c: 3 }, e: { r: 5, c: 11 } },
            { s: { r: 6, c: 1 }, e: { r: 6, c: 2 } },
            { s: { r: 6, c: 3 }, e: { r: 6, c: 11 } },
            { s: { r: 7, c: 1 }, e: { r: 7, c: 2 } },
            { s: { r: 7, c: 3 }, e: { r: 7, c: 11 } },
            { s: { r: 8, c: 1 }, e: { r: 8, c: 2 } },
            { s: { r: 8, c: 3 }, e: { r: 8, c: 11 } },
            { s: { r: 9, c: 1 }, e: { r: 9, c: 2 } },
            { s: { r: 9, c: 3 }, e: { r: 9, c: 11 } },
            { s: { r: 10, c: 1 }, e: { r: 10, c: 2 } },
            { s: { r: 10, c: 3 }, e: { r: 10, c: 11 } },
            { s: { r: 11, c: 1 }, e: { r: 11, c: 2 } },
            { s: { r: 11, c: 3 }, e: { r: 11, c: 11 } },
            { s: { r: 12, c: 1 }, e: { r: 12, c: 2 } },
            { s: { r: 12, c: 3 }, e: { r: 12, c: 11 } },
            { s: { r: 13, c: 1 }, e: { r: 13, c: 2 } },
            { s: { r: 13, c: 3 }, e: { r: 13, c: 11 } },
            { s: { r: 4, c: 12 }, e: { r: 13, c: 18 } },
            { s: { r: 2, c: 1 }, e: { r: 2, c: 18 } },
            { s: { r: 14, c: 1 }, e: { r: 14, c: 18 } },
        ];

        XLSX.utils.sheet_add_aoa(ws, [['유지보수 요청 내역서', '유지보수 요청 내역서']], { origin: 'B2' });
        XLSX.utils.sheet_add_aoa(ws, [['플랜트명', '플랜트명']], { origin: 'B4' });
        XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: 'C4' });
        XLSX.utils.sheet_add_aoa(ws, [['시리얼 넘버', '시리얼 넘버']], { origin: 'B5' });
        XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: 'C5' });
        XLSX.utils.sheet_add_aoa(ws, [['설치일', '설치일']], { origin: 'B6' });
        XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: 'C6' });
        XLSX.utils.sheet_add_aoa(ws, [['구매일', '구매일']], { origin: 'B7' });
        XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: 'C7' });
        XLSX.utils.sheet_add_aoa(ws, [['모델명', '모델명']], { origin: 'B8' });
        XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: 'C8' });
        XLSX.utils.sheet_add_aoa(ws, [['플랜트보유기업', '플랜트보유기업']], { origin: 'B9' });
        XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: 'C9' });
        XLSX.utils.sheet_add_aoa(ws, [['플랜트 담당자', '플랜트 담당자']], { origin: 'B10' });
        XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: 'C10' });
        XLSX.utils.sheet_add_aoa(ws, [['플랜트담당자연락처', '플랜트담당자연락처']], { origin: 'B11' });
        XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: 'C11' });
        XLSX.utils.sheet_add_aoa(ws, [['플랜트 위치', '플랜트 위치']], { origin: 'B12' });
        XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: 'C12' });
        XLSX.utils.sheet_add_aoa(ws, [['유지보수기업', '유지보수기업']], { origin: 'B13' });
        XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: 'C13' });
        XLSX.utils.sheet_add_aoa(ws, [['요청 상태', '요청 상태']], { origin: 'B14' });
        XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: 'C14' });

        ws['B2'].s = {
            font: { name: '맑은 고딕', bold: true, sz: 22, color: { rgb: '548235' } },
            alignment: { horizontal: 'center' },
        };


        let range = XLSX.utils.decode_range('B4:C14');
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
                const cell = ws[cellRef];

                if(C === 1){
                    cell.s = {
                        font: { name: '맑은 고딕', bold: true, sz: 9 },
                        alignment: { horizontal: 'center', vertical: 'center' },
                        fill: { fgColor: { rgb: 'F2F2F2' } },
                        border: {
                            bottom: {
                                style: 'thin',
                                color: { rgb: 'C7CCD9' },
                            }
                        }
                    };
                }else{
                    cell.s = {
                        border: {
                            bottom: {
                                style: 'thin',
                                color: { rgb: 'C7CCD9' },
                            }
                        }
                    };
                }

                if(R === 3){
                    cell.s.border.top = borderStyle;
                }
            }
        }

        range = XLSX.utils.decode_range('D4:L14');
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
                if(cellRef === 'D4'){
                    XLSX.utils.sheet_add_aoa(ws, [[selectedRequest.machine_name, selectedRequest.machine_name]], { origin: cellRef });
                }else if(cellRef === 'D5'){
                    XLSX.utils.sheet_add_aoa(ws, [[selectedRequest.serial_number, selectedRequest.serial_number]], { origin: cellRef });
                }else if(cellRef === 'D6'){
                    XLSX.utils.sheet_add_aoa(ws, [[formatDate(selectedRequest.installation_date), formatDate(selectedRequest.installation_date)]], { origin: cellRef });
                }else if(cellRef === 'D7'){
                    XLSX.utils.sheet_add_aoa(ws, [[formatDate(selectedRequest.purchase_date), formatDate(selectedRequest.purchase_date)]], { origin: cellRef });
                }else if(cellRef === 'D8'){
                    XLSX.utils.sheet_add_aoa(ws, [[selectedRequest.model_name, selectedRequest.model_name]], { origin: cellRef });
                }else if(cellRef === 'D9'){
                    XLSX.utils.sheet_add_aoa(ws, [[selectedRequest.requesting_company_name, selectedRequest.requesting_company_name]], { origin: cellRef });
                }else if(cellRef === 'D10'){
                    XLSX.utils.sheet_add_aoa(ws, [[selectedRequest.manager_name, selectedRequest.manager_name]], { origin: cellRef });
                }else if(cellRef === 'D11'){
                    XLSX.utils.sheet_add_aoa(ws, [[selectedRequest.manager_contact, selectedRequest.manager_contact]], { origin: cellRef });
                }else if(cellRef === 'D12'){
                    XLSX.utils.sheet_add_aoa(ws, [[selectedRequest.location, selectedRequest.location]], { origin: cellRef });
                }else if(cellRef === 'D13'){
                    XLSX.utils.sheet_add_aoa(ws, [[selectedRequest.requested_company_name, selectedRequest.requested_company_name]], { origin: cellRef });
                }else if(cellRef === 'D14'){
                    XLSX.utils.sheet_add_aoa(ws, [[formatStatus(selectedRequest.status), formatStatus(selectedRequest.status)]], { origin: cellRef });
                }else{
                    XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: cellRef });
                }

                const cell = ws[cellRef];

                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'center', vertical: 'center' },
                    border: {
                        left: {
                            style: 'thin',
                            color: { rgb: 'C7CCD9' },
                        },
                        bottom: {
                            style: 'thin',
                            color: { rgb: 'C7CCD9' },
                        }
                    }
                };

                if(R === 3){
                    cell.s.border.top = borderStyle;
                }
            }
        }

        range = XLSX.utils.decode_range('M4:S4');
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellRef = XLSX.utils.encode_cell({ r: R, c: C });

                if (C === 12) {
                    XLSX.utils.sheet_add_aoa(ws, [['요청 내용']], { origin: cellRef });
                    ws[cellRef].s = {
                        font: { name: '맑은 고딕', bold: true, sz: 9 },
                        alignment: { horizontal: 'center', vertical: 'center' },
                        fill: { fgColor: { rgb: 'F2F2F2' } },
                        border: {
                            top: borderStyle
                        }
                    };
                } else {
                    // 나머지 셀들은 빈 데이터로 설정
                    XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: cellRef });
                    ws[cellRef].s = {
                        border: {
                            top: borderStyle
                        }
                    };
                }
            }
        }

        range = XLSX.utils.decode_range('B15:S15');
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
                XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: cellRef });
                ws[cellRef].s = {
                    border: {
                        bottom: borderStyle
                    }
                };
            }
        }

        range = XLSX.utils.decode_range('M5:M14');
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
                XLSX.utils.sheet_add_aoa(ws, [[selectedRequest.request_description, selectedRequest.request_description]], { origin: cellRef });
                ws[cellRef].s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'center', vertical: 'center' },
                };
            }
        }

        // 워크북에 시트 추가
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const today = new Date();
        const baseFileName = `[${selectedRequest.machine_name}] 유지보수 요청 내역서 ${formatDate(selectedRequest.request_date)}`
        // 엑셀 파일로 저장
        XLSX.writeFile(wb, `${baseFileName}.xlsx`);
    };

    function formatDate(isoString) {
        if(isoString === "" || !isoString){
            return "없음";
        }
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');  // 월은 0부터 시작하기 때문에 1을 더해줍니다.
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    return (
        <div className="column">
            <div className="row align-center full-size-width register-form-header">
                <div className="column">
                    <p className="medium-title bold" style={{whiteSpace: "nowrap", margin: "0.5rem 0"}}>유지보수 요청서</p>
                </div>
                <BaseButtonAwesomeTrans icon={faClose} onClick={closeClick} />
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">플랜트명</span>
                    <div className="column full-size-width text-field">
                        <p style={{whiteSpace: "nowrap"}}>{selectedRequest.machine_name}</p>
                    </div>
                </div>
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">요청 담당자</span>
                    <div className="column full-size-width text-field">
                        <p style={{whiteSpace: "nowrap"}}>{selectedRequest.requesting_admin_name}</p>
                    </div>
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">유지보수 기업</span>
                    <div className="column full-size-width text-field">
                        <p style={{whiteSpace: "nowrap"}}>{selectedRequest.requested_company_name}</p>
                    </div>
                </div>
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">기업 연락처</span>
                    <div className="column full-size-width text-field">
                        <p style={{whiteSpace: "nowrap"}}>{selectedRequest.requested_company_phone}</p>
                    </div>
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">기업 담당자</span>
                    <div className="column full-size-width text-field">
                        <p style={{whiteSpace: "nowrap"}}>{selectedRequest.requested_admin_name}</p>
                    </div>
                </div>
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">담당자 연락처</span>
                    <div className="column full-size-width text-field">
                        <p style={{whiteSpace: "nowrap"}}>{selectedRequest.requested_admin_phone}</p>
                    </div>
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">요청일시</span>
                    <div className="column full-size-width text-field">
                        <p style={{whiteSpace: "nowrap"}}>{formatDate(selectedRequest.request_date)}</p>
                    </div>
                </div>
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">수락 일시</span>
                    <div className="column full-size-width text-field">
                        <p style={{whiteSpace: "nowrap"}}>{formatDate(selectedRequest.accepted_date)}</p>
                    </div>
                </div>
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">거절 일시</span>
                    <div className="column full-size-width text-field">
                        <p style={{whiteSpace: "nowrap"}}>{formatDate(selectedRequest.rejected_date)}</p>
                    </div>
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">요청 내용</span>
                    <div className="column full-size-width textarea-field">
                        <p style={{whiteSpace: "nowrap"}}>{selectedRequest.request_description}</p>
                    </div>
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <BaseButton onClick={handleExportExcel}>엑셀 다운로드</BaseButton>
                </div>
            </div>
        </div>
    );
}
export default InfoModal;


