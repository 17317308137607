import React, {useState, useEffect, useContext, useRef} from "react";
import ResizeObserver from 'resize-observer-polyfill';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendarDay, faHistory,
    faRefresh
} from "@fortawesome/free-solid-svg-icons";
import BaseInput from "../../BaseComponents/BaseInput";
import {
    BaseButtonAwesome,
    BaseButtonField,
} from "../../BaseComponents/BaseButton";
import {GlobalContext, ReservationContext, ReservationHistoryContext} from "../../pages/AdmHomePage";
import {BaseLoading, BaseLoadingInContainer} from "../../BaseComponents/BaseLoading";
import { FixedSizeList as List } from 'react-window';
import BaseNoData from '../../BaseComponents/BaseNoData';
import {BaseAlert, BaseAlertDanger, BaseAlertWarn} from "../../BaseComponents/BaseAlert";
import {backendServer} from "../../App";
import RequestModal from "../../modals/reservation/RequestModal";
import SelectModal from "../../modals/reservation/SelectModal";
import {BaseSelector} from "../../BaseComponents/BaseSelector";
import RegModal from "../../modals/machine/RegModal";
import InfoModal from "../../modals/request/InfoModal";
const widthPercentage = ['100px', '200px','150px','450px','200px', '200px', '200px', '200px', '200px', '200px', '200px'];

// 상수 정의
const ITEM_SIZE = 44;  // 각 항목의 높이를 조정합니다.

function Main() {
    const { currentReservationHistories, setCurrentReservationHistories, activeRVHHistory, setActiveRVHHistory } = useContext(ReservationHistoryContext);
    const { userData, setUserData, isInit, setIsInit, timeRemainingRef } = useContext(GlobalContext);
    const [ selectedRequest, setSelectedRequest ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);

    const [ modalType, setModalType ] = useState(-1);
    const [ isRegLoading, setIsRegLoading ] = useState(false);

    const divRef = useRef(null);
    const [listHeight, setListHeight] = useState(468);
    const [containerWidth, setContainerWidth] = useState(0);
    const containerRef = useRef(null);

    const [ isModal, setIsModal ] = useState(false);

    const [ selectedSearchStatus, setSelectedSearchStatus ] = useState("");

    const [ searchValues, setSearchValues ] = useState(() => {
        const currentYear = new Date().getFullYear(); // 올해의 연도를 가져옵니다.
        const startOfYear = `${currentYear}-01-01`; // 올해의 첫날
        const endOfYear = `${currentYear}-12-31`; // 올해의 마지막 날

        return {
            requested_company_name: "", requested_admin_name: "", requested_admin_phone: "",
            machine_name: "", location: "",
            request_start_date: startOfYear, request_end_date: endOfYear,
            accepted_start_date: "", accepted_end_date: "",
            rejected_start_date: "", rejected_end_date: "",
        }
    });

    const [ alertTimeout, setAlertTimeout ] = useState(null);
    const [ isAlert, setIsAlert] = useState(-1);
    const [alertMsg, setAlertMsg] = useState("");

    const headerData = {
        status: "상태",
        requesting_admin_phone: "요청자",
        machine_name : '플랜트',
        location : '위치',
        request_description : '요청 내용',
        request_date : '요청 날짜',
        accepted_date : '수락 날짜',
        rejected_date : '거절 날짜',
        requested_company_name : '유지보수 기업',
        requested_company_phone : '회사번호',
        requested_admin_name : '회사 담당자',
        requested_admin_phone : '담당자 번호',
    };
    const setIsAlertWithTimeout = (alertType, time = 3500) => {
        // 이미 설정된 setTimeout이 있다면 취소합니다.
        if (alertTimeout) {
            clearTimeout(alertTimeout);
        }

        setIsAlert(alertType);

        // 3.5초 후에 알림을 사라지게 만듭니다.
        const timeoutId = setTimeout(() => {
            setIsAlert(-1);
        }, time);

        // 새로 설정된 setTimeout의 ID를 저장합니다.
        setAlertTimeout(timeoutId);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        const prevValue = searchValues[name];

        if (name === "requested_admin_phone") {
            // 입력값이 줄어들었을 때는 형식화를 건너뛰기
            if (value.length < prevValue.length) {
                setSearchValues(prevValues => ({
                    ...prevValues,
                    requested_admin_phone: value
                }));
            } else {
                if(prevValue.replaceAll(/-/g,"").length === 11) return;

                setSearchValues(prevValues => ({
                    ...prevValues,
                    requested_admin_phone: formatPhoneNumber(value)
                }));
            }
        } else {
            setSearchValues(prevValues => ({
                ...prevValues,
                [name]: value
            }));
        }
    }

    const formatPhoneNumber = (input) => {
        const nums = input.replace(/\D/g, "");  // 숫자만 추출

        // 휴대폰 번호 형식
        if ((nums.startsWith("010") || nums.startsWith("011") || nums.startsWith("019")) && nums.length > 3) {
            return `${nums.slice(0, 3)}-${nums.slice(3, 7)}-${nums.slice(7)}`;
        }

        return nums;  // 기본적인 형태를 유지
    };

    const getReservationHistories = async () => {
        setIsLoading(true);
        try {
            const requestBody = {
                searchCriteria: searchValues,
                searchStatus: selectedSearchStatus,// 추가된 부분
            };

            const response = await fetch(`https://${backendServer}/get-histories/histories`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody),
                credentials: 'include'
            });

            if (response.ok) {
                const reservations = await response.json();
                setCurrentReservationHistories(prevState => [headerData, ...reservations]);
            } else {
                const errorData = await response.json();
                if(errorData.error === 'SessionExpired') {
                    setUserData(null);
                    setIsInit(true);
                } else if(errorData.error === 'NotLoggedIn') {
                    setUserData(null);
                    setIsInit(true);
                }
            }
        } catch (error) {
            setAlertMsg("서버에 연결할 수 없습니다. 관리자에게 문의해보세요.");
            setIsAlertWithTimeout(2);
        } finally {
            setIsLoading(false);
            timeRemainingRef.current = (60 * 60);
        }
    }

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setContainerWidth(entry.contentRect.width);
            }
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [containerRef]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setListHeight(entry.contentRect.height);
            }
        });

        if (divRef.current) {
            resizeObserver.observe(divRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [divRef]);

    useEffect(() => {
        getReservationHistories();
    }, [])

    const handleOpenModal = async (type) => {
        setModalType(type);
        setIsModal(true);
    }

    const handleCloseModal = () => {
        setModalType(-1);
        setIsModal(false);
    }

    function formatDate(isoString) {
        let date = new Date(isoString);
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let day = String(date.getDate()).padStart(2, '0');
        let hours = String(date.getHours()).padStart(2, '0');
        let minutes = String(date.getMinutes()).padStart(2, '0');
        let seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const formatLocation = (location) => {
        return location.split(" ")[0];
    }

    function TableRow({ index, style, data }) {
        const history = data[index];

        const rowStyle = {
            ...style,
            top: ITEM_SIZE * index - 16,
        };

        const headerStyle = {
            height: '28px'
        };

        const bodyStyle = {
            backgroundColor: index % 2 === 1 ? '#e8e8e8' : 'transparent',
        }

        if (containerWidth < 1560) {
            headerStyle.width = '1550px';
            bodyStyle.width = '1550px';
        }

        const getStyle = (widthPercentage, isFirst) => {
            if (containerWidth < 1560){
                if(isFirst){
                    return { width: widthPercentage,
                        backgroundColor: index % 2 === 1 ? '#e8e8e8' : '#FFFFFF',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }else{
                    return { width: widthPercentage,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }
            }else{
                if(isFirst){
                    return { flex: widthPercentage,
                        backgroundColor: index % 2 === 1 ? '#e8e8e8' : '#FFFFFF',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }else{
                    return { flex: widthPercentage,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }
            }
        }

        const getStatusText = (status) => {
            if(status === "pending"){
                return "요청";
            } else if(status === "accepted"){
                return "수락";
            } else if(status === "rejected"){
                return "거절";
            }
        }

        return (
            <>
                {index === 0 ? (
                    <>
                        <div className="row full-size-width table-header" style={headerStyle}>
                            <div className="column first-child" style={getStyle(widthPercentage[1])}>
                                <p className="text-align-center">{history.machine_name}</p>
                            </div>
                            <div className="column align-center" style={getStyle(widthPercentage[7])}>
                                <p className="text-align-center">{history.requested_company_name}</p>
                            </div>
                            <div className="column align-center" style={getStyle(widthPercentage[8])}>
                                <p className="text-align-center">{history.requested_company_phone}</p>
                            </div>
                            <div className="column align-center" style={getStyle(widthPercentage[4])}>
                                <p className="text-align-center">{history.request_date}</p>
                            </div>
                            <div className="column" style={getStyle(widthPercentage[0])}>
                                <p className="text-align-center">{history.status}</p>
                            </div>
                            <div className="column" style={getStyle(widthPercentage[1])}>
                                <p className="text-align-center">유지보수 요청서 보기</p>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div style={rowStyle}>
                            <div className="row full-size-width table-item" style={bodyStyle}>
                                <div className={`column first-column align-center`}
                                     style={getStyle(widthPercentage[1], true)}>
                                    <p>{history.machine_name}</p>
                                </div>
                                <div className="column align-center" style={getStyle(widthPercentage[7])}>
                                    <p>{history.requested_company_name}</p>
                                </div>
                                <div className="column align-center" style={getStyle(widthPercentage[8])}>
                                    <p>{history.requested_company_phone}</p>
                                </div>
                                <div className="column align-center" style={getStyle(widthPercentage[4])}>
                                    <p>{formatDate(history.request_date)}</p>
                                </div>
                                <div className={`column align-center ${history.status === 'rejected' ? 'red' : history.status === 'accepted' ? 'blue' : ''}`} style={getStyle(widthPercentage[0])}>
                                    <p>{getStatusText(history.status)}</p>
                                </div>
                                <div className="column" style={getStyle(widthPercentage[1])}>
                                    <BaseButtonField
                                        onClick={() => {
                                            setSelectedRequest(history);
                                            handleOpenModal(0);
                                        }}>
                                        보기
                                    </BaseButtonField>
                                </div>
                            </div>
                        </div>
                    </>
                )}

            </>
        );
    }

    return (
        <div className="full-size column align-center">
            <div className="seperator-half"></div>
            <div className="row align-center-left main-table-header full-size-width">
                <FontAwesomeIcon className="menu" icon={faHistory} />
                <div className="seperator-horz"></div>
                <p className="medium-title bold">요청 내역</p>
            </div>
            <div className="row full-size-width">
                <div className="column grow-size align-center search-info-container">
                    <p className="normal-info bold text-align-left full-size-width">검색 기준</p>
                    <div className="row full-size-width">
                        <BaseInput
                            name="requested_company_name"
                            onChange={handleChange}
                            value={searchValues.requested_company_name}
                            aria-description="condition"
                            placeholder="유지보수 회사명"
                        />
                        <div className="seperator-horz-half"/>
                        <BaseInput
                            name="machine_name"
                            onChange={handleChange}
                            value={searchValues.machine_name}
                            aria-description="condition"
                            placeholder="플랜트"
                        />
                        <div className="seperator-horz-half"/>
                        <div className="row full-size-width">
                            <BaseSelector
                                options={[
                                    { value: '', label: '모두' },
                                    { value: 'pending', label: '요청' },
                                    { value: 'accepted', label: '수락' },
                                    { value: 'rejected', label: '거절' },
                                ]}
                                onChange={(e) => setSelectedSearchStatus(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="column grow-size align-center search-info-container">
                    <p className="normal-info bold text-align-left full-size-width">요청일 기준</p>
                    <div className="row full-size-width">
                        <BaseInput
                            name="request_start_date"
                            onChange={handleChange}
                            value={searchValues.request_start_date}
                            type="date"
                            data-placeholder="검색시작일"
                            aria-description="condition"
                        />
                        <div className="seperator-horz"></div>
                        <BaseInput
                            name="request_end_date"
                            onChange={handleChange}
                            value={searchValues.request_end_date}
                            type="date"
                            data-placeholder="검색종료일"
                            aria-description="condition"
                        />
                    </div>
                </div>
                <div className="column align-center buttons">
                    <BaseButtonAwesome onClick={getReservationHistories} icon={faRefresh}/>
                </div>
            </div>
            <div className="column grow-size full-size-width">
                <div className="seperator-half"></div>
                <div className="column main-table-body" ref={containerRef}>
                    {isLoading && (
                        <>
                            <BaseLoading/>
                        </>
                    )}
                    <div className="table-container-h1" ref={divRef}>
                        <>
                            {currentReservationHistories.length === 1 ? (
                                <>
                                    <BaseNoData/>
                                </>
                            ) : (
                                <>
                                    <div className="row table-body-container" >
                                        <List
                                            width={containerWidth}
                                            height={listHeight}
                                            className="table full-size-width"
                                            itemCount={currentReservationHistories.length}
                                            itemSize={ITEM_SIZE}
                                            itemData={currentReservationHistories}
                                        >
                                            {TableRow}
                                        </List>
                                    </div>
                                </>
                            )}
                        </>
                    </div>
                </div>
            </div>
            <div className="seperator"></div>
            { isModal && modalType === 0 && (
                <>
                    <div className="modal full-size column align-center">
                        <div className="register-form">
                            {isRegLoading ? (
                                <>
                                    <BaseLoadingInContainer/>
                                </>
                            ):(
                                <>
                                    <InfoModal
                                        selectedRequest={selectedRequest}
                                        closeClick={handleCloseModal}
                                    />
                                </>
                            )}

                        </div>
                    </div>
                </>
            )}
            {isAlert === 0 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                <>
                    <BaseAlert msg={alertMsg}></BaseAlert>
                </>
            )}
            {isAlert === 1 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                <>
                    <BaseAlertWarn msg={alertMsg}></BaseAlertWarn>
                </>
            )}
            {isAlert === 2 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                <>
                    <BaseAlertDanger msg={alertMsg}></BaseAlertDanger>
                </>
            )}
        </div>
    );
}

export default Main;
