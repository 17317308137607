import React, { useState, useEffect } from 'react';
import '../styles/BaseSliderViewer.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHourglassEmpty, faImages} from "@fortawesome/free-solid-svg-icons";
const BaseSliderImageViewer = ({ data }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const updateSlide = (newIndex) => {
        setCurrentSlide(newIndex);
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + data.length) % data.length);
    };

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % data.length);
    };

    const hasImages = data.some(image => image);

    return (
        <div className="slider-container">
            <div className="slider">
                <div className="img-container">
                    {hasImages &&
                        data.map((image, index) => (
                            <img
                                key={index}
                                src={`data:image/png;base64,${image}`}
                                className={currentSlide === index ? 'active' : ''}
                                onClick={() => updateSlide(index)}
                                alt={`Slide ${index + 1}`}
                            />
                        ))}
                    {!hasImages && (
                        <div className="placeholder">
                            <FontAwesomeIcon style={{flex: 1, color: "#ccc"}} icon={faImages} />
                            <p className="normal-info">이미지가 없습니다.</p>
                        </div>
                    )}
                </div>
            </div>
            <div className="thumbnail-container">
                {hasImages &&
                    data.map((image, index) => {
                        if (image) {
                            return (
                                <div
                                    key={index}
                                    className={`thumbnail ${currentSlide === index ? 'active' : ''}`}
                                    onClick={() => updateSlide(index)}
                                >
                                    <img src={`data:image/png;base64,${image}`} alt={`Thumbnail ${index + 1}`} />
                                </div>
                            );
                        }
                        return null; // 빈 이미지 데이터가 있으면 썸네일을 생성하지 않음
                    })}
            </div>
        </div>
    );
};

export default BaseSliderImageViewer;
