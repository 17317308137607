import "../../styles/Technician.css"
import React, {useContext, useState} from "react";
import Main from "./main";
import History from "./history";
import {TechnicianContext} from "../../pages/AdmHomePage";

function Technician() {
    const { activeHistory, setActiveHistory, selectedItem, setSelectedItem } = useContext(TechnicianContext);

    return (
        <>
            {!activeHistory && !selectedItem && (
                <Main/>
            )}
            { activeHistory && !selectedItem && (
                <History/>
            )}
        </>
    );
}

export default Technician;
