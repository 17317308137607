import React, {useState, useEffect, useRef} from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdmLoginPage from "./pages/AdmLoginPage";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import AdmHomePage from "./pages/AdmHomePage";
import AdmEmailLoginPage from "./pages/AdmEmailLoginPage";
import FinishSignUp from "./pages/finishSignUp";
import { auth } from "./modules/firebase.js";
import { signOut } from 'firebase/auth';
import { debounce } from 'lodash';
import WorkLoginPage from "./pages/WorkLoginPage";
import WorkReportPage from "./pages/WorkReportPage";

// UserContext 생성
export const UserContext = React.createContext();
export const WorkContext = React.createContext();
export const backendServer = "plantpartner.kr:5003";

function App() {
    // uid 상태 생성
    const [uid, setUid] = useState(null);
    const [machineID, setMachineID ] = useState("");
    const [machineName, setMachineName] = useState("");
  return (
      <Router>
          <UserContext.Provider value={{ uid, setUid }}>
          <WorkContext.Provider value={{ machineID,setMachineID, machineName,setMachineName }}>
              <Routes>
                  <Route path="/" element={<AdmLoginPage />} />
                  <Route path="/work" element={<WorkLoginPage />} />
                  <Route path="/work/report" element={<WorkReportPage />} />
                  <Route path="/home" element={<HomePage />} />
                  <Route path="/regular" element={<LoginPage />} />
                  <Route path="/adm/home" element={<AdmHomePage />} />
                  <Route path="/adm/email" element={<AdmEmailLoginPage />} />
                  <Route path="/finishSignUp" element={<FinishSignUp />} />
              </Routes>
          </WorkContext.Provider>
          </UserContext.Provider>
      </Router>
  );
}

export default App;
