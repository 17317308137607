import {BaseLoadingInContainer} from "../../BaseComponents/BaseLoading";
import {BaseButton, BaseButtonAwesomeTrans} from "../../BaseComponents/BaseButton";
import {faClose, faTrash} from "@fortawesome/free-solid-svg-icons";
import BaseInput from "../../BaseComponents/BaseInput";
import React, { useRef } from "react";
import {BaseImage, BaseImageSquare} from "../../BaseComponents/BaseImage";
import uploadSVG from "../../images/upload-solid.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BaseTextArea from "../../BaseComponents/BaseTextArea";
import {BaseAutocompleteInput} from "../../BaseComponents/BaseAutocompleteInput";

function InfoModal(props) {
    const { selectedMachine, closeClick } = props;

    function formatDate(isoString) {
        if(isoString === ""){
            return "";
        }
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');  // 월은 0부터 시작하기 때문에 1을 더해줍니다.
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    return (
        <>
            <div className="row align-center full-size-width register-form-header">
                <p className="medium-title bold">최근 작업 보고서</p>
                <BaseButtonAwesomeTrans icon={faClose} onClick={closeClick} />
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">작업자</span>
                    <div className="column full-size-width text-field">
                        <p>{selectedMachine.user_name}</p>
                    </div>
                </div>
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">연락처</span>
                    <div className="column full-size-width text-field">
                        <p>{selectedMachine.user_phone}</p>
                    </div>
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">작업 시작</span>
                    <div className="column full-size-width text-field">
                        <p>{formatDate(selectedMachine.start_time)}</p>
                    </div>
                </div>
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">작업 종료</span>
                    <div className="column full-size-width text-field">
                        <p>{formatDate(selectedMachine.end_time)}</p>
                    </div>
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">작업 수행 회사</span>
                    <div className="column full-size-width text-field">
                        <p>{selectedMachine.company_name}</p>
                    </div>
                </div>
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">요청 일시</span>
                    <div className="column full-size-width text-field">
                        <p>{formatDate(selectedMachine.request_date)}</p>
                    </div>
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">유지보수 내용</span>
                    <div className="column full-size-width textarea-field">
                        <p>{selectedMachine.end_time ? selectedMachine.maintenance_details : "작 업 중"}</p>
                    </div>
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">특이사항</span>
                    <div className="column full-size-width textarea-field">
                        <p>{selectedMachine.end_time ? selectedMachine.special_notes : "작 업 중"}</p>
                    </div>
                </div>
            </div>
        </>
    );
}
export default InfoModal;


