import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDCEUrGSA0HewP10a8qFc3eIVPWkqyK0h4",
    authDomain: "plantmanagement-bbbac.firebaseapp.com",
    databaseURL: "https://plantmanagement-bbbac-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "plantmanagement-bbbac",
    storageBucket: "plantmanagement-bbbac.appspot.com",
    messagingSenderId: "89405653386",
    appId: "1:89405653386:web:f8266f978c0dfe75868873",
    measurementId: "G-2T5BNXCEJQ"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
setPersistence(auth, browserLocalPersistence)
    .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
    });
const db = getFirestore(firebaseApp);

export {firebaseApp, auth, db};
