import {BaseLoadingInContainer} from "../../BaseComponents/BaseLoading";
import {BaseButton, BaseButtonAwesomeTrans} from "../../BaseComponents/BaseButton";
import {faClose, faTrash} from "@fortawesome/free-solid-svg-icons";
import BaseInput from "../../BaseComponents/BaseInput";
import React, {useRef, useState} from "react";
import {BaseImage, BaseImageSquare} from "../../BaseComponents/BaseImage";
import uploadSVG from "../../images/upload-solid.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BaseTextArea from "../../BaseComponents/BaseTextArea";
import {BaseAutocompleteInput, BaseAutocompleteInput2} from "../../BaseComponents/BaseAutocompleteInput";

function SelectModal(props) {
    const { currentUsers, handleConfirm, closeClick, handleSuggestionClick } = props;

    const [ value, setValue ] = useState({name: ""});

    return (
        <>
            <div className="row align-center full-size-width register-form-header">
                <p className="medium-title bold">작업자 지정</p>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">작업자</span>
                    <BaseAutocompleteInput2
                        data={currentUsers}
                        displayFunc={user => `${user.name}`}
                        suggestionFunc={user => `${user.name}\t(${user.phone})`}
                        suggestionClick={handleSuggestionClick}
                        value={value.name}
                        name="name"
                        placeholder="작업자를 배정해주세요."
                    />
                </div>
            </div>
            <div className="seperator"></div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <BaseButton onClick={handleConfirm} >수락</BaseButton>
                </div>
                <div className="column register-form-item">
                    <BaseButton aria-description="cancle" onClick={closeClick}>취소</BaseButton>
                </div>
            </div>

        </>
    );
}
export default SelectModal;


