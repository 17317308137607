// BaseAlert.js
import React from 'react';
import '../styles/BaseAlert.css';
import { BaseButton } from "../BaseComponents/BaseButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faExclamationTriangle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import {useNavigate} from "react-router-dom";

function BaseAlert({msg, ...props}) {
    return (
        <div className="alert-body">
            <div className="alert-form">
                <FontAwesomeIcon className="icon" icon={faInfoCircle} /> {msg}
            </div>
        </div>
    );
}

function BaseAlertWarn({msg, ...props}) {
    return (
        <div className="alert-body">
            <div className="alert-form">
                <FontAwesomeIcon className="icon yellow" icon={faExclamationTriangle} /> {msg}
            </div>
        </div>
    );
}


function BaseAlertDanger({msg, ...props}) {
    return (
        <div className="alert-body">
            <div className="alert-form">
                <FontAwesomeIcon className="icon red" icon={faTimesCircle} /> {msg}
            </div>
        </div>
    );
}

function BaseFullAlert({msg, handleBack, ...props}) {
    let navigate = useNavigate();

    const handleBackToLogin = () => {
        navigate(`/`);
    };

    return (
        <div className="alert-body-full column align-center full-size">
            <div className="column alert-form-full align-center">
                <div className="row align-center">
                    <FontAwesomeIcon className="icon red" icon={faTimesCircle} /> {msg}
                </div>
                <BaseButton onClick={handleBack ? handleBack : handleBackToLogin}>돌아가기</BaseButton>
            </div>
        </div>
    );
}

export { BaseAlert, BaseAlertWarn, BaseAlertDanger, BaseFullAlert };
