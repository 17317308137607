import React, {useState, useEffect, useContext, useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAdd,
    faArrowLeft,
    faCogs,
    faCrown,
    faHome,
    faSearch,
    faTasks,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import BaseInput from "../../BaseComponents/BaseInput";
import {BaseButtonAwesome, BaseButtonField} from "../../BaseComponents/BaseButton";
import {GlobalContext} from "../../pages/HomePage";
import { BaseLoading, BaseLoadingInContainer } from "../../BaseComponents/BaseLoading";
import {FixedSizeList as List} from "react-window";
import ResizeObserver from "resize-observer-polyfill";
import BaseNoData from "../../BaseComponents/BaseNoData";
import InfoModal_requested from "../../modals/WorkHistory/InfoModal_requested";
import {backendServer} from "../../App";
import {BaseAlert, BaseAlertDanger, BaseAlertWarn} from "../../BaseComponents/BaseAlert";
import SliderImageView from "../../modules/Slider";
import ReportModal from "../../modals/assignwork/ReportModal";

const ITEM_SIZE = 44;
const widthPercentage = ['100px','150px','250px','200px','250px','250px','200px','250px','250px','200px','250px','250px','600px','400px','200px'];
function Main() {
    const { userData, setUserData, isInit, setIsInit, timeRemainingRef } = useContext(GlobalContext);

    const [ currentWorkList, setCurrentWorkList] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isRegLoading, setIsRegLoading ] = useState(false);

    const [ alertTimeout, setAlertTimeout ] = useState(null);
    const [ isAlert, setIsAlert] = useState(-1);
    const [alertMsg, setAlertMsg] = useState("");
    const setIsAlertWithTimeout = (alertType, time = 3500) => {
        // 이미 설정된 setTimeout이 있다면 취소합니다.
        if (alertTimeout) {
            clearTimeout(alertTimeout);
        }

        setIsAlert(alertType);

        // 3.5초 후에 알림을 사라지게 만듭니다.
        const timeoutId = setTimeout(() => {
            setIsAlert(-1);
        }, time);

        // 새로 설정된 setTimeout의 ID를 저장합니다.
        setAlertTimeout(timeoutId);
    }

    const divRef = useRef(null);
    const [listHeight, setListHeight] = useState(468);
    const [containerWidth, setContainerWidth] = useState(0);
    const containerRef = useRef(null);

    const [ isModal, setIsModal ] = useState(false);
    const [ modalType, setModalType ] = useState(-1);
    const [ selectedWork, setSelectedWork ] = useState(null);

    const handleCloseModal = () => {
        setModalType(-1);
        setIsModal(false);
    }

    const handleOpenModal = (type) => {
        setModalType(type);
        setIsModal(true);
    }

    const [ searchValues, setSearchValues ] = useState({
        machine_name: "", requested_company_name: "", user_name : '',
        start_time : '', start_end_time : '', end_time : '', end_end_time : ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;

        setSearchValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setContainerWidth(entry.contentRect.width);
            }
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [containerRef]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setListHeight(entry.contentRect.height);
            }
        });

        if (divRef.current) {
            resizeObserver.observe(divRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [divRef]);

    const getWorkList = async () => {
        const headerData = {
            status: "상태",
            user_name: "작업자",
            user_phone: "작업자 연락처",
            requesting_company_name: "요청한 회사",
            requesting_company_phone: "회사 번호",
            requested_company_name: "작업한 회사",
            requested_company_phone: "회사 번호",
            machine_name: "작업 플랜트",
            start_time: "시작 시간",
            end_time: "종료 시간",
            maintenance_details: "유지보수 내용",
            special_notes: "특이사항",
            images: "작업사진",
            request_date: "배정 날짜",
            accepted_date: "배정 날짜",
        }

        setIsLoading(true);
        try {
            const requestBody = {
                searchCriteria: searchValues  // 추가된 부분
            };

            const response = await fetch(`https://${backendServer}/get-worklist`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody),
                credentials: 'include'
            });

            if (response.ok) {
                const histories = await response.json();
                setCurrentWorkList(prevState => [headerData, ...histories]);
            } else {
                const errorData = await response.json();
                if(errorData.error === 'SessionExpired') {
                    setUserData(null);
                    setIsInit(true);
                } else if(errorData.error === 'NotLoggedIn') {
                    setUserData(null);
                    setIsInit(true);
                }
            }
        } catch (error) {
            setAlertMsg("서버에 연결할 수 없습니다. 관리자에게 문의해보세요.");
            setIsAlertWithTimeout(2);
        } finally {
            setIsLoading(false);
            timeRemainingRef.current = (60 * 60);
        }
    }

    function TableRow({ index, style, data }) {
        const history = data[index];

        const rowStyle = {
            ...style,
            top: ITEM_SIZE * index - 16,
        };

        const headerStyle = {
            height: '28px'
        };

        const bodyStyle = {
            backgroundColor: index % 2 === 1 ? '#e8e8e8' : 'transparent',
        }

        if (containerWidth < 910) {
            headerStyle.width = '900px';
            bodyStyle.width = '900px';
        }

        const getStyle = (widthPercentage, isFirst) => {
            if (containerWidth < 910){
                if(isFirst){
                    return { width: widthPercentage,
                        backgroundColor: index % 2 === 1 ? '#e8e8e8' : '#FFFFFF',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }else{
                    return { width: widthPercentage,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }
            }else{
                if(isFirst){
                    return { flex: widthPercentage,
                        backgroundColor: index % 2 === 1 ? '#e8e8e8' : '#FFFFFF',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }else{
                    return { flex: widthPercentage,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }
            }
        }

        function formatDate(isoString) {
            if (!isoString || isNaN(new Date(isoString).getTime())) {
                return "";
            }

            const date = new Date(isoString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');  // 월은 0부터 시작하기 때문에 1을 더해줍니다.
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }

        const openImage = (data) => {
            if (data && (data[0] || data[1] || data[2])) {
                const imageHTML = new SliderImageView(data);
                const imageWindow = window.open();
                imageWindow.document.write(imageHTML.getHTML());
            } else {
                setAlertMsg("저장된 이미지가 없습니다.");
                setIsAlertWithTimeout(1);
            }
        }

        return (
            <>
                {index === 0 ? (
                    <>
                        <div className="row full-size-width table-header" style={headerStyle}>
                            <div className="column align-center first-child" style={getStyle(widthPercentage[0])}>
                                <p>{history.status}</p>
                            </div>
                            <div className="column align-center" style={getStyle(widthPercentage[9])}>
                                <p>{history.machine_name}</p>
                            </div>
                            <div className="column align-center" style={getStyle(widthPercentage[3])}>
                                <p>{history.requesting_company_name}</p>
                            </div>
                            <div className="column align-center" style={getStyle(widthPercentage[5])}>
                                <p>{history.start_time}</p>
                            </div>
                            <div className="column align-center" style={getStyle(widthPercentage[5])}>
                                <p>{history.accepted_date}</p>
                            </div>
                            <div className="column align-center" style={getStyle(widthPercentage[1])}>
                                <p>일지 작성</p>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div style={rowStyle}>
                            <div className="row full-size-width table-item" style={bodyStyle}>
                                <div className="column align-center first-column" style={getStyle(widthPercentage[0], true)}>
                                    <p>{(!history.start_time && !history.end_time) ? "배정됌" : "작업 중"}</p>
                                </div>
                                <div className="column align-center" style={getStyle(widthPercentage[9])}>
                                    <p>{history.machine_name}</p>
                                </div>
                                <div className="column align-center" style={getStyle(widthPercentage[3])}>
                                    <p>{history.requesting_company_name}</p>
                                </div>
                                <div className="column align-center" style={getStyle(widthPercentage[5])}>
                                    <p>{formatDate(history.start_time)}</p>
                                </div>
                                <div className="column align-center" style={getStyle(widthPercentage[5])}>
                                    <p>{formatDate(history.accepted_date)}</p>
                                </div>
                                <div className="column align-center" style={getStyle(widthPercentage[1])}>
                                    <BaseButtonField disabled={(!history.start_time && !history.end_time)} onClick={()=> {
                                        setSelectedWork(history);
                                        handleOpenModal(0);
                                    }}>일지 작성</BaseButtonField>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    }

    return (
        <div className="full-size column align-center">
            {isLoading && (
                <>
                    <BaseLoading/>
                </>
            )}
            <div className="seperator-half"></div>
            <div className="row align-center-left main-table-header full-size-width">
                <FontAwesomeIcon className="menu" icon={faTasks} />
                <div className="seperator-horz"></div>
                <p className="medium-title bold">작업 내역</p>
            </div>
            <div className="row full-size-width">
                <div className="column grow-size align-center search-info-container">
                    <p className="normal-info bold text-align-left full-size-width">검색 기준</p>
                    <div className="row full-size-width">
                        <BaseInput
                            name="machine_name"
                            aria-description="condition"
                            value={searchValues.machine_name}
                            placeholder="플랜트명"
                            onChange={handleChange}
                        />
                        <div className="seperator-horz"></div>
                        <BaseInput
                            name="requesting_company_name"
                            aria-description="condition"
                            value={searchValues.requesting_company_name}
                            placeholder="소유기업"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="column grow-size align-center search-info-container">
                    <p className="normal-info bold text-align-left full-size-width">배정 날짜 기준</p>
                    <div className="row full-size-width">
                        <BaseInput
                            name="accepted_date"
                            type="date"
                            data-placeholder="검색시작일"
                            aria-description="condition"
                            value={searchValues.accepted_date}
                            required
                            aria-required="true"
                            onChange={handleChange}
                        />
                        <div className="seperator-horz"></div>
                        <BaseInput
                            name="accepted_end_date"
                            type="date"
                            data-placeholder="검색종료일"
                            aria-description="condition"
                            value={searchValues.accepted_end_date}
                            required
                            aria-required="true"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="column align-center buttons">
                    <BaseButtonAwesome icon={faSearch} onClick={getWorkList}/>
                </div>
            </div>
            <div className="column grow-size full-size-width">
                <div className="seperator"></div>
                <div className="column main-table-body" ref={containerRef}>
                    <div className="table-container-h2" ref={divRef}>
                        <>
                            {currentWorkList.length === 1 ? (
                                <>
                                    <BaseNoData/>
                                </>
                            ) : (
                                <>
                                    <div className="row table-body-container">
                                        <List
                                            width={containerWidth}
                                            height={listHeight}
                                            className="table full-size-width"
                                            itemCount={currentWorkList.length}
                                            itemSize={ITEM_SIZE}
                                            itemData={currentWorkList}
                                        >
                                            {TableRow}
                                        </List>
                                    </div>
                                </>
                            )}
                        </>
                    </div>
                </div>
            </div>
            { isModal && modalType === 0 && selectedWork && (
                <>
                    <div className="modal full-size column align-center">
                        <div className="register-form" style={{width: "50%", height: "75%", boxSizing:"border-box"}}>
                            {isRegLoading ? (
                                <>
                                    <BaseLoadingInContainer/>
                                </>
                            ):(
                                <>
                                    <ReportModal
                                        selectedWork = {selectedWork}
                                        closeClick={() => {
                                            handleCloseModal()
                                            setSelectedWork(null)
                                        }}
                                        handleDone={() => {
                                            console.log("Done")
                                            handleCloseModal()
                                            setSelectedWork(null)
                                            getWorkList()
                                        }}
                                    />
                                </>
                            )}

                        </div>
                    </div>
                </>
            )}
            {isAlert === 0 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                <>
                    <BaseAlert msg={alertMsg}></BaseAlert>
                </>
            )}
            {isAlert === 1 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                <>
                    <BaseAlertWarn msg={alertMsg}></BaseAlertWarn>
                </>
            )}
            {isAlert === 2 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                <>
                    <BaseAlertDanger msg={alertMsg}></BaseAlertDanger>
                </>
            )}
        </div>
    );
}

export default Main;
