import React from 'react';
import '../styles/BaseLoading.css';
import {RingLoader, ScaleLoader} from 'react-spinners';

function BaseLoading(props) {

    return (
        <div className="column align-center full-size spinner-container">
            <ScaleLoader color="#FFFFF0" size={40} />
        </div>
    );
}

function BaseLoadingInContainer(props) {
    return (
        <div className="column align-center full-size spinner-container-trans" {...props}>
            <RingLoader color="#000000" size={40} />
        </div>
    );
}

export { BaseLoading, BaseLoadingInContainer };

