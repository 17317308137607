import "../../styles/Reservation.css"
import React, {useContext, useState} from "react";
import Main from "./main";
import {ReservationContext} from "../../pages/AdmHomePage";

function Reservation() {
    const { activeRVHistory, setActiveRVHistory, selectedRVItem, setSelectedRVItem } = useContext(ReservationContext);

    return (
        <>
            {!activeRVHistory && !selectedRVItem && (
                <Main/>
            )}
        </>
    );
}

export default Reservation;
