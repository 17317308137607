import {BaseLoadingInContainer} from "../../BaseComponents/BaseLoading";
import {BaseButton, BaseButtonAwesomeTrans, BaseButtonField} from "../../BaseComponents/BaseButton";
import {faClose, faTrash} from "@fortawesome/free-solid-svg-icons";
import BaseInput from "../../BaseComponents/BaseInput";
import React, {useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BaseImage} from "../../BaseComponents/BaseImage";
import uploadSVG from "../../images/upload-solid.svg";

function ModifyModal(props) {
    const fileInputRef = useRef(null); // useRef를 사용하여 참조를 생성합니다.
    const { values, imageValue, closeClick, handleChange, handleModify, handleClick, onImageUpload, deleteImage, handleDelete } = props;

    const [selectedImage, setSelectedImage ] = useState("e");

    const loadImage = (data) => {
        return `data:image/png;base64,${data}`;
    }

    const handleImageClick = () => {
        fileInputRef.current.click(); // fileInputRef를 사용하여 file input을 클릭합니다.
    }

    function resizeImage(file, maxWidth, maxHeight, quality = 0.8, callback) {
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');

            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            // 여기서 'image/jpeg'는 결과 이미지의 형식이며,
            // quality는 JPEG 압축률을 나타냅니다 (0~1 사이의 값).
            canvas.toBlob((blob) => {
                callback(blob);
            }, 'image/jpeg', quality);
        };
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            resizeImage(file, 800, 600, 0.8, (resizedBlob) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    onImageUpload(e.target.result, resizedBlob, selectedImage);
                };
                reader.readAsDataURL(resizedBlob);
            });
        }
    }

    return (
        <>
            <div className="row align-center full-size-width register-form-header">
                <p className="medium-title bold">플랜트 정보 확인 및 수정</p>
                <BaseButtonAwesomeTrans icon={faClose} onClick={closeClick} />
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">플랜트명</span>
                    <BaseInput
                        name="machine_name"
                        placeholder="플랜트명"
                        value={values.machine_name}
                        onChange={handleChange}
                    />
                </div>
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left full-size-width">시리얼 넘버</span>
                    <BaseInput
                        name="serial_number"
                        placeholder="시리얼 넘버"
                        value={values.serial_number}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">구매일</span>
                    <BaseInput
                        name="purchase_date"
                        placeholder="플랜트 구매일"
                        type="date"
                        value={values.purchase_date}
                        onChange={handleChange}
                    />
                </div>
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left full-size-width">설치일</span>
                    <BaseInput
                        name="installation_date"
                        placeholder="플랜트 설치일"
                        type="date"
                        value={values.installation_date}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">위치</span>
                    <BaseInput
                        name="location"
                        placeholder="플랜트 위치"
                        value={values.location}
                        onChange={handleChange}
                        onClick={handleClick}
                    />
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">담당자</span>
                    <BaseInput
                        name="manager_name"
                        placeholder="담당자 이름"
                        value={values.manager_name}
                        onChange={handleChange}
                    />
                </div>
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">연락처</span>
                    <BaseInput
                        name="manager_contact"
                        placeholder="담당자 연락처"
                        value={values.manager_contact}
                        onChange={handleChange}
                    />
                </div>
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">모델명</span>
                    <BaseInput
                        name="model_name"
                        placeholder="모델명"
                        value={values.model_name}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left" style={{margin:"0 0 0.5rem 0"}}>사진보기</span>
                    <div className="row full-size-width">
                        <BaseButtonField style={{margin: "0 0.5rem", color: selectedImage === "e" ? "#ffffff80" : "#333333"}}
                                         onClick={() => {
                                           setSelectedImage("e");
                                         }}
                        >플랜트</BaseButtonField>
                        <BaseButtonField style={{margin: "0 0.5rem", color: selectedImage === "qr" ? "#ffffff80" : "#333333"}}
                                         onClick={() => {
                                             setSelectedImage("qr");
                                         }}
                        >QR 코드</BaseButtonField>
                        <BaseButtonField style={{margin: "0 0.5rem", color: selectedImage === "qr_l" ? "#ffffff80" : "#333333"}}
                                         onClick={() => {
                                             setSelectedImage("qr_l");
                                         }}
                        >QR 코드 위치</BaseButtonField>
                    </div>
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item align-center">
                    <div className="row full-size-width">
                        <span className="grow-size normal-info bold text-align-left">
                            {selectedImage === "e" && "플랜트 사진"}
                            {selectedImage === "qr" && "QR 사진"}
                            {selectedImage === "qr_l" && "QR 위치 사진"}
                        </span>
                        {selectedImage !== "qr" && (
                            <FontAwesomeIcon icon={faTrash} onClick={()=>{
                                deleteImage(selectedImage);
                            }} style={{cursor: "pointer"}}/>
                        )}
                    </div>
                    <div className="column align-center upload-box"
                         onClick={handleImageClick}
                         style={!imageValue.url ? { padding: "4rem 0" } : {}}>
                        <BaseImage
                            src={
                                selectedImage === "e" ?
                                    (imageValue.e && imageValue.e.url ? loadImage(imageValue.e.url) : uploadSVG)
                                    : selectedImage === "qr" ?
                                        (imageValue.qr && imageValue.qr.url ? loadImage(imageValue.qr.url) : uploadSVG)
                                        : selectedImage === "qr_l" ?
                                            (imageValue.qr_l && imageValue.qr_l.url ? loadImage(imageValue.qr_l.url) : uploadSVG)
                                            : uploadSVG
                            }
                            alt="machine-picture"
                            width="100%"
                            height="100%"
                        />
                    </div>
                    <input
                        disabled={selectedImage === "qr"}
                        type="file"
                        style={{ display: 'none' }}
                        ref={fileInputRef} // fileInputRef를 file input element에 연결합니다.
                        onChange={handleImageChange}
                        accept=".png, .jpg, .jpeg"
                    />
                </div>
            </div>
            <div className="seperator"></div>
            <div className="row">
                <BaseButton onClick={handleDelete} style={{backgroundColor: "#ff7a7a"}}>삭제하기</BaseButton>
                <div className="seperator-horz-half"></div>
                <BaseButton onClick={handleModify}>수정하기</BaseButton>
            </div>
        </>
    );
}
export default ModifyModal;


