import {BaseLoadingInContainer} from "../../BaseComponents/BaseLoading";
import {BaseButton, BaseButtonAwesomeTrans} from "../../BaseComponents/BaseButton";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import BaseInput from "../../BaseComponents/BaseInput";
import React from "react";

function ModifyModal(props) {
    const { values, closeClick, handleChange, handleModify, handleDelete } = props;

    return (
        <>
            <div className="row align-center full-size-width register-form-header">
                <p className="medium-title bold">유지보수 작업자 수정</p>
                <BaseButtonAwesomeTrans icon={faClose} onClick={closeClick} />
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">이름</span>
                    <BaseInput
                        name="name"
                        placeholder="작업자 이름"
                        value={values.name}
                        onChange={handleChange}
                    />
                </div>
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left full-size-width">연락처</span>
                    <BaseInput
                        name="phone"
                        placeholder="작업자 연락처"
                        value={values.phone}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">아이디</span>
                    <BaseInput
                        name="ID"
                        placeholder="작업자가 사용할 아이디"
                        value={values.ID}
                        onChange={handleChange}
                    />
                </div>
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left full-size-width">비밀번호</span>
                    <BaseInput
                        name="PWD"
                        placeholder="비워두면 기존 비밀번호 사용"
                        value={values.PWD}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row full-size-width">
                <div className="column register-form-item">
                    <span className="normal-info bold text-align-left">이메일</span>
                    <BaseInput
                        name="email"
                        placeholder="작업자 이메일"
                        value={values.email}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="seperator"></div>
            <div className="row">
                <BaseButton onClick={handleDelete} style={{backgroundColor: "#ff7a7a"}}>삭제하기</BaseButton>
                <div className="seperator-horz-half"></div>
                <BaseButton onClick={handleModify}>수정하기</BaseButton>
            </div>
        </>
    );
}
export default ModifyModal;


