import "../../styles/Technician.css"
import React, {useContext, useState} from "react";
import Main from "../Machine/main";
import {MachineContext } from "../../pages/AdmHomePage";
import History from "./history";

function Machine() {
    const { activeMCHistory, setActiveMCHistory, selectedMCItem, setSelectedMCItem } = useContext(MachineContext);

    return (
        <>
            {!activeMCHistory && !selectedMCItem && (
                <Main/>
            )}
            { activeMCHistory && !selectedMCItem && (
                <History/>
            )}
        </>
    );
}

export default Machine;
