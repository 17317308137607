import {BaseLoadingInContainer} from "../../BaseComponents/BaseLoading";
import {BaseButton, BaseButtonAwesomeTrans, BaseButtonField} from "../../BaseComponents/BaseButton";
import {faClose, faTrash} from "@fortawesome/free-solid-svg-icons";
import BaseInput from "../../BaseComponents/BaseInput";
import React, { useRef } from "react";
import {BaseImage, BaseImageSquare} from "../../BaseComponents/BaseImage";
import uploadSVG from "../../images/upload-solid.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BaseTextArea from "../../BaseComponents/BaseTextArea";
import {BaseAutocompleteInput} from "../../BaseComponents/BaseAutocompleteInput";
import BaseSliderImageViewer from "../../BaseComponents/BaseSliderImageViewer";
import * as XLSX from "xlsx-js-style";

function InfoModal_requested(props) {
    const { selectedHistory, closeClick } = props;

    function formatDate(isoString) {
        if(isoString === "" || !isoString){
            return "미완료";
        }
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');  // 월은 0부터 시작하기 때문에 1을 더해줍니다.
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const handleExportExcel = () => {
        function formatDate(isoString) {
            if (!isoString || isNaN(new Date(isoString).getTime())) {
                return "";
            }

            const date = new Date(isoString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');  // 월은 0부터 시작하기 때문에 1을 더해줍니다.
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }

        const borderStyle = {
            style: 'medium',
            color: { rgb: '548235' }
        };

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([[]]);

        const columnWidths = [
            { wch: 1.86 },
            { wch: 3.00 },
            { wch: 11.57 },
            { wch: 5.29 },
            { wch: 5.29 },
            { wch: 11.57 },
            { wch: 2.71 },
            { wch: 2.71 },
            { wch: 2.71 },
            { wch: 2.71 },
            { wch: 2.71 },
            { wch: 10.71 },
            { wch: 3.29 },
            { wch: 4.00 },
            { wch: 2.71 },
            { wch: 4.43 },
            { wch: 11.57 },
            { wch: 9.57 },
            { wch: 15.43 },
        ];

        ws['!cols'] = columnWidths;

        ws['!rows'] = [
            { hpx: 15.00 },
            { hpx: 30.00 },
            { hpx: 10.50 },
            { hpx: 24.75 },
            { hpx: 24.75 },
            { hpx: 24.75 },
            { hpx: 24.75 },
            { hpx: 24.75 },
            { hpx: 24.75 },
            { hpx: 24.75 },
            { hpx: 98.25 },
            { hpx: 98.25 },
            { hpx: 10.50 },
        ];

        // A1부터 S1까지의 범위를 하나의 셀로 병합
        ws['!merges'] = [
            { s: { r: 1, c: 1 }, e: { r: 1, c: 18 } },
            { s: { r: 3, c: 1 }, e: { r: 3, c: 2 } },
            { s: { r: 3, c: 3 }, e: { r: 3, c: 11 } },
            { s: { r: 3, c: 12 }, e: { r: 3, c: 18 } },
            { s: { r: 4, c: 1 }, e: { r: 4, c: 2 } },
            { s: { r: 4, c: 3 }, e: { r: 4, c: 11 } },
            { s: { r: 5, c: 1 }, e: { r: 5, c: 2 } },
            { s: { r: 5, c: 3 }, e: { r: 5, c: 11 } },
            { s: { r: 6, c: 1 }, e: { r: 6, c: 2 } },
            { s: { r: 6, c: 3 }, e: { r: 6, c: 11 } },
            { s: { r: 7, c: 1 }, e: { r: 7, c: 2 } },
            { s: { r: 7, c: 3 }, e: { r: 7, c: 11 } },
            { s: { r: 8, c: 1 }, e: { r: 8, c: 2 } },
            { s: { r: 8, c: 3 }, e: { r: 8, c: 11 } },
            { s: { r: 9, c: 1 }, e: { r: 9, c: 2 } },
            { s: { r: 9, c: 3 }, e: { r: 9, c: 11 } },
            { s: { r: 10, c: 1 }, e: { r: 10, c: 2 } },
            { s: { r: 10, c: 3 }, e: { r: 10, c: 11 } },
            { s: { r: 11, c: 1 }, e: { r: 11, c: 2 } },
            { s: { r: 11, c: 3 }, e: { r: 11, c: 11 } },
            { s: { r: 2, c: 1 }, e: { r: 2, c: 18 } },
            { s: { r: 12, c: 1 }, e: { r: 12, c: 18 } },
            { s: { r: 4, c: 12 }, e: { r: 11, c: 18 } },
        ];

        XLSX.utils.sheet_add_aoa(ws, [['작업 일지', '작업 일지']], { origin: 'B2' });
        XLSX.utils.sheet_add_aoa(ws, [['플랜트명', '플랜트명']], { origin: 'B4' });
        XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: 'C4' });
        XLSX.utils.sheet_add_aoa(ws, [['유지보수업체', '유지보수업체']], { origin: 'B5' });
        XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: 'C5' });
        XLSX.utils.sheet_add_aoa(ws, [['유지보수 작업자', '유지보수 작업자']], { origin: 'B6' });
        XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: 'C6' });
        XLSX.utils.sheet_add_aoa(ws, [['연락처', '연락처']], { origin: 'B7' });
        XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: 'C7' });
        XLSX.utils.sheet_add_aoa(ws, [['수락일시', '수락일시']], { origin: 'B8' });
        XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: 'C8' });
        XLSX.utils.sheet_add_aoa(ws, [['작업시작', '작업시작']], { origin: 'B9' });
        XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: 'C9' });
        XLSX.utils.sheet_add_aoa(ws, [['작업종료', '작업종료']], { origin: 'B10' });
        XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: 'C10' });
        XLSX.utils.sheet_add_aoa(ws, [['유지보수내용', '유지보수내용']], { origin: 'B11' });
        XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: 'C11' });
        XLSX.utils.sheet_add_aoa(ws, [['특이사항', '특이사항']], { origin: 'B12' });
        XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: 'C12' });

        ws['B2'].s = {
            font: { name: '맑은 고딕', bold: true, sz: 22, color: { rgb: '548235' } },
            alignment: { horizontal: 'center' },
        };

        let range = XLSX.utils.decode_range('B4:C12');
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
                const cell = ws[cellRef];

                if(C === 1){
                    cell.s = {
                        font: { name: '맑은 고딕', bold: true, sz: 9 },
                        alignment: { horizontal: 'center', vertical: 'center' },
                        fill: { fgColor: { rgb: 'F2F2F2' } },
                        border: {
                            bottom: {
                                style: 'thin',
                                color: { rgb: 'C7CCD9' },
                            }
                        }
                    };
                }else{
                    cell.s = {
                        border: {
                            bottom: {
                                style: 'thin',
                                color: { rgb: 'C7CCD9' },
                            }
                        }
                    };
                }

                if(R === 3){
                    cell.s.border.top = borderStyle;
                }
            }
        }

        range = XLSX.utils.decode_range('D4:L12');
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
                if(cellRef === 'D4'){
                    XLSX.utils.sheet_add_aoa(ws, [[selectedHistory.machine_name, selectedHistory.machine_name]], { origin: cellRef });
                }else if(cellRef === 'D5'){
                    XLSX.utils.sheet_add_aoa(ws, [[selectedHistory.requested_company_name, selectedHistory.requested_company_name]], { origin: cellRef });
                }else if(cellRef === 'D6'){
                    XLSX.utils.sheet_add_aoa(ws, [[selectedHistory.user_name, selectedHistory.user_name]], { origin: cellRef });
                }else if(cellRef === 'D7'){
                    XLSX.utils.sheet_add_aoa(ws, [[selectedHistory.user_phone, selectedHistory.user_phone]], { origin: cellRef });
                }else if(cellRef === 'D8'){
                    XLSX.utils.sheet_add_aoa(ws, [[formatDate(selectedHistory.accepted_date), formatDate(selectedHistory.accepted_date)]], { origin: cellRef });
                }else if(cellRef === 'D9'){
                    XLSX.utils.sheet_add_aoa(ws, [[formatDate(selectedHistory.start_time), formatDate(selectedHistory.start_time)]], { origin: cellRef });
                }else if(cellRef === 'D10'){
                    XLSX.utils.sheet_add_aoa(ws, [[formatDate(selectedHistory.end_time), formatDate(selectedHistory.end_time)]], { origin: cellRef });
                }else if(cellRef === 'D11'){
                    XLSX.utils.sheet_add_aoa(ws, [[selectedHistory.maintenance_details, selectedHistory.maintenance_details]], { origin: cellRef });
                }else if(cellRef === 'D12'){
                    XLSX.utils.sheet_add_aoa(ws, [[selectedHistory.special_notes, selectedHistory.special_notes]], { origin: cellRef });
                }else{
                    XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: cellRef });
                }

                const cell = ws[cellRef];

                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'center', vertical: 'center' },
                    border: {
                        left: {
                            style: 'thin',
                            color: { rgb: 'C7CCD9' },
                        },
                        bottom: {
                            style: 'thin',
                            color: { rgb: 'C7CCD9' },
                        }
                    }
                };

                if(R === 3){
                    cell.s.border.top = borderStyle;
                }
            }
        }

        range = XLSX.utils.decode_range('M4:S4');
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellRef = XLSX.utils.encode_cell({ r: R, c: C });

                if (C === 12) {
                    XLSX.utils.sheet_add_aoa(ws, [['작업 사진']], { origin: cellRef });
                    ws[cellRef].s = {
                        font: { name: '맑은 고딕', bold: true, sz: 9 },
                        alignment: { horizontal: 'center', vertical: 'center' },
                        fill: { fgColor: { rgb: 'F2F2F2' } },
                        border: {
                            top: borderStyle
                        }
                    };
                } else {
                    // 나머지 셀들은 빈 데이터로 설정
                    XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: cellRef });
                    ws[cellRef].s = {
                        border: {
                            top: borderStyle
                        }
                    };
                }
            }
        }

        range = XLSX.utils.decode_range('B13:S13');
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
                XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: cellRef });
                ws[cellRef].s = {
                    border: {
                        bottom: borderStyle
                    }
                };
            }
        }

        // 워크북에 시트 추가
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const today = new Date();
        const baseFileName = `[${selectedHistory.machine_name}] 작업 일지 ${formatDate(selectedHistory.start_time)}`
        // 엑셀 파일로 저장
        XLSX.writeFile(wb, `${baseFileName}.xlsx`);

        // 이미지 다운로드
        const images = [selectedHistory.image1, selectedHistory.image2, selectedHistory.image3];

        images.forEach((imageBase64, index) => {
            if (imageBase64) { // 이미지 데이터가 유효한 경우
                const imageSrc = `data:image/jpeg;base64,${imageBase64}`;
                const link = document.createElement('a');
                link.href = imageSrc;
                link.download = `${baseFileName}_image_${index + 1}.jpeg`; // 이미지 파일명 설정
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        });
    };

    return (
        <div className="row">
            <div className="column full-size-width">
                <div className="row align-center full-size-width register-form-header">
                    <div className="column">
                        <p className="medium-title bold" style={{whiteSpace: "nowrap", margin: "0.5rem 0"}}>유지보수 보고서</p>
                        <p className="normal-info" style={{whiteSpace: "nowrap", margin: 0}}>플랜트명 : '{selectedHistory.machine_name}'</p>
                    </div>
                </div>
                <div className="row full-size-width">
                    <div className="column register-form-item">
                        <span className="normal-info bold text-align-left">유지보수 내용</span>
                        <div className="column full-size-width textarea-field">
                            <p>{selectedHistory.end_time ? selectedHistory.maintenance_details : "작 업 중"}</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="column register-form-item">
                        <span className="normal-info bold text-align-left">특이사항</span>
                        <div className="column full-size-width textarea-field">
                            <p>{selectedHistory.end_time ? selectedHistory.special_notes : "작 업 중"}</p>
                        </div>
                    </div>
                </div>
                <div className="row full-size-width">
                    <div className="column register-form-item">
                        <span className="normal-info bold text-align-left">작업 요청 회사</span>
                        <div className="column full-size-width text-field">
                            <p style={{whiteSpace: "nowrap"}}>{selectedHistory.requesting_company_name}</p>
                        </div>
                    </div>
                </div>
                <div className="row full-size-width">
                    <div className="column register-form-item">
                        <span className="normal-info bold text-align-left">작업자</span>
                        <div className="column full-size-width text-field">
                            <p style={{whiteSpace: "nowrap"}}>{selectedHistory.user_name}</p>
                        </div>
                    </div>
                    <div className="column register-form-item">
                        <span className="normal-info bold text-align-left">연락처</span>
                        <div className="column full-size-width text-field">
                            <p style={{whiteSpace: "nowrap"}}>{selectedHistory.user_phone}</p>
                        </div>
                    </div>
                </div>
                <div className="row full-size-width">
                    <div className="column register-form-item">
                        <span className="normal-info bold text-align-left">작업 시작</span>
                        <div className="column full-size-width text-field">
                            <p style={{whiteSpace: "nowrap"}}>{formatDate(selectedHistory.start_time)}</p>
                        </div>
                    </div>
                    <div className="column register-form-item">
                        <span className="normal-info bold text-align-left">작업 종료</span>
                        <div className="column full-size-width text-field">
                            <p style={{whiteSpace: "nowrap"}}>{formatDate(selectedHistory.end_time)}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="column full-size-width" style={{paddingBottom: "1rem"}}>
                <div className="row align-center full-size-width register-form-header">
                    <p className="medium-title bold" style={{whiteSpace: "nowrap", margin: "0.5rem 0"}}>작업사진</p>
                    <BaseButtonAwesomeTrans icon={faClose} onClick={closeClick} />
                </div>
                <BaseSliderImageViewer data={[selectedHistory.image1,selectedHistory.image2,selectedHistory.image3]} />
                <BaseButtonField onClick={handleExportExcel}>엑셀 다운로드</BaseButtonField>
            </div>
        </div>
    );
}
export default InfoModal_requested;


