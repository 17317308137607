import React, {useContext, useEffect, useRef, useState} from 'react';
import '../styles/AdmHomePage.css';
import {backendServer} from "../App";
import {
    faBars, faBuilding,
    faCalendarAlt,
    faChartBar,
    faChartPie,
    faCogs, faFax,
    faHistory, faHome, faLocationPinLock, faMailBulk, faPhone, faSignOut,
    faTasks, faTimes, faUserCog,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import {BaseLoading} from "../BaseComponents/BaseLoading";
import {BaseAlert, BaseAlertDanger, BaseAlertWarn, BaseFullAlert} from "../BaseComponents/BaseAlert";
import {BaseImage, BaseImageCircle} from "../BaseComponents/BaseImage";
import logo from "../images/logo.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import format from "date-fns/format";
import {BaseButton} from "../BaseComponents/BaseButton";
import TabHome from "../tabpages/HomeRegular";
import TabWorkHistory from "../tabpages/WorkHistory/WorkHistory_R";
import TabAssignWork from "../tabpages/AssignWork/AssignWork";

export const GlobalContext = React.createContext();
export const WorkHistoryContext = React.createContext();

function HomePage() {
    const [ currentWorkHistories, setCurrentWorkHistories ] = useState([]);

    const [tabs, setTabs] = useState(['홈']);
    const [activeTab, setActiveTab] = useState('홈');
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isAlert, setIsAlert] = useState(-1);
    const [alertMsg, setAlertMsg] = useState("");
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isInit, setIsInit] = useState(false);
    const [isLogout, setIsLogout] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);

    const timeDisplay = useRef(null);
    const timeRemainingRef = useRef(3600);
    const checkSession = async (callback) => {
        try {
            const response = await fetch(`https://${backendServer}/check-session`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            });

            if (response.ok) {
                await callback(response);
            }
        } catch (error) {
            console.error("Error checking session:", error);
        }
    };

    const checkFirstCheck = async (response) => {
        const data = await response.json();

        // 예를 들어, 서버에서 'loggedIn' 속성을 true로 응답했다면 홈페이지로 리디렉션
        if (data.loggedIn) {
            const userDataResponse = await fetch(`https://${backendServer}/get-user-data/regular`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
            });

            if (userDataResponse.ok) {
                const fetchedUserData = await userDataResponse.json();
                setUserData(fetchedUserData);
            } else {
                console.error("Error fetching user data.");
                setIsCorrect(true);
            }
        }else{
            setIsCorrect(true);
        }
    }

    useEffect(() => {
        checkSession(checkFirstCheck);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (timeRemainingRef.current <= 1) {
                // 시간이 다 되었으므로 로그아웃 처리
                fetch(`https://${backendServer}/logout`, {
                    method: 'POST',
                    credentials: 'include'
                }).then(() => {
                    // 로그아웃 처리 후 필요한 로직 추가
                    clearInterval(intervalId);  // 타이머 종료
                });
                timeRemainingRef.current = 0;  // 남은 시간을 0으로 설정
            } else {
                timeRemainingRef.current -= 1;  // 남은 시간을 1초 감소
            }

            if (timeDisplay.current) {
                timeDisplay.current.textContent = `자동 로그아웃까지 ${formatTimeRemaining()}`;
            }

        }, 1000);  // 매 초마다

        // useEffect의 반환 함수에서 타이머를 취소
        return () => clearInterval(intervalId);
    }, []);

    const formatTimeRemaining = () => {
        const minutes = Math.floor(timeRemainingRef.current / 60);
        const seconds = timeRemainingRef.current % 60;
        return `${minutes}분 ${seconds}초`;
    };

    const menuItems = {
        관리: [{ name: "홈", icon: faHome }, { name: "배정된 작업", icon: faUserCog }, { name: "작업 내역", icon: faCogs } ]
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    }

    const handleMenuItemClick = async (menuItem) => {
        if(!tabs.includes(menuItem)){
            setTabs([...tabs, menuItem]);
        }
        setActiveTab(menuItem);
    }

    const handleTabClick = async (tabToActivate) => {
        setActiveTab(tabToActivate);  // 탭을 클릭하면 해당 탭을 활성화
    }

    const handleLogout = async () => {
        fetch(`https://${backendServer}/logout`, {
            method: 'POST',
            credentials: 'include'
        }).then(() => {
            setUserData(null);
            setIsLogout(true);  // 예: 세션이 종료되었음을 사용자에게 알리기 위한 상태를 설정
        });
    }

    const handleTabClose = (tabToRemove) => {
        // 현재 닫히는 탭의 인덱스를 찾음
        const closingTabIndex = tabs.indexOf(tabToRemove);
        let tabToActivate = null;

        // 만약 닫히는 탭이 현재 활성화된 탭이라면, 새로운 탭을 활성화
        if (tabToRemove === activeTab) {
            // 만약 닫히는 탭이 첫 번째 탭이 아니라면, 이전 탭을 활성화
            if (closingTabIndex > 0) {
                tabToActivate = tabs[closingTabIndex - 1];
            }
            // 만약 닫히는 탭이 첫 번째 탭이지만 다른 탭이 더 있다면, 다음 탭을 활성화
            else if (tabs.length > 1) {
                tabToActivate = tabs[closingTabIndex + 1];
            }
        } else {
            tabToActivate = activeTab;
        }

        // 탭 목록에서 제거
        setTabs(prevTabs => {
            const newTabs = prevTabs.filter(tab => tab !== tabToRemove);

            // 만약 마지막으로 닫힌 탭이 현재 활성화된 탭이었다면, 새로 결정한 탭을 활성화
            if(tabToRemove === activeTab) {
                setTimeout(() => {
                    setActiveTab(tabToActivate);
                }, 0);
            }else{
                setTimeout(() => {
                    setActiveTab(activeTab);
                }, 0);
            }

            return newTabs;
        });
    }

    return (
        <div className="full-size column main">
            {!userData ? (
                <>
                    <BaseLoading/>
                    {isCorrect && (
                        <BaseFullAlert msg={"올바른 접근이 아닙니다."}/>
                    )}
                    {isInit && (
                        <BaseFullAlert msg={"장시간 활동이 없어 로그아웃 되었습니다."}/>
                    )}
                    {isLogout && (
                        <BaseFullAlert msg={"성공적으로 로그아웃 되었습니다."}/>
                    )}
                </>
            ) : (
                <>
                    {isLoading && (
                        <>
                            <BaseLoading/>
                        </>
                    )}
                    <div className="full-size-width main-header row align-center">
                        <div className="grow-size row align-center-left">
                            <BaseImage aria-description="logo" src={logo} alt="Logo" width="48px" height="48px" style={{background: "#00000000"}} />
                            <div className="column">
                                <p className="extra-title white bold mobile-welcome-title" style={{margin: "0.5rem 2rem"}}>{userData.name}님 작업 관리 페이지</p>
                                <div className="row">
                                    <div className="row align-center">
                                        <p className="option text-align-left">현재 IP</p><p className="value text-align-center">{userData.current_ip}</p>
                                    </div>
                                    <div className="row align-center">
                                        <p className="option text-align-left">이전 IP</p><p className="value text-align-center">{userData.previous_ip}</p>
                                    </div>
                                    <div className="row align-center">
                                        <p className="option text-align-left">최종 접속 일시</p>
                                        <p className="value text-align-center">{format(new Date(userData.last_login_at), "yyyy.MM.dd HH:mm")}</p>
                                    </div>
                                    <div className="row align-center">
                                        <p ref={timeDisplay} className="value text-align-left" style={{color: "#fff"}}>자동 로그아웃까지 {formatTimeRemaining()}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-center menu-body">
                            {Object.entries(menuItems).map(([category, items]) => (
                                <div className="row menu-category" key={category}>
                                    {items.map(item => (
                                        <div className="row align-center menu-item" key={item.name} onClick={() => handleMenuItemClick(item.name)}>
                                            <p className="bold" style={{whiteSpace: "nowrap", color: activeTab === item.name ? 'white' : '#898989'}} key={item.name}>{item.name}</p>
                                            {activeTab === item.name && (
                                                <>
                                                    <div className={`selector full-size `}></div>
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                        <p className="medium-info white welcome-title">{userData.name}님 환영합니다.</p>
                        <div className="seperator-horz"/>
                        <FontAwesomeIcon className="menu white" icon={faSignOut} onClick={handleLogout} />
                    </div>
                    <div className="grow-size main-body column">
                        <div className="row main-form-header">
                            {tabs.map(tab => (
                                <div
                                    className={`row tab-item align-center ${activeTab === tab ? 'active' : ''}`}
                                    key={tab}
                                    onClick={() => handleTabClick(tab)}
                                >
                                    <p className="normal-info bold">{tab}</p>
                                    <FontAwesomeIcon className="tab-item-icon" icon={faTimes} onClick={() => handleTabClose(tab)} />
                                </div>
                            ))}
                        </div>
                        <div className="grow-size main-form-body align-center">
                            <GlobalContext.Provider value={{
                                timeRemainingRef,
                                userData, setUserData,
                                isInit, setIsInit
                            }}>
                                {activeTab === null}
                                {activeTab === '홈' && <TabHome />}
                                {activeTab === '작업 내역' && (
                                    <WorkHistoryContext.Provider value={{
                                        currentWorkHistories, setCurrentWorkHistories
                                    }}>
                                        <TabWorkHistory />
                                    </WorkHistoryContext.Provider>
                                )}
                                {activeTab === '배정된 작업' && (
                                        <TabAssignWork />
                                )}
                            </GlobalContext.Provider>
                        </div>
                    </div>
                    {isAlert === 0 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                        <>
                            <BaseAlert msg={alertMsg}></BaseAlert>
                        </>
                    )}
                    {isAlert === 1 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                        <>
                            <BaseAlertWarn msg={alertMsg}></BaseAlertWarn>
                        </>
                    )}
                    {isAlert === 2 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                        <>
                            <BaseAlertDanger msg={alertMsg}></BaseAlertDanger>
                        </>
                    )}
                </>
            )}
            {activeTab === '홈' && (
                <div className="column full-size-width align-center main-footer">
                    <div className="row full-size main-footer-content">
                        <div className="column align-center">
                            <BaseImage src={logo} alt="footer-logo" width={96} height={96}/>
                        </div>
                        <div className="seperator-horz"></div>
                        <div className="column full-size-width align-center">
                            <div className="row full-size-width align-center-left">
                                <a className="footer-a" href="http://kpc21.or.kr/coding/sub1/sub1.asp" target="_blank">
                                    <p className="footer-info bold">사업협동조합 소개</p>
                                </a>
                                <div className="seperator-horz mobile"></div>
                                <a className="footer-a" href="http://kpc21.or.kr/coding/sub1/sub6.asp" target="_blank">
                                    <p className="footer-info bold">오시는 길</p>
                                </a>
                                <div className="seperator-horz mobile"></div>
                                <a className="footer-a" href="http://kpc21.or.kr/coding/sub5/sub1.asp" target="_blank">
                                    <p className="footer-info bold">가입 안내</p>
                                </a>
                            </div>
                            <div className="seperator-half-bottom"/>
                            <div className="row full-size-width">
                                <FontAwesomeIcon className="footer-info-less" icon={faLocationPinLock}/>
                                <p className="footer-info-less">플랜트 건설ㆍ유지ㆍ보수 사업협동조합</p>
                            </div>
                            <div className="seperator-half-bottom"/>
                            <div className="row full-size-width">
                                <FontAwesomeIcon className="footer-info-less" icon={faBuilding}/>
                                <p className="footer-info-less">Korea Plant Cooperative All rights reserved.</p>
                            </div>
                        </div>
                        <div className="column full-size-width align-center">
                            <div className="row full-size-width align-center-right">
                                <div className="row full-size-width align-center-right">
                                    <div className="row full-size-width align-center-right">
                                        <FontAwesomeIcon className="footer-info-more" icon={faPhone}/>
                                    </div>
                                    <div className="row align-center-right">
                                        <p className="footer-info-more bold">061-691-6862</p>
                                    </div>
                                </div>
                            </div>
                            <div className="seperator-half-bottom"/>
                            <div className="row full-size-width align-center-right">
                                <div className="row full-size-width align-center-right">
                                    <div className="row full-size-width align-center-right">
                                        <FontAwesomeIcon className="footer-info-more" icon={faFax}/>
                                    </div>
                                    <div className="row align-center-right">
                                        <p className="footer-info-more bold">061-691-6863</p>
                                    </div>
                                </div>
                            </div>
                            <div className="seperator-half-bottom"/>
                            <div className="row full-size-width align-center-right">
                                <div className="row full-size-width align-center-right">
                                    <div className="row full-size-width align-center-right">
                                        <FontAwesomeIcon className="footer-info-more" icon={faMailBulk}/>
                                    </div>
                                    <div className="row align-center-right">
                                        <p className="footer-info-more bold">info@kpc21.or.kr</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default HomePage;
