import "../../styles/Technician.css"
import React, {useContext, useState} from "react";
import Main from "../Request/main";
function Request() {

    return (
        <>
            <Main/>
        </>
    );
}

export default Request;
