import "../../styles/Technician.css"
import Main from "./main_R";

function WorkHistory() {
    return (
        <>
            <Main/>
        </>
    );
}

export default WorkHistory;
