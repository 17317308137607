import React, { useContext, useEffect, useState } from 'react';
import {useNavigate} from "react-router-dom";
import BaseInput from "../BaseComponents/BaseInput";
import { BaseButton } from "../BaseComponents/BaseButton";
import {BaseImageCircle} from "../BaseComponents/BaseImage";
import logo from "../images/logo.png";
import { auth, db } from "../modules/firebase.js";
import {fetchSignInMethodsForEmail, sendSignInLinkToEmail, signInWithPhoneNumber} from "firebase/auth";
import { updateDoc, doc, serverTimestamp, getDoc } from 'firebase/firestore';
import { sha256 } from 'js-sha256';
import {BaseAlert, BaseAlertDanger, BaseAlertWarn} from "../BaseComponents/BaseAlert";
import {BaseLoading} from "../BaseComponents/BaseLoading";
import axios from 'axios';
import { UserContext } from '../App.js';
import {is} from "date-fns/locale";

function AdmEmailLoginPage() {
    const { uid, setUid } = useContext(UserContext);
    let navigate = useNavigate();
    const [values, setValues] = useState({ PHONE: '', AUTH: '' });
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [isAlert, setIsAlert] = useState(-1);
    const [alertMsg, setAlertMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    }

    const sendSMSCode = () => {
        setIsLoading(true);
        let phoneNumber = values.PHONE;
        if (phoneNumber.startsWith('010')) {
            phoneNumber = '+82' + phoneNumber.substring(1);
            const docRef = doc(db, 'verified', sha256(phoneNumber));
            getDoc(docRef).then(async (docSnap) => {
                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    if(userData.state === 'active') {
                        const email = userData.email;
                        const signInMethods = await fetchSignInMethodsForEmail(auth, email);
                        if(signInMethods.length === 0){
                            setIsLoading(false);
                            setAlertMsg("이메일 로그인을 등록하지 않은 계정입니다.");
                            if(isAlert === -1){
                                setIsAlert(2);
                                setTimeout(() => {
                                    setIsAlert(-1);
                                }, 3500)
                            }
                        }else{
                            const actionCodeSettings = {
                                url: 'http://localhost:3000/finishSignUp',
                                handleCodeInApp: true,
                            };
                            await sendSignInLinkToEmail(auth, email, actionCodeSettings);
                            window.localStorage.setItem('emailForSignIn', email);
                            setIsLoading(false);
                            setIsCodeSent(true);
                            setAlertMsg("성공적으로 전송하였습니다.");
                            if(isAlert === -1){
                                setIsAlert(0);
                                setTimeout(() => {
                                    setIsAlert(-1);
                                }, 3500)
                            }
                        }
                    } else if(userData.state === 'unactive') {
                        setIsLoading(false);
                        setAlertMsg("휴면계정 입니다.");
                        if(isAlert === -1){
                            setIsAlert(2);
                            setTimeout(() => {
                                setIsAlert(-1);
                            }, 3500)
                        }
                    } else if(userData.state === 'block') {
                        setIsLoading(false);
                        setAlertMsg("정지된 계정입니다.");
                        if(isAlert === -1){
                            setIsAlert(2);
                            setTimeout(() => {
                                setIsAlert(-1);
                            }, 3500)
                        }
                    }
                } else {
                    setIsLoading(false);
                    setAlertMsg("가입되지 않은 관리자 입니다.");
                    if(isAlert === -1){
                        setIsAlert(2);
                        setTimeout(() => {
                            setIsAlert(-1);
                        }, 3500)
                    }
                }
            });
        }else{
            setIsLoading(false);
            setAlertMsg("전화번호 양식이 올바르지 않습니다.");
            if(isAlert === -1){
                setIsAlert(1);
                setTimeout(() => {
                    setIsAlert(-1);
                }, 3500)
            }
        }
    }

    return (
        <div className="column align-center full-size login-body-theme">
            {isLoading ? (
                <BaseLoading/>
            ) : (
                <>
                    <div className="column align-center login-form">
                        <div className="row align-center full-size-width header">
                            <p className="normal-title bold">이메일 로그인</p>
                        </div>
                        {!isCodeSent && (
                            <>
                                <span className="normal-info bold text-align-left full-size-width">전화번호</span>
                                <BaseInput
                                    name="PHONE"
                                    placeholder="전화번호를 입력하세요."
                                    type="number"
                                    value={values.PHONE}
                                    onChange={handleChange}
                                />
                            </>
                        )}
                        {isCodeSent && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                            <>
                                <span className="normal-info bold text-align-center full-size-width">
                                    해당번호로 연결 된 이메일에 로그인 인증 링크를 발송했습니다.
                                    <br/>
                                    이메일을 통해 로그인을 완료해주세요.
                                </span>
                                <div className="seperator"></div>
                                <span className="normal-notice text-align-center full-size-width">
                                    해당창은 닫으셔도 괜찮습니다.
                                </span>
                            </>
                        )}

                        {!isCodeSent && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                            <>
                                <div className="seperator"></div>
                                <BaseButton onClick={sendSMSCode}>이메일 받기</BaseButton>
                            </>
                        )}
                        <div className="row align-center-left full-size-width footer">
                            <BaseImageCircle src={logo} alt="Logo" width="36px" height="36px" />
                            <p className="less-important-info">Copyright @ 2023 by SER. All rights reserved</p>
                        </div>
                    </div>
                    <div id="t"></div>
                    {isAlert === 0 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                        <>
                            <BaseAlert msg={alertMsg}></BaseAlert>
                        </>
                    )}
                    {isAlert === 1 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                        <>
                            <BaseAlertWarn msg={alertMsg}></BaseAlertWarn>
                        </>
                    )}
                    {isAlert === 2 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                        <>
                            <BaseAlertDanger msg={alertMsg}></BaseAlertDanger>
                        </>
                    )}
                </>
            )}
        </div>
    );
}

export default AdmEmailLoginPage;
