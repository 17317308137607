import React, {useState, useEffect, useContext, useRef} from "react";
import ResizeObserver from 'resize-observer-polyfill';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faWrench, faSearch, faUsers, faCogs, faCodePullRequest} from "@fortawesome/free-solid-svg-icons";
import BaseInput from "../../BaseComponents/BaseInput";
import {
    BaseButton,
    BaseButtonAwesome,
    BaseButtonAwesomeTrans,
    BaseButtonField,
    BaseButtonFieldAwesome
} from "../../BaseComponents/BaseButton";
import {GlobalContext, RequestContext} from "../../pages/AdmHomePage";
import {BaseLoading, BaseLoadingInContainer} from "../../BaseComponents/BaseLoading";
import { FixedSizeList as List } from 'react-window';
import BaseNoData from '../../BaseComponents/BaseNoData';
import {BaseAlert, BaseAlertDanger, BaseAlertWarn} from "../../BaseComponents/BaseAlert";
import RegModal from "../../modals/machine/RegModal";
import ModifyModal from "../../modals/machine/ModifyModal";
import {backendServer} from "../../App";
import RequestModal from "../../modals/machine/RequestModal";
import InfoModal from "../../modals/machine/InfoModal";
import SliderImageView from "../../modules/Slider";
const widthPercentage = ['150px', '200px','200px','150px','150px', '600px', '150px','150px','400px','150px','150px','150px','150px','150px', '50px'];

// 상수 정의
const ITEM_SIZE = 44;  // 각 항목의 높이를 조정합니다.

function Main() {
    const { currentRequests, setCurrentRequests } = useContext(RequestContext);
    const { userData, setUserData, isInit, setIsInit, timeRemainingRef } = useContext(GlobalContext);

    const [ isLoading, setIsLoading ] = useState(false);

    const [ modalType, setModalType ] = useState(-1);
    const [ isRegLoading, setIsRegLoading ] = useState(false);

    const divRef = useRef(null);
    const [listHeight, setListHeight] = useState(468);
    const [containerWidth, setContainerWidth] = useState(0);
    const containerRef = useRef(null);

    const [ isModal, setIsModal ] = useState(false);

    const [ selectedValues, setSelectedValues ] = useState({
        machine_name: '', serial_number: '', purchase_date: "", installation_date: "", location: "",
        manager_name: "", manager_contact: "", company_id: ""
    });
    const [ values, setValues ] = useState({
        machine_name: '', serial_number: '', purchase_date: "", installation_date: "", location: "",
        manager_name: "", manager_contact: ""
    });
    const [ searchValues, setSearchValues ] = useState({
        machine_id: 0, manager_name: '', manager_contact: '', location: "", machine_name: "", serial_number: "", installation_date_start : '',
        installation_date_end : '', purchase_date_start : '', purchase_date_end : '',
    });
    const [ requestValues , setRequestValues ] = useState({
        requested_company_name: "",
        requested_company_id: "",
        requested_text: "",
        machine_id: "",
    })


    const [ selectedMachine, setSelectedMachine ] = useState(null);
    const [ checkedMachine, setCheckedMachine ] = useState(null);
    const [ uploadImageValue, setUploadImageValue ] = useState({ url: '', binary: ''});
    const [ modifyImageValue, setModifyImageValue ] = useState({ url: '', binary: ''});

    const [ alertTimeout, setAlertTimeout ] = useState(null);
    const [ isAlert, setIsAlert] = useState(-1);
    const [alertMsg, setAlertMsg] = useState("");

    const [ companies, setCompanies ] = useState([]);

    const headerData = {
        check: true,
        status: "보수요청",
        machine_name : '플랜트명',
        serial_number : '시리얼 넘버',
        purchase_date : '구매일',
        installation_date : '설치일',
        location : '위치',
        manager_name : '담당자',
        manager_contact : '담당자 연락처',
        equipment_image : '플랜트 사진',
        qr_code_image : 'QR 사진',
        qr_code_location_image : 'QR 코드 위치',
        workStatus: '최근이력',
        totalHistory: '전체이력',
        modify: '수정',
    };

    const setIsAlertWithTimeout = (alertType, time = 3500) => {
        // 이미 설정된 setTimeout이 있다면 취소합니다.
        if (alertTimeout) {
            clearTimeout(alertTimeout);
        }

        setIsAlert(alertType);

        // 3.5초 후에 알림을 사라지게 만듭니다.
        const timeoutId = setTimeout(() => {
            setIsAlert(-1);
        }, time);

        // 새로 설정된 setTimeout의 ID를 저장합니다.
        setAlertTimeout(timeoutId);
    }

    const getMachines = async () => {
        setIsLoading(true);
        try {
            const requestBody = {
                company_id: userData.company_id,
                searchCriteria: searchValues  // 추가된 부분
            };

            const response = await fetch(`https://${backendServer}/get-machines`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody),
                credentials: 'include'
            });

            if (response.ok) {
                const machines = await response.json();
                setCurrentRequests(prevState => [headerData, ...machines]);
            } else {
                const errorData = await response.json();
                if(errorData.error === 'SessionExpired') {
                    setUserData(null);
                    setIsInit(true);
                } else if(errorData.error === 'NotLoggedIn') {
                    setUserData(null);
                    setIsInit(true);
                }
            }
        } catch (error) {
            setAlertMsg("서버에 연결할 수 없습니다. 관리자에게 문의해보세요.");
            setIsAlertWithTimeout(2);
        } finally {
            setIsLoading(false);
            timeRemainingRef.current = (60 * 60);
        }
    }

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setContainerWidth(entry.contentRect.width);
            }
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [containerRef]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setListHeight(entry.contentRect.height);
            }
        });

        if (divRef.current) {
            resizeObserver.observe(divRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [divRef]);

    const handleOpenModal = async (type) => {
        if(type === 2){
            setIsLoading(true);
            const response = await fetch(`https://${backendServer}/get-companies`, {
                method: 'GET',
                credentials: 'include'
            });
            setIsLoading(false);

            const data  = await response.json();
            setCompanies(data);
            setModalType(type);
            setIsModal(true);
        }else{
            setModalType(type);
            setIsModal(true);
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        let prevValue;

        if(modalType === 0){
            prevValue = values[name];
        }else if(modalType === 1){
            prevValue = selectedValues[name];
        }else if(modalType === -1){
            prevValue = searchValues[name];
        }else if(modalType === 2){
            prevValue = requestValues[name];
        }

        if (name === "manager_contact") {
            // 입력값이 줄어들었을 때는 형식화를 건너뛰기
            if (value.length < prevValue.length) {
                if(modalType === 0){
                    setValues(prevValues => ({
                        ...prevValues,
                        manager_contact: value
                    }));
                }else if(modalType === 1){
                    setSelectedValues(prevValues => ({
                        ...prevValues,
                        manager_contact: value
                    }));
                }else{
                    setSearchValues(prevValues => ({
                        ...prevValues,
                        manager_contact: value
                    }));
                }
            } else {
                if(prevValue.replaceAll(/-/g,"").length === 11) return;

                if(modalType === 0){
                    setValues(prevValues => ({
                        ...prevValues,
                        manager_contact: formatPhoneNumber(value)
                    }));
                }else if(modalType === 1){
                    setSelectedValues(prevValues => ({
                        ...prevValues,
                        manager_contact: formatPhoneNumber(value)
                    }));
                }else{
                    setSearchValues(prevValues => ({
                        ...prevValues,
                        manager_contact: formatPhoneNumber(value)
                    }));
                }
            }
        } else {
            if(modalType === 0){
                setValues(prevValues => ({
                    ...prevValues,
                    [name]: value
                }));
            }else if(modalType === 1){
                setSelectedValues(prevValues => ({
                    ...prevValues,
                    [name]: value
                }));
            }else if(modalType === -1){
                setSearchValues(prevValues => ({
                    ...prevValues,
                    [name]: value
                }));
            }else if(modalType === 2){
                setRequestValues(prevValues => ({
                    ...prevValues,
                    [name]: value
                }));
            }
        }
    }

    const formatPhoneNumber = (input) => {
        const nums = input.replace(/\D/g, "");  // 숫자만 추출

        // 휴대폰 번호 형식
        if ((nums.startsWith("010") || nums.startsWith("011") || nums.startsWith("019")) && nums.length > 3) {
            return `${nums.slice(0, 3)}-${nums.slice(3, 7)}-${nums.slice(7)}`;
        }

        return nums;  // 기본적인 형태를 유지
    };

    const handleSubmit = async () => {
        if(values.machine_name === ""){
            setAlertMsg("플랜트명을 입력해주세요.");
            setIsAlertWithTimeout(1);
        }else if(values.serial_number === ""){
            setAlertMsg("시리얼 넘버를 입력해주세요.");
            setIsAlertWithTimeout(1);
        }else if(values.purchase_date === ""){
            setAlertMsg("구매일을 작성해주세요.");
            setIsAlertWithTimeout(1);
        }else if(values.installation_date === ""){
            setAlertMsg("설치일을 작성해주세요.");
            setIsAlertWithTimeout(1);
        }else if(values.location === ""){
            setAlertMsg("플랜트 위치를 입력해주세요.");
            setIsAlertWithTimeout(1);
        }else if(values.manager_name === ""){
            setAlertMsg("담당자 이름을 입력해주세요.");
            setIsAlertWithTimeout(1);
        }else if(values.manager_contact === ""){
            setAlertMsg("담당자 연락처를 입력해주세요.");
            setIsAlertWithTimeout(1);
        }else{
            setIsRegLoading(true);

            try {
                const formData = new FormData();
                if(uploadImageValue.binary){
                    formData.append("equipment_image", uploadImageValue.binary);
                }
                formData.append("data", JSON.stringify({...values, company_id: userData.company_id})); // 기타 JSON 데이터 추가

                const response = await fetch(`https://${backendServer}/register/machines`, {
                    method: 'POST',
                    body: formData,
                    credentials: 'include',
                });

                if (response.ok) {
                    setAlertMsg("성공적으로 등록 했습니다.");
                    setIsAlertWithTimeout(0);
                    setValues({
                        machine_name: '', serial_number: '', purchase_date: "", installation_date: "", location: "",
                        manager_name: "", manager_contact: ""
                    });
                    setUploadImageValue({url: '', binary: ''})
                } else {
                    const errorData = await response.json();
                    if(errorData.error === 'SessionExpired') {
                        setUserData(null);
                        setIsInit(true);
                        throw new Error('Session has expired.'); // 이후의 처리를 중단하고 catch로 이동하기 위해 에러를 발생시킵니다.
                    }else if(errorData.error === 'NotFound'){
                        setAlertMsg("사용자를 찾을 수 없습니다.");
                        setIsAlertWithTimeout(2);
                    }
                }
                timeRemainingRef.current = (60 * 60);
            } catch (error) {
                setAlertMsg("서버에 연결할 수 없습니다. 관리자에게 문의해보세요.");
                setIsAlertWithTimeout(2);
            } finally {
                setIsRegLoading(false);
            }
        }
    }
    const handleRequest = async () => {
        if(requestValues.requested_company_id === ""){
            setAlertMsg("회사를 선택해야 합니다.");
            setIsAlertWithTimeout(1);
        }else{
            setIsRegLoading(true);

            try {
                const sendData = {...requestValues };

                const response = await fetch(`https://${backendServer}/request/maintenance`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(sendData),
                    credentials: 'include'
                });

                if (response.ok) {
                    setRequestValues({
                        requested_company_name: "",
                        requested_company_id: "",
                        requested_text: "",
                        machine_id: "",
                    });
                    setAlertMsg("등록에 성공했습니다.");
                    setIsAlertWithTimeout(0);
                    handleCloseModal();
                    getMachines();
                } else {
                    const errorData = await response.json();
                    if(errorData.error === 'SessionExpired') {
                        setUserData(null);
                        setIsInit(true);
                        throw new Error('Session has expired.'); // 이후의 처리를 중단하고 catch로 이동하기 위해 에러를 발생시킵니다.
                    }else if(errorData.error === 'DuplicateEntry'){
                        setAlertMsg("아이디가 중복되거나 이미 등록된 작업자 입니다.");
                        setIsAlertWithTimeout(2);
                    }else if(errorData.error === 'FailedSMSSended'){
                        setRequestValues({
                            requested_company_name: "",
                            requested_company_id: "",
                            requested_text: "",
                            machine_id: "",
                        });
                        handleCloseModal();
                        getMachines();
                        setAlertMsg("요청은 성공헀으나, 문자 발송에 실패했습니다. [수동 연락 요망]");
                        setIsAlertWithTimeout(2, 7000);
                    }else if(errorData.error === 'NotLoggedIn'){
                        setUserData(null);
                        setIsInit(true);
                    }
                    timeRemainingRef.current = (60 * 60);
                }
            } catch (error) {
                console.error(error);
                setAlertMsg("서버에 연결할 수 없습니다. 관리자에게 문의해보세요.");
                setIsAlertWithTimeout(2);
            } finally {
                setIsRegLoading(false);
            }
        }
    }
    const handleModify = async () => {
        if(selectedValues.machine_name === ""){
            setAlertMsg("플랜트명을 입력해주세요.");
            setIsAlertWithTimeout(1);
        }else if(selectedValues.serial_number === ""){
            setAlertMsg("시리얼 넘버를 입력해주세요.");
            setIsAlertWithTimeout(1);
        }else if(selectedValues.purchase_date === ""){
            setAlertMsg("구매일을 작성해주세요.");
            setIsAlertWithTimeout(1);
        }else if(selectedValues.installation_date === ""){
            setAlertMsg("설치일을 작성해주세요.");
            setIsAlertWithTimeout(1);
        }else if(selectedValues.location === ""){
            setAlertMsg("플랜트 위치를 입력해주세요.");
            setIsAlertWithTimeout(1);
        }else if(selectedValues.manager_name === ""){
            setAlertMsg("담당자 이름을 입력해주세요.");
            setIsAlertWithTimeout(1);
        }else if(selectedValues.manager_contact === ""){
            setAlertMsg("담당자 연락처를 입력해주세요.");
            setIsAlertWithTimeout(1);
        }else{
            setIsRegLoading(true);

            try {
                const formData = new FormData();
                if(modifyImageValue.binary){
                    formData.append("equipment_image", modifyImageValue.binary);
                }
                formData.append("data", JSON.stringify({...selectedValues })); // 기타 JSON 데이터 추가

                const response = await fetch(`https://${backendServer}/modify/machines`, {
                    method: 'POST',
                    body: formData,
                    credentials: 'include',
                });

                if (response.ok) {
                    setAlertMsg("수정을 완료했습니다.");
                    setIsAlertWithTimeout(0);
                    handleCloseModal();
                    getMachines();
                    timeRemainingRef.current = (60 * 60);
                } else {
                    const errorData = await response.json();
                    if(errorData.error === 'SessionExpired') {
                        setUserData(null);
                        setIsInit(true);
                        throw new Error('Session has expired.'); // 이후의 처리를 중단하고 catch로 이동하기 위해 에러를 발생시킵니다.
                    }else if(errorData.error === 'NotFound'){
                        setAlertMsg("사용자를 찾을 수 없습니다.");
                        setIsAlertWithTimeout(2);
                    }else if(errorData.error === 'NotLoggedIn'){
                        setUserData(null);
                        setIsInit(true);
                    }
                }
            } catch (error) {
                setAlertMsg("서버에 연결할 수 없습니다. 관리자에게 문의해보세요.");
                setIsAlertWithTimeout(2);
            } finally {
                setIsRegLoading(false);
            }
        }
    }

    const handleCloseModal = () => {
        setModalType(-1);
        setIsModal(false);
    }

    const handleClick = ()=> {
        let compare;
        if(modalType === 0){
            compare = values.location;
        }else if(modalType === 1){
            compare = selectedValues.location;
        }else{
            compare = searchValues.location;
        }

        if(compare === "") {
            new window.daum.Postcode({
                oncomplete: function(data) {
                    const fullAddress = `${data.address}`;
                    if(modalType === 0){
                        setValues(prevValues => ({
                            ...prevValues,
                            location: fullAddress
                        }));
                    }else if(modalType === 1){
                        setSelectedValues(prevValues => ({
                            ...prevValues,
                            location: fullAddress
                        }));
                    }else{
                        setSearchValues(prevValues => ({
                            ...prevValues,
                            location: fullAddress
                        }));
                    }
                }
            }).open();
        }else{

        }
    }

    function formatDate(isoString) {
        return isoString.split('T')[0];
    }

    const handleImageUpload = (imageData, binaryData) => {
        // 여기서 imageData를 state에 저장하거나 다른 작업을 수행합니다.
        if(modalType === 0){
            setUploadImageValue({url: imageData.split(",")[1], binary: binaryData});
        }else if(modalType === 1){
            setModifyImageValue({url: imageData.split(",")[1], binary: binaryData})
        }
    }

    const openImage = (data) => {
        if (data[0]) {
            const imageHTML = new SliderImageView(data);
            const imageWindow = window.open();
            imageWindow.document.write(imageHTML.getHTML());
        } else {
            setAlertMsg("저장된 이미지가 없습니다.");
            setIsAlertWithTimeout(1);
        }
    }

    function getStatusClass(status) {
        switch (status) {
            case 'wait':
                return 'status-wait';
            case 'pending':
                return 'status-pending';
            case 'accepted':
                return 'status-accepted';
            default:
                return '';
        }
    }

    function getStatusText(status) {
        switch (status) {
            case 'wait': return '요청하기';
            case 'pending': return '응답대기';
            case 'accepted': return '작업 진행중';
            default: return '';
        }
    }

    function TableRow({ index, style, data }) {
        const machine = data[index];

        const rowStyle = {
            ...style,
            top: ITEM_SIZE * index - 16,
        };

        const headerStyle = {
            height: '28px'
        };

        const bodyStyle = {
            backgroundColor: index % 2 === 1 ? '#e8e8e8' : 'transparent',
        }

        if (containerWidth < 1210) {
            headerStyle.width = '1200px';
            bodyStyle.width = '1200px';
        }

        const getStyle = (widthPercentage, isFirst) => {
            if (containerWidth < 1210){
                if(isFirst){
                    return { width: widthPercentage,
                        backgroundColor: index % 2 === 1 ? '#e8e8e8' : '#FFFFFF',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }else{
                    return { width: widthPercentage,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }
            }else{
                if(isFirst){
                    return { flex: widthPercentage,
                        backgroundColor: index % 2 === 1 ? '#e8e8e8' : '#FFFFFF',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }else{
                    return { flex: widthPercentage,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }
            }
        }

        const handleCheckChange = (machineIndex) => {
            // 현재 machines 상태의 복사본을 만듭니다.
            const newMachines = [...currentRequests];

            // 체크 상태를 토글합니다.
            newMachines[machineIndex] = {
                ...newMachines[machineIndex],
                check: !newMachines[machineIndex].check
            };

            // 상태를 업데이트합니다.
            setCurrentRequests(newMachines);
        };

        const handleToggleAllChecks = () => {
            // 첫 번째 machine을 제외하고, 'wait' 상태인 machine이 모두 체크되어 있는지 확인합니다.
            const allChecked = currentRequests.slice(1).every(machine => machine.check);

            // 체크 상태를 반대로 설정합니다 (전체 선택 또는 전체 해제).
            // 'wait' 상태가 아닌 machine은 변경하지 않습니다.
            const newMachines = currentRequests.map((machine, index) => {
                if (index === 0) return machine; // 첫 번째 machine이나 'wait' 상태가 아닌 machine은 변경하지 않습니다.
                return { ...machine, check: !allChecked };
            });

            // 상태를 업데이트합니다.
            setCurrentRequests(newMachines);
        };

        return (
            <>
                {index === 0 ? (
                    <>
                        <div className="row full-size-width table-header" style={headerStyle}>
                            <div className="row first-child align-center" style={{width: "400px", margin: 0, padding: 0}}>
                                <div className="column align-center" style={getStyle(widthPercentage[14])} onClick={handleToggleAllChecks}>
                                    <input
                                        type="checkbox"
                                        checked={
                                            currentRequests.slice(1).every(machine => machine.check)
                                        }
                                        onChange={handleToggleAllChecks}
                                        className="text-align-center"
                                    />
                                </div>
                                <div className="column" style={getStyle(widthPercentage[0])}>
                                    <p className="text-align-center">{machine.status}</p>
                                </div>
                                <div className="column" style={getStyle(widthPercentage[1])}>
                                    <p className="text-align-center">{machine.machine_name}</p>
                                </div>
                            </div>
                            <div className="column align-center" style={getStyle(widthPercentage[2])}>
                                <p className="text-align-center">{machine.serial_number}</p>
                            </div>
                            <div className="column align-center" style={getStyle(widthPercentage[5])}>
                                <p className="text-align-center">{machine.workStatus}</p>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div style={rowStyle}>
                            <div className="row full-size-width table-item" style={bodyStyle}>
                                <div className="row first-column" style={{width: "400px", margin: 0, padding: 0}}>
                                    <div className="column align-center" style={getStyle(widthPercentage[14], true)}>
                                        <input
                                            type="checkbox"
                                            checked={machine.check}
                                            className="text-align-center"
                                            onChange={() => handleCheckChange(index)}
                                        />
                                    </div>
                                    <div name="equipment-image" className="column align-center" style={getStyle(widthPercentage[0], true)}>
                                        <BaseButtonField
                                            onClick={() => {
                                                setRequestValues({
                                                    requested_company_name: "",
                                                    requested_company_id: "",
                                                    requested_text: "",
                                                    machine_id: machine.machine_id,
                                                    machine_name: machine.machine_name,
                                                });
                                                handleOpenModal(2);
                                            }}>
                                            요청하기
                                        </BaseButtonField>
                                    </div>
                                    <div className="column align-center first-column" style={getStyle(widthPercentage[1], true)}>
                                        <p>{machine.machine_name}</p>
                                    </div>
                                </div>
                                <div className="column align-center" style={getStyle(widthPercentage[2])}>
                                    <p>{machine.serial_number}</p>
                                </div>
                                <div className="column align-center" style={getStyle(widthPercentage[5])}>
                                    <p>{machine.workStatus}</p>
                                </div>
                            </div>
                        </div>
                    </>
                )}

            </>
        );
    }

    const showTotalHistory = async (machine_id, machine_name) => {
        const historyHeader = {
            status: "상태",
            user_name: "작업자",
            user_phone: "연락처",
            company_name: "작업 회사",
            company_phone: "회사 번호",
            machine_name: "작업 플랜트",
            start_time: "시작 시간",
            end_time: "종료 시간",
            maintenance_details: "유지보수 내용",
            special_notes: "특이사항",
            images: "작업사진"
        }
        setIsLoading(true);
        try{
            const response = await fetch(`https://${backendServer}/get-histories/machine?machine_id=${machine_id}`, {
                method: 'GET',
                credentials: 'include'
            });
            if (response.ok) {
                const data  = await response.json();
            } else {
                const errorData = await response.json();
                if(errorData.error === 'SessionExpired') {
                    setUserData(null);
                    setIsInit(true);
                    throw new Error('Session has expired.'); // 이후의 처리를 중단하고 catch로 이동하기 위해 에러를 발생시킵니다.
                }else if(errorData.error === 'NotFound'){
                    setAlertMsg("사용자를 찾을 수 없습니다.");
                    setIsAlertWithTimeout(2);
                }else if(errorData.error === 'NotLoggedIn'){
                    setUserData(null);
                    setIsInit(true);
                }
            }
        }catch (e){
            setAlertMsg("서버에 연결할 수 없습니다. 관리자에게 문의해보세요.");
            setIsAlertWithTimeout(2);
        }finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="full-size column align-center">
            <div className="seperator-half"></div>
            <div className="row align-center-left main-table-header full-size-width">
                <FontAwesomeIcon className="menu" icon={faCodePullRequest}/>
                <div className="seperator-horz"></div>
                <p className="medium-title bold">유지보수요청</p>
            </div>
            <div className="row full-size-width">
                <div className="column grow-size search-info-container">
                    <p className="normal-info bold text-align-left full-size-width">검색 기준</p>
                    <div className="row full-size-width">
                        <BaseInput
                            name="machine_name"
                            onChange={handleChange}
                            value={searchValues.machine_name}
                            aria-description="condition"
                            placeholder="플랜트명"
                        />
                        <div className="seperator-horz"></div>
                        <BaseInput
                            name="serial_number"
                            onChange={handleChange}
                            value={searchValues.serial_number}
                            aria-description="condition"
                            placeholder="시리얼넘버"
                        />
                    </div>
                </div>
                <div className="column align-center buttons">
                    <BaseButtonAwesome onClick={getMachines} icon={faSearch}/>
                </div>
            </div>
            <div className="column grow-size full-size-width">
                <div className="row full-size-width">
                    <div className="column full-size-width align-left-center" style={{paddingLeft: "1.5rem", paddingBottom: "1rem"}}>
                        <div className="buttons">
                            <BaseButtonField
                                onClick={() => {
                                    const machineIds = currentRequests.slice(1)
                                        .filter(machine => machine.check)
                                        .map(machine => machine.machine_id);

                                    const machineNames = currentRequests.slice(1)
                                        .filter(machine => machine.check)
                                        .map(machine => machine.machine_name);

                                    if(machineIds.length === 0 ) {
                                        setAlertMsg("선택된 플랜트가 없습니다.");
                                        setIsAlertWithTimeout(1);
                                    }else {
                                        setRequestValues({
                                            requested_company_name: "",
                                            requested_company_id: "",
                                            requested_text: "",
                                            machine_id: "",
                                            machine_ids: machineIds,
                                            machine_names: machineNames,
                                        });
                                        handleOpenModal(2);
                                    }
                                }}
                                className="column align-center" >
                                <p style={{margin: "0 0.5rem"}}>일괄 요청</p>
                            </BaseButtonField>
                        </div>
                    </div>
                </div>
                <div className="column main-table-body" ref={containerRef}>
                    {isLoading && (
                        <>
                            <BaseLoading/>
                        </>
                    )}
                    <div className="table-container-h1" ref={divRef}>
                        <>
                            {currentRequests.length === 0 ? (
                                <>
                                    <BaseNoData/>
                                </>
                            ) : (
                                <>
                                    <div className="row table-body-container" >
                                        <List
                                            width={containerWidth}
                                            height={listHeight}
                                            className="table full-size-width"
                                            itemCount={currentRequests.length}
                                            itemSize={ITEM_SIZE}
                                            itemData={currentRequests}
                                        >
                                            {TableRow}
                                        </List>
                                    </div>
                                </>
                            )}
                        </>
                    </div>
                </div>
            </div>
            <div className="seperator"></div>
            { isModal && modalType === 0 && (
                <>
                    <div className="modal full-size column align-center">
                        <div className="register-form">
                            {isRegLoading ? (
                                <>
                                    <BaseLoadingInContainer/>
                                </>
                            ):(
                                <>
                                    <RegModal
                                        values={values}

                                        imageValue={uploadImageValue}
                                        closeClick={handleCloseModal}
                                        handleChange={handleChange}
                                        handleSubmit={handleSubmit}
                                        handleClick={handleClick}
                                        onImageUpload={handleImageUpload}
                                        deleteImage={() => {
                                            setUploadImageValue({url: '', binary: ''});
                                        }}
                                    />
                                </>
                            )}

                        </div>
                    </div>
                </>
            )}
            { isModal && modalType === 1 && (
                <>
                    <div className="modal full-size column align-center">
                        <div className="register-form">
                            {isRegLoading ? (
                                <>
                                    <BaseLoadingInContainer/>
                                </>
                            ):(
                                <>
                                    <ModifyModal
                                        values={selectedValues}
                                        imageValue={modifyImageValue}
                                        closeClick={handleCloseModal}
                                        handleChange={handleChange}
                                        handleModify={handleModify}
                                        handleClick={handleClick}
                                        onImageUpload={handleImageUpload}
                                        deleteImage={() => {
                                            setModifyImageValue({url: '', binary: ''});
                                        }}
                                    />
                                </>
                            )}

                        </div>
                    </div>
                </>
            )}
            { isModal && modalType === 2 && (
                <>
                    <div className="modal full-size column align-center">
                        <div className="register-form">
                            {isRegLoading ? (
                                <>
                                    <BaseLoadingInContainer/>
                                </>
                            ):(
                                <>
                                    <RequestModal
                                        values={requestValues}
                                        handleSuggestionClick={(company) => {
                                            if(company){
                                                setRequestValues(prevState => {
                                                    return {...prevState, requested_company_id: company.company_id};
                                                })
                                            }else{
                                                setRequestValues(prevState => {
                                                    return {...prevState, requested_company_name: '', requested_company_id: ''};
                                                })
                                            }
                                        }}
                                        companies={companies}
                                        closeClick={handleCloseModal}
                                        handleChange={handleChange}
                                        handleSubmit={handleRequest}
                                    />
                                </>
                            )}

                        </div>
                    </div>
                </>
            )}
            { isModal && modalType === 3 && (
                <>
                    <div className="modal full-size column align-center">
                        <div className="register-form">
                            {isRegLoading ? (
                                <>
                                    <BaseLoadingInContainer/>
                                </>
                            ):(
                                <>
                                    <InfoModal
                                        selectedMachine = {selectedMachine}
                                        closeClick={() => {
                                            handleCloseModal()
                                            setSelectedMachine(null)
                                        }}
                                    />
                                </>
                            )}

                        </div>
                    </div>
                </>
            )}
            {isAlert === 0 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                <>
                    <BaseAlert msg={alertMsg}></BaseAlert>
                </>
            )}
            {isAlert === 1 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                <>
                    <BaseAlertWarn msg={alertMsg}></BaseAlertWarn>
                </>
            )}
            {isAlert === 2 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                <>
                    <BaseAlertDanger msg={alertMsg}></BaseAlertDanger>
                </>
            )}
        </div>
    );
}

export default Main;
