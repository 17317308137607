import {useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import BaseInput from "../BaseComponents/BaseInput";
import { BaseButton } from "../BaseComponents/BaseButton";
import {BaseFixedImage, BaseImageCircle} from "../BaseComponents/BaseImage";
import {BaseAlert, BaseAlertWarn, BaseAlertDanger, BaseFullAlert} from "../BaseComponents/BaseAlert";
import logo from "../images/logo.png";
import {backendServer, WorkContext} from "../App";
import {BaseLoading} from "../BaseComponents/BaseLoading";

function LoginPage() {
    const { machineID,setMachineID, machineName,setMachineName } = useContext(WorkContext);
    let navigate = useNavigate();
    const [ isLoading, setIsLoading ] = useState(true);

    const [values, setValues] = useState({ ID: '', PWD: '' });

    const [ alertTimeout, setAlertTimeout ] = useState(null);
    const [isAlert, setIsAlert] = useState(-1);
    const [alertMsg, setAlertMsg] = useState("");
    const setIsAlertWithTimeout = (alertType, timeOut = 3500) => {
        // 이미 설정된 setTimeout이 있다면 취소합니다.
        if (alertTimeout) {
            clearTimeout(alertTimeout);
        }

        setIsAlert(alertType);

        // 3.5초 후에 알림을 사라지게 만듭니다.
        const timeoutId = setTimeout(() => {
            setIsAlert(-1);
        }, timeOut);

        // 새로 설정된 setTimeout의 ID를 저장합니다.
        setAlertTimeout(timeoutId);
    }

    const [ isNotQueue, setIsNotQueue ] = useState(false);
    const [ isNotMachine, setIsNotMachine ] = useState(false);
    const [ isNotServer, setIsNotServer ] = useState(false);

    const [ temp, setTemp ] = useState("");
    useEffect(() => {
        checkWorking();
    }, [])

    const checkWorking = async () => {
        setIsLoading(true);

        const urlParams = new URLSearchParams(window.location.search);
        const machine_uid = urlParams.get('uid');

        try {
            const requestBody = {
                machine_uid: machine_uid,
            };

            const response = await fetch(`https://${backendServer}/check-machine`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody),
                credentials: 'include'
            });

            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.error === 'NotFoundQueue') {
                    setIsNotQueue(true);
                } else if(errorData.error === 'NotFoundMachine') {
                    setIsNotMachine(true);
                }
            } else {
                const data = await response.json();
                setMachineID(data.machine_id);
                setMachineName(data.machine_name);
                if(response.status === 202){
                    setIsLoading(false);
                    navigate("/work/report");
                }else{
                    setIsLoading(false);
                }
            }
        } catch (error) {
            alert(error);
            setTemp(error);
            setIsNotServer(true);
        }
    }
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    }

    const handleBack = () => {
        window.close(); // 사이트를 닫습니다.
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        if(values.ID === ""){
            if(isAlert === -1){
                setAlertMsg("아이디를 입력해주세요.");
                setIsAlertWithTimeout(1);
            }
        }else if(values.PWD === ""){
            if(isAlert === -1){
                setAlertMsg("비밀번호를 입력해주세요.");
                setIsAlertWithTimeout(1);
            }
        }else{
            try {
                const response = await fetch(`https://${backendServer}/qr/login/technician`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({...values}),
                    credentials: 'include'
                });

                if (response.ok) {
                    navigate("/work/report");
                } else {
                    const errorData = await response.json();
                    if(errorData.error === "NotFoundID"){
                        setAlertMsg("존재하지 않는 아이디 입니다.");
                        setIsAlertWithTimeout(2);
                    } else if(errorData.error === "NotMatchedPWD"){
                        setAlertMsg("비밀번호가 올바르지 않습니다.");
                        setIsAlertWithTimeout(2);
                    } else if(errorData.error === "NotQRScan"){
                        setAlertMsg("비정상적인 접근 입니다.");
                        setIsAlertWithTimeout(2);
                    } else if(errorData.error === 'UserIDMismatch') {
                        setAlertMsg("배정된 작업자가 아닙니다.");
                        setIsAlertWithTimeout(2);
                    } else {
                        setAlertMsg(errorData.message);
                        setIsAlertWithTimeout(2);
                    }
                }
            } catch (error) {
                setAlertMsg("서버에 연결 할 수 없습니다. 관리자에게 문의하세요.");
                setIsAlertWithTimeout(2);
            }
        }
        setIsLoading(false);
    }

    return (
        <>
            {isLoading ? (
                <>
                    <BaseLoading/>
                    {isNotQueue && (
                        <BaseFullAlert
                            handleBack={handleBack}
                            msg={"예약된 작업이 없습니다."}
                        />
                    )}
                    {isNotMachine && (
                        <BaseFullAlert
                            handleBack={handleBack}
                            msg={"올바르지 않은 QR 입니다."}
                        />
                    )}
                    {isNotServer && (
                        <BaseFullAlert
                            handleBack={handleBack}
                            msg={JSON.stringify({...temp, name: temp.name, message: temp.message}, null, 2)}
                        />
                    )}
                </>
            ) : (
                <>
                    <div className="column align-center full-size login-body-theme">
                        <div className="column align-center login-form">
                            <div className="row align-center full-size-width header">
                                <p className="normal-title bold">작업 시작</p>
                            </div>
                            <span className="normal-info bold text-align-left full-size-width">아이디</span>
                            <BaseInput
                                name="ID"
                                placeholder="아이디를 입력해주세요."
                                value={values.ID}
                                onChange={handleChange}
                            />
                            <span className="normal-info bold text-align-left full-size-width">비밀번호</span>
                            <BaseInput
                                name="PWD"
                                placeholder="비밀번호를 입력해주세요."
                                value={values.PWD}
                                type="password"
                                onChange={handleChange}
                            />
                            <div className="seperator-bottom"/>
                            <BaseButton onClick={handleSubmit}>로그인</BaseButton>
                            <div className="row align-center-left full-size-width footer">
                                <BaseFixedImage src={logo} alt="Logo" width="36px" height="36px" />
                                <p className="less-important-info">Copyright @ 2023 by KCP. All rights reserved</p>
                            </div>
                        </div>
                        {isAlert === 0 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                            <>
                                <BaseAlert msg={alertMsg}></BaseAlert>
                            </>
                        )}
                        {isAlert === 1 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                            <>
                                <BaseAlertWarn msg={alertMsg}></BaseAlertWarn>
                            </>
                        )}
                        {isAlert === 2 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                            <>
                                <BaseAlertDanger msg={alertMsg}></BaseAlertDanger>
                            </>
                        )}
                    </div>
                </>
            )}
        </>

    );
}

export default LoginPage;
