import React, { useContext, useEffect, useState } from 'react';
import {useNavigate} from "react-router-dom";
import BaseInput from "../BaseComponents/BaseInput";
import { BaseButton }  from "../BaseComponents/BaseButton";
import {BaseFixedImage, BaseImage, BaseImageCircle} from "../BaseComponents/BaseImage";
import logo from "../images/logo.png";

import {BaseAlert, BaseAlertDanger, BaseAlertWarn} from "../BaseComponents/BaseAlert";
import {BaseLoading} from "../BaseComponents/BaseLoading";
import {backendServer, UserContext} from '../App.js';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuilding, faFax, faLocationPinLock, faMailBulk, faPhone} from "@fortawesome/free-solid-svg-icons";

function AdmLoginPage() {
    let navigate = useNavigate();
    const [values, setValues] = useState({ ID: '', PWD: '' });

    const [ alertTimeout, setAlertTimeout ] = useState(null);
    const [ isAlert, setIsAlert] = useState(-1);
    const [alertMsg, setAlertMsg] = useState("");
    const setIsAlertWithTimeout = (alertType, timeOut = 3500) => {
        // 이미 설정된 setTimeout이 있다면 취소합니다.
        if (alertTimeout) {
            clearTimeout(alertTimeout);
        }

        setIsAlert(alertType);

        // 3.5초 후에 알림을 사라지게 만듭니다.
        const timeoutId = setTimeout(() => {
            setIsAlert(-1);
        }, timeOut);

        // 새로 설정된 setTimeout의 ID를 저장합니다.
        setAlertTimeout(timeoutId);
    }

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkSession = async () => {
            try {
                const response = await fetch(`https://${backendServer}/check-session`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include'
                });

                if (response.ok) {
                    const data = await response.json();

                    // 예를 들어, 서버에서 'loggedIn' 속성을 true로 응답했다면 홈페이지로 리디렉션
                    if (data.loggedIn) {
                        navigate("/adm/home");
                    }else{
                        setIsLoading(false);
                    }
                }
            } catch (error) {
                console.error("Error checking session:", error);
            }
        };

        checkSession();
    }, []);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        if(values.ID === ""){
            if(isAlert === -1){
                setAlertMsg("아이디를 입력해주세요.");
                setIsAlertWithTimeout(1);
            }
        }else if(values.PWD === ""){
            if(isAlert === -1){
                setAlertMsg("비밀번호를 입력해주세요.");
                setIsAlertWithTimeout(1);
            }
        }else{
            // 로그인 요청
            try {
                const response = await fetch(`https://${backendServer}/login`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(values),
                    credentials: 'include'
                });

                if (response.ok) {
                    navigate("/adm/home"); // 메인 페이지로 이동
                } else {
                    const responseData = await response.json();
                    setAlertMsg(`${responseData.message}`);
                    setIsAlertWithTimeout(2);
                }
            } catch (error) {
                setAlertMsg("서버에 연결할 수 없습니다. 관리자에게 문의해보세요.");
                setIsAlertWithTimeout(2);
            }
        }
        setIsLoading(false);
    }

    return (
        <div className="column full-size align-center login-body-theme">
            <div className="column full-size-width align-center main-header-header">
                <div className="row full-size align-center main-header-content">
                    <p className="bold" style={{fontSize: 28}}>
                        IoT 기반 플랜트 이력 관리 시스템
                    </p>
                </div>
            </div>
            <div className="row align-center full-size">
                <div className="column align-center login-form">
                    <div className="row align-center full-size-width header">
                        <div className="full-size-width align-center" style={{borderBottom: "2px solid #00000050", cursor: "pointer"}}>
                            <p className="text-align-center bold" style={{fontSize: 20}}>관리자</p>
                        </div>
                        <div className="full-size-width align-center" style={{cursor: "pointer"}}  onClick={() => {
                            navigate("/regular");
                        }}>
                            <p className="text-align-center" style={{fontSize: 20}}>유지보수 작업자</p>
                        </div>
                    </div>
                    <span className="normal-info bold text-align-left full-size-width">아이디</span>
                    <BaseInput
                        name="ID"
                        placeholder="아이디를 입력해주세요."
                        value={values.PHONE}
                        onChange={handleChange}
                    />
                    <span className="normal-info bold text-align-left full-size-width">비밀번호</span>
                    <BaseInput
                        name="PWD"
                        placeholder="비밀번호를 입력해주세요."
                        value={values.PHONE}
                        type="password"
                        onChange={handleChange}
                    />
                    <div className="seperator-half-bottom"/>
                    <div className="seperator-bottom"/>
                    <BaseButton onClick={handleSubmit}>로그인</BaseButton>
                    <div className="row align-center-left full-size-width footer">
                        <BaseFixedImage src={logo} alt="Logo" width="36px" height="36px" />
                        <p className="less-important-info">Copyright @ 2023 by KCP. All rights reserved</p>
                    </div>
                </div>
                <div className="full-size login-body-theme-sideimg">

                </div>
            </div>
            <div className="column full-size-width align-center main-footer">
                <div className="row full-size main-footer-content">
                    <div className="column align-center">
                        <BaseImage src={logo} alt="footer-logo" width={96} height={96}/>
                    </div>
                    <div className="seperator-horz"></div>
                    <div className="column full-size-width align-center">
                        <div className="row full-size-width align-center-left">
                            <a className="footer-a" href="http://kpc21.or.kr/coding/sub1/sub1.asp" target="_blank">
                                <p className="footer-info bold">사업협동조합 소개</p>
                            </a>
                            <div className="seperator-horz mobile"></div>
                            <a className="footer-a" href="http://kpc21.or.kr/coding/sub1/sub6.asp" target="_blank">
                                <p className="footer-info bold">오시는 길</p>
                            </a>
                            <div className="seperator-horz mobile"></div>
                            <a className="footer-a" href="http://kpc21.or.kr/coding/sub5/sub1.asp" target="_blank">
                                <p className="footer-info bold">가입 안내</p>
                            </a>
                        </div>
                        <div className="seperator-half-bottom"/>
                        <div className="row full-size-width">
                            <FontAwesomeIcon className="footer-info-less" icon={faLocationPinLock}/>
                            <p className="footer-info-less">플랜트 건설ㆍ유지ㆍ보수 사업협동조합</p>
                        </div>
                        <div className="seperator-half-bottom"/>
                        <div className="row full-size-width">
                            <FontAwesomeIcon className="footer-info-less" icon={faBuilding}/>
                            <p className="footer-info-less">Korea Plant Cooperative All rights reserved.</p>
                        </div>
                    </div>
                    <div className="column full-size-width align-center">
                        <div className="row full-size-width align-center-right">
                            <div className="row full-size-width align-center-right">
                                <div className="row full-size-width align-center-right">
                                    <FontAwesomeIcon className="footer-info-more" icon={faPhone}/>
                                </div>
                                <div className="row align-center-right">
                                    <p className="footer-info-more bold">070-5088-1922</p>
                                </div>
                            </div>
                        </div>
                        <div className="seperator-half-bottom"/>
                        <div className="row full-size-width align-center-right">
                            <div className="row full-size-width align-center-right">
                                <div className="row full-size-width align-center-right">
                                    <FontAwesomeIcon className="footer-info-more" icon={faFax}/>
                                </div>
                                <div className="row align-center-right">
                                    <p className="footer-info-more bold">061-691-6863</p>
                                </div>
                            </div>
                        </div>
                        <div className="seperator-half-bottom"/>
                        <div className="row full-size-width align-center-right">
                            <div className="row full-size-width align-center-right">
                                <div className="row full-size-width align-center-right">
                                    <FontAwesomeIcon className="footer-info-more" icon={faMailBulk}/>
                                </div>
                                <div className="row align-center-right">
                                    <p className="footer-info-more bold">info@kpc21.or.kr</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isAlert === 0 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                <>
                    <BaseAlert msg={alertMsg}></BaseAlert>
                </>
            )}
            {isAlert === 1 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                <>
                    <BaseAlertWarn msg={alertMsg}></BaseAlertWarn>
                </>
            )}
            {isAlert === 2 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                <>
                    <BaseAlertDanger msg={alertMsg}></BaseAlertDanger>
                </>
            )}
        </div>
    );
}

export default AdmLoginPage;
