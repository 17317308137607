import {faHome, faCrown, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useContext } from "react";
import {GlobalContext} from '../pages/HomePage.js';
import {BaseLoadingInContainer} from "../BaseComponents/BaseLoading";
import {tr} from "date-fns/locale";
import {backendServer} from "../App";


function HOMERegular() {
    const { timeRemainingRef,
            userData, setUserData,
            isInit, setIsInit } = useContext(GlobalContext);

    const [ isLoading, setIsLoading ] = useState(true);
    const [notices, setNotices] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [order, setOrder] = useState('desc');
    const [activeNotice, setActiveNotice] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const noticesPerPage = 10;

    const lastNoticeIndex = currentPage * noticesPerPage;
    const firstNoticeIndex = lastNoticeIndex - noticesPerPage;

    const pageCount = Math.ceil(totalCount / noticesPerPage);
    const dataPage = Math.ceil(currentPage / 10);
    const startIdx = (currentPage - 1) % 10 * noticesPerPage;  // 0, 10, 20, ...
    const endIdx = startIdx + noticesPerPage;
    const currentNotices = notices.slice(startIdx, endIdx);

    let startPage = Math.floor((currentPage - 1) / 10) * 10 + 1;
    let endPage = Math.min(startPage + 9, pageCount);

    useEffect(() => {
        setIsLoading(true);
        // 초기 마운트 시 공지사항을 가져옵니다. 기본은 내림차순입니다.
        fetch(`https://${backendServer}/get-notices?order=${order}&page=${dataPage}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(res => {
                if(res.ok){
                    const data = res.json();
                    return data;
                }else{
                    const errorData = res.json();
                    if(errorData.error === "SessionExpired" || errorData.error === "NotLoggedIn") {
                        setUserData(null);
                        setIsInit(true);
                        throw new Error('Session has expired.'); // 이후의 처리를 중단하고 catch로 이동하기 위해 에러를 발생시킵니다.
                    }
                }
            })
            .then(data => {
                setIsLoading(false);
                setTotalCount(data.total);
                setNotices(data.notices);

                timeRemainingRef.current = (60 * 60);
            })
            .catch(error => console.error("Logout!!"));
    }, [order, dataPage]);

    const handlePageClick = (event, value) => {
        setCurrentPage(value);
    };

    function formatDate(isoString) {
        return isoString.split('T')[0];
    }

    const toggleOrder = () => {
        // 현재의 정렬 방식을 반대로 바꿈
        setOrder(prevOrder => {
            const nextOrder = prevOrder === 'desc' ? 'asc' : 'desc';
            return nextOrder;
        });
    }

    return (
        <div className="full-size column align-center">
            <div className="row align-center-left main-table-header full-size-width">
                <FontAwesomeIcon className="menu" icon={faHome} onClick={() => setActiveNotice(null)}/>
                <div className="seperator-horz"></div>
                <p className="medium-title bold">홈</p>
                {activeNotice &&(
                    <>
                        <p className="medium-info bold">/</p>
                        <p className="medium-info bold">{activeNotice.title}</p>
                    </>
                )}
            </div>
            <div className="column full-size">
                <div className="column main-table-body">
                    {!activeNotice ? (
                        <div className="table-container full-size">
                            {isLoading ? (
                                <BaseLoadingInContainer/>
                            ) : (
                                <table className="notice-table">
                                    <thead>
                                    <tr>
                                        <th>작성자</th>
                                        <th>글제목</th>
                                        <th onClick={toggleOrder} style={{ cursor: 'pointer' }}>
                                            작성일 {order === 'desc' ? '▼' : '▲'}  {/* 정렬 방식에 따라 아이콘 표시 */}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {currentNotices.map((notice) => (
                                            <tr className="notice-item" key={notice.id} onClick={() => setActiveNotice(notice)}>
                                                <td>
                                                    <div className="row align-center">
                                                        <FontAwesomeIcon className="notice-badge" icon={faCrown} />
                                                        <p>{notice.author}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <h4>{notice.title}</h4>
                                                </td>
                                                <td>
                                                    <div className="row align-center">
                                                        <p>{formatDate(notice.created_at)}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                            <div className="page-numbers fixed-bottom">
                                {startPage > 1 &&
                                    <button
                                        onClick={(e) => handlePageClick(e, startPage - 1)}>
                                        {"<"}
                                    </button>
                                }
                                {Array.from({length: endPage-startPage+1}, (_, i) => (
                                    <button
                                        key={i+startPage}
                                        onClick={(e) => handlePageClick(e, i+startPage)}
                                        className={currentPage === i+startPage ? 'active' : ''}>
                                        {i+startPage}
                                    </button>
                                ))}
                                {endPage < pageCount &&
                                    <button
                                        onClick={(e) => handlePageClick(e, endPage + 1)}>
                                        {">"}
                                    </button>
                                }
                            </div>
                        </div>
                    ) : (
                        <div className="notice-detail">
                            <button className="back-button" onClick={() => setActiveNotice(null)}>
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </button>
                            <h2 className="notice-detail-title">{activeNotice.title}</h2>
                            <hr/>
                            <div className="row">
                                <p className="notice-detail-author">
                                    <FontAwesomeIcon className="notice-badge" icon={faCrown} />
                                    {activeNotice.author}
                                </p>
                                <div className="grow-size"></div>
                                <p className="notice-detail-date">{formatDate(activeNotice.created_at)}</p>
                            </div>
                            <hr/>
                            <div className="content-container">
                                <p className="notice-detail-content">{activeNotice.content}</p>
                            </div>

                        </div>
                    )}
                </div>

            </div>

            <div className="seperator"></div>
        </div>
    );
}

export default HOMERegular;
