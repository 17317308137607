import "../../styles/Technician.css"
import React, {useContext, useState} from "react";
import Main from "./main";
function AssignWork() {
    return (
        <>
            <Main/>
        </>
    );
}

export default AssignWork;
