// Input.js
import React from 'react';
import '../styles/BaseTextArea.css';

function BaseTextArea(props) {
    return (
        <textarea className="textarea-base" {...props} />
    );
}

export default BaseTextArea;
