import React, {useState, useEffect, useContext, useRef} from "react";
import ResizeObserver from 'resize-observer-polyfill';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faArrowLeft, faClose, faCrown, faHome, faSearch, faUsers} from "@fortawesome/free-solid-svg-icons";
import BaseInput from "../../BaseComponents/BaseInput";
import {BaseButton, BaseButtonAwesome, BaseButtonAwesomeTrans, BaseButtonField} from "../../BaseComponents/BaseButton";
import {GlobalContext, TechnicianContext} from "../../pages/AdmHomePage";
import {BaseLoading, BaseLoadingInContainer} from "../../BaseComponents/BaseLoading";
import { FixedSizeList as List } from 'react-window';
import BaseNoData from '../../BaseComponents/BaseNoData';
import {BaseFixedImage} from "../../BaseComponents/BaseImage";
import logo from "../../images/logo.png";
import {BaseAlert, BaseAlertDanger, BaseAlertWarn} from "../../BaseComponents/BaseAlert";
import RegModal from "../../modals/technician/RegModal";
import ModifyModal from "../../modals/technician/ModifyModal";
import {backendServer} from "../../App";
import InfoModal from "../../modals/technician/InfoModal";
const widthPercentage = ['100px','200px','300px','150px', '200px', '650px','150px','150px'];
// 상수 정의
const ITEM_SIZE = 44;  // 각 항목의 높이를 조정합니다.

function Main() {
    const { currentTechnicians, setCurrentTechnicians, activeHistory, setActiveHistory } = useContext(TechnicianContext);
    const { userData, setUserData, isInit, setIsInit, timeRemainingRef } = useContext(GlobalContext);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ modalType, setModalType ] = useState(-1);

    const [ isRegLoading, setIsRegLoading ] = useState(false);
    const divRef = useRef(null);
    const [listHeight, setListHeight] = useState(468);
    const [containerWidth, setContainerWidth] = useState(0);
    const containerRef = useRef(null);
    const [ isModal, setIsModal ] = useState(false);
    const [ selectedValues, setSelectedValues ] = useState({
        ID: '', PWD: '', name: "", phone: "", email: "", user_id: 0,
    });
    const [ values, setValues ] = useState({
        ID: '', PWD: '', name: "", phone: "", email: "", user_id: 0,
    });
    const [ searchValues, setSearchValues ] = useState({
        search_date_start: '', search_date_end: '', name: "", phone: ""
    });

    const [ selectedTechnician, setSelectedTechnician ] = useState(null);

    const [ alertTimeout, setAlertTimeout ] = useState(null);
    const [ isAlert, setIsAlert] = useState(-1);
    const [alertMsg, setAlertMsg] = useState("");

    const headerData = {
        name: '유지보수 작업자',
        phone: '연락처',
        email: '이메일',
        username: '아이디',
        created_at: '등록일',
        recentHistory: '최근이력',
        totalHistory: '작업이력',
        modify: '수정',
    };
    const setIsAlertWithTimeout = (alertType) => {
        // 이미 설정된 setTimeout이 있다면 취소합니다.
        if (alertTimeout) {
            clearTimeout(alertTimeout);
        }

        setIsAlert(alertType);

        // 3.5초 후에 알림을 사라지게 만듭니다.
        const timeoutId = setTimeout(() => {
            setIsAlert(-1);
        }, 3500);

        // 새로 설정된 setTimeout의 ID를 저장합니다.
        setAlertTimeout(timeoutId);
    }

    const getTechnicians = async () => {
        setIsLoading(true);
        try {
            const requestBody = {
                company_id: userData.company_id,
                searchCriteria: searchValues  // 추가된 부분
            };

            const response = await fetch(`https://${backendServer}/get-technicians`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody),
                credentials: 'include'
            });

            if (response.ok) {
                const technicians = await response.json();
                setCurrentTechnicians(prevState => [headerData, ...technicians]);
            } else {
                const errorData = await response.json();
                if(errorData.error === 'SessionExpired') {
                    setUserData(null);
                    setIsInit(true);
                    throw new Error('Session has expired.'); // 이후의 처리를 중단하고 catch로 이동하기 위해 에러를 발생시킵니다.
                }else if(errorData.error === 'NotLoggedIn'){
                    setUserData(null);
                    setIsInit(true);
                }
            }
        } catch (error) {
            setAlertMsg("서버에 연결할 수 없습니다. 관리자에게 문의해보세요.");
            setIsAlertWithTimeout(2);
        } finally {
            setIsLoading(false);
            timeRemainingRef.current = (60 * 60);
        }
    }

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setContainerWidth(entry.contentRect.width);
            }
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [containerRef]);

    const handleOpenModal = (type) => {
        setModalType(type);
        setIsModal(true);
    }

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setListHeight(entry.contentRect.height);
            }
        });

        if (divRef.current) {
            resizeObserver.observe(divRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [divRef]);

    const handleChange = (event) => {
        const { name, value } = event.target;

        let prevValue;
        if(modalType === 0){
            prevValue = values[name];
        }else if(modalType === 1){
            prevValue = selectedValues[name];
        }else {
            prevValue = searchValues[name];
        }

        if (name === "phone") {
            // 입력값이 줄어들었을 때는 형식화를 건너뛰기
            if (value.length < prevValue.length) {
                if(modalType === 0){
                    setValues(prevValues => ({
                        ...prevValues,
                        phone: value
                    }));
                }else if(modalType === 1){
                    setSelectedValues(prevValues => ({
                        ...prevValues,
                        phone: value
                    }));
                }else{
                    setSearchValues(prevValues => ({
                        ...prevValues,
                        phone: value
                    }));
                }
            } else {
                if(prevValue.replaceAll(/-/g,"").length === 11) return;

                if(modalType === 0){
                    setValues(prevValues => ({
                        ...prevValues,
                        phone: formatPhoneNumber(value)
                    }));
                }else if(modalType === 1){
                    setSelectedValues(prevValues => ({
                        ...prevValues,
                        phone: formatPhoneNumber(value)
                    }));
                }else{
                    setSearchValues(prevValues => ({
                        ...prevValues,
                        phone: formatPhoneNumber(value)
                    }));
                }
            }
        } else {
            if(modalType === 0){
                setValues(prevValues => ({
                    ...prevValues,
                    [name]: value
                }));
            }else if(modalType === 1){
                setSelectedValues(prevValues => ({
                    ...prevValues,
                    [name]: value
                }));
            }else {
                setSearchValues(prevValues => ({
                    ...prevValues,
                    [name]: value
                }));
            }
        }
    }

    const formatPhoneNumber = (input) => {
        const nums = input.replace(/\D/g, "");  // 숫자만 추출

        // 휴대폰 번호 형식
        if ((nums.startsWith("010") || nums.startsWith("011") || nums.startsWith("019")) && nums.length > 3) {
            return `${nums.slice(0, 3)}-${nums.slice(3, 7)}-${nums.slice(7)}`;
        }

        return nums;  // 기본적인 형태를 유지
    };

    const handleSubmit = async () => {
        if(values.ID === ""){
            setAlertMsg("아이디를 입력해주세요.");
            setIsAlertWithTimeout(1);
        }else if(values.PWD === ""){
            setAlertMsg("비밀번호를 입력해주세요.");
            setIsAlertWithTimeout(1);
        }else if(values.name === ""){
            setAlertMsg("이름을 입력해주세요.");
            setIsAlertWithTimeout(1);
        }else if(values.phone === ""){
            setAlertMsg("연락처를 입력해주세요.");
            setIsAlertWithTimeout(1);
        }else if(values.email === ""){
            setAlertMsg("이메일을 입력해주세요.");
            setIsAlertWithTimeout(1);
        }else{
            setIsRegLoading(true);

            try {
                const sendData = {...values, company_id: userData.company_id };

                const response = await fetch(`https://${backendServer}/register/technicians`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(sendData),
                    credentials: 'include'
                });

                if (response.ok) {
                    setValues({
                        ID: '', PWD: '', name: "", phone: "", email: "", user_id: ""
                    });
                    setAlertMsg("등록에 성공했습니다.");
                    setIsAlertWithTimeout(0);
                } else {
                    const errorData = await response.json();
                    if(errorData.error === 'SessionExpired') {
                        setUserData(null);
                        setIsInit(true);
                        throw new Error('Session has expired.'); // 이후의 처리를 중단하고 catch로 이동하기 위해 에러를 발생시킵니다.
                    }else if(errorData.error === 'DuplicateEntry'){
                        setAlertMsg("아이디가 중복되거나 이미 등록된 작업자 입니다.");
                        setIsAlertWithTimeout(2);
                    }else if(errorData.error === 'NotLoggedIn'){
                        setUserData(null);
                        setIsInit(true);
                    }
                }
            } catch (error) {
                setAlertMsg("서버에 연결할 수 없습니다. 관리자에게 문의해보세요.");
                setIsAlertWithTimeout(2);
            } finally {
                setIsRegLoading(false);
            }
        }
    }

    const handleModify = async () => {
        if(selectedValues.ID === ""){
            setAlertMsg("아이디를 입력해주세요.");
            setIsAlertWithTimeout(1);
        }else if(selectedValues.name === ""){
            setAlertMsg("이름을 입력해주세요.");
            setIsAlertWithTimeout(1);
        }else if(selectedValues.phone === ""){
            setAlertMsg("연락처를 입력해주세요.");
            setIsAlertWithTimeout(1);
        }else if(selectedValues.email === ""){
            setAlertMsg("이메일을 입력해주세요.");
            setIsAlertWithTimeout(1);
        }else if(selectedValues.PWD.length < 4 && selectedValues.PWD !== '') {
            setAlertMsg("비밀번호는 4자 이상이여야 합니다.");
            setIsAlertWithTimeout(1);
        } else {
            setIsRegLoading(true);

            try {
                const sendData = {...selectedValues, company_id: selectedTechnician.company_id };

                const response = await fetch(`https://${backendServer}/modify/technicians`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(sendData),
                    credentials: 'include'
                });

                if (response.ok) {
                    setAlertMsg("수정을 완료했습니다.");
                    setIsAlertWithTimeout(0);
                } else {
                    const errorData = await response.json();
                    if(errorData.error === 'SessionExpired') {
                        setUserData(null);
                        setIsInit(true);
                        throw new Error('Session has expired.'); // 이후의 처리를 중단하고 catch로 이동하기 위해 에러를 발생시킵니다.
                    }else if(errorData.error === 'NotFound'){
                        setAlertMsg("사용자를 찾을 수 없습니다.");
                        setIsAlertWithTimeout(2);
                    }else if(errorData.error === 'NotLoggedIn'){
                        setUserData(null);
                        setIsInit(true);
                    }
                }
            } catch (error) {
                setAlertMsg("서버에 연결할 수 없습니다. 관리자에게 문의해보세요.");
                setIsAlertWithTimeout(2);
            } finally {
                setIsRegLoading(false);
            }
        }
    }

    const handleDelete = async () => {
        const confirmLogout = window.confirm('해당 작업자를 삭제하시겠습니까?');

        if (confirmLogout) {
            setIsRegLoading(true);

            try {
                const sendData = {company_id: selectedTechnician.company_id, technicians: [selectedTechnician.user_id] };

                const response = await fetch(`https://${backendServer}/delete/technicians`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(sendData),
                    credentials: 'include'
                });

                if (response.ok) {
                    setAlertMsg("삭제를 완료했습니다.");
                    setIsAlertWithTimeout(0);
                    handleCloseModal();
                    getTechnicians();
                    timeRemainingRef.current = (60 * 60);
                } else {
                    const errorData = await response.json();
                    if(errorData.error === 'SessionExpired') {
                        setUserData(null);
                        setIsInit(true);
                        throw new Error('Session has expired.'); // 이후의 처리를 중단하고 catch로 이동하기 위해 에러를 발생시킵니다.
                    }else if(errorData.error === 'NotFound'){
                        setAlertMsg("사용자를 찾을 수 없습니다.");
                        setIsAlertWithTimeout(2);
                    }else if(errorData.error === 'NotLoggedIn'){
                        setUserData(null);
                        setIsInit(true);
                    }
                }
            } catch (error) {
                setAlertMsg("서버에 연결할 수 없습니다. 관리자에게 문의해보세요.");
                setIsAlertWithTimeout(2);
            } finally {
                setIsRegLoading(false);
            }
        } else {
            return;
        }

    }

    const handleCloseModal = () => {
        setModalType(-1);
        setIsModal(false);
    }

    function formatDate(isoString) {
        return isoString.split('T')[0];
    }

    function TableRow({ index, style, data }) {
        const technician = data[index];

        const rowStyle = {
            ...style,
            top: ITEM_SIZE * index - 16,
        };

        const headerStyle = {
            height: '28px'
        };

        const bodyStyle = {
            backgroundColor: index % 2 === 1 ? '#e8e8e8' : 'transparent',
        }

        if (containerWidth < 1210) {
            headerStyle.width = '1200px';
            bodyStyle.width = '1200px';
        }

        const getStyle = (widthPercentage, isFirst) => {
            if (containerWidth < 1210){
                if(isFirst){
                    return { width: widthPercentage,
                        backgroundColor: index % 2 === 1 ? '#e8e8e8' : '#FFFFFF',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }else{
                    return { width: widthPercentage,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }
            }else{
                if(isFirst){
                    return { flex: widthPercentage,
                        backgroundColor: index % 2 === 1 ? '#e8e8e8' : '#FFFFFF',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }else{
                    return { flex: widthPercentage,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap' };
                }
            }
        }

        const getMaintenance = (technician) => {
            if(technician.history_id){
                if(technician.start_time && technician.end_time){
                    return `[${technician.machine_name}] ${technician.maintenance_details}`
                }else if(technician.start_time && !technician.end_time){
                    return `[${technician.machine_name}] 작업중`
                }
            }else{
                return "없음";
            }
        }

        const handleCheckChange = (technicianIndex) => {
            // 현재 machines 상태의 복사본을 만듭니다.
            const newTechnicians = [...currentTechnicians];

            // 체크 상태를 토글합니다.
            newTechnicians[technicianIndex] = {
                ...newTechnicians[technicianIndex],
                check: !newTechnicians[technicianIndex].check
            };

            // 상태를 업데이트합니다.
            setCurrentTechnicians(newTechnicians);
        };

        const handleToggleAllChecks = () => {
            // 첫 번째 machine을 제외하고, 'wait' 상태인 machine이 모두 체크되어 있는지 확인합니다.
            const allChecked = currentTechnicians.slice(1).every(technician => technician.check);

            // 체크 상태를 반대로 설정합니다 (전체 선택 또는 전체 해제).
            // 'wait' 상태가 아닌 machine은 변경하지 않습니다.
            const newTechnicians = currentTechnicians.map((technician, index) => {
                if (index === 0) return technician; // 첫 번째 machine이나 'wait' 상태가 아닌 machine은 변경하지 않습니다.
                return { ...technician, check: !allChecked };
            });

            // 상태를 업데이트합니다.
            setCurrentTechnicians(newTechnicians);
        };

        return (
            <>
                {index === 0 ? (
                    <>
                        <div className="row full-size-width table-header" style={headerStyle}>
                            <div className="column align-center" style={{width: "50px", margin: 0, padding: 0}} onClick={handleToggleAllChecks}>
                                <input
                                    type="checkbox"
                                    checked={
                                        currentTechnicians.slice(1).every(machine => machine.check)
                                    }
                                    onChange={handleToggleAllChecks}
                                    className="text-align-center"
                                />
                            </div>
                            <div className="column first-child" style={getStyle(widthPercentage[2])} onClick={() => {
                                setSelectedTechnician(technician);
                                const values = {
                                    name: technician.name,
                                    phone: technician.phone,
                                    ID: technician.username,
                                    PWD: '',
                                    email: technician.email,
                                    user_id: technician.user_id,
                                }
                                setSelectedValues(values);
                                handleOpenModal(1);
                            }}>
                                <p className="text-align-center">{technician.name}</p>
                            </div>
                            <div className="column align-center" style={getStyle(widthPercentage[3])}>
                                <p className="text-align-center">{technician.username}</p>
                            </div>
                            <div className="column align-center" style={getStyle(widthPercentage[1])}>
                                <p className="text-align-center">{technician.phone}</p>
                            </div>
                            <div className="column align-center" style={getStyle(widthPercentage[2])}>
                                <p className="text-align-center">{technician.email}</p>
                            </div>

                            <div className="column align-center" style={getStyle(widthPercentage[4])}>
                                <p className="text-align-center">{technician.created_at}</p>
                            </div>
                            <div className="column align-center" style={getStyle(widthPercentage[6])}>
                                <p className="text-align-center">{technician.totalHistory}</p>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div style={rowStyle}>
                            <div className="row full-size-width table-item" style={bodyStyle}>
                                <div className="column align-center" style={{width: "50px", margin: 0, padding: 0}}>
                                    <input
                                        type="checkbox"
                                        checked={technician.check}
                                        className="text-align-center"
                                        onChange={() => handleCheckChange(index)}
                                    />
                                </div>
                                <div className="column align-center first-column" style={getStyle(widthPercentage[2], true)} onClick={() => {
                                    setSelectedTechnician(technician);
                                    const values = {
                                        name: technician.name,
                                        phone: technician.phone,
                                        ID: technician.username,
                                        PWD: '',
                                        email: technician.email,
                                        user_id: technician.user_id,
                                    }
                                    setSelectedValues(values);
                                    handleOpenModal(1);
                                }}>
                                    <p>{technician.name}</p>
                                </div>
                                <div className="column align-center" style={getStyle(widthPercentage[3])} onClick={() => {
                                    setSelectedTechnician(technician);
                                    const values = {
                                        name: technician.name,
                                        phone: technician.phone,
                                        ID: technician.username,
                                        PWD: '',
                                        email: technician.email,
                                        user_id: technician.user_id,
                                    }
                                    setSelectedValues(values);
                                    handleOpenModal(1);
                                }}>
                                    <p>{technician.username}</p>
                                </div>
                                <div className="column align-center" style={getStyle(widthPercentage[1])} onClick={() => {
                                    setSelectedTechnician(technician);
                                    const values = {
                                        name: technician.name,
                                        phone: technician.phone,
                                        ID: technician.username,
                                        PWD: '',
                                        email: technician.email,
                                        user_id: technician.user_id,
                                    }
                                    setSelectedValues(values);
                                    handleOpenModal(1);
                                }}>
                                    <p>{technician.phone}</p>
                                </div>
                                <div className="column align-center" style={getStyle(widthPercentage[2])} onClick={() => {
                                    setSelectedTechnician(technician);
                                    const values = {
                                        name: technician.name,
                                        phone: technician.phone,
                                        ID: technician.username,
                                        PWD: '',
                                        email: technician.email,
                                        user_id: technician.user_id,
                                    }
                                    setSelectedValues(values);
                                    handleOpenModal(1);
                                }}>
                                    <p>{technician.email}</p>
                                </div>
                                <div className="column align-center" style={getStyle(widthPercentage[4])} onClick={() => {
                                    setSelectedTechnician(technician);
                                    const values = {
                                        name: technician.name,
                                        phone: technician.phone,
                                        ID: technician.username,
                                        PWD: '',
                                        email: technician.email,
                                        user_id: technician.user_id,
                                    }
                                    setSelectedValues(values);
                                    handleOpenModal(1);
                                }}>
                                    <p>{formatDate(technician.created_at)}</p>
                                </div>
                                <div className="column align-center" style={getStyle(widthPercentage[6])}>
                                    <BaseButtonField onClick={() => showTotalHistory( technician.user_id, technician.name )}>이력보기</BaseButtonField>
                                </div>
                            </div>
                        </div>
                    </>
                )}

            </>
        );
    }

    const showTotalHistory = async (user_id, name) => {
        const historyHeader = {
            status: "상태",
            user_name: "작업자",
            user_phone: "작업자 연락처",
            requesting_company_name: "소유 기업",
            requesting_company_phone: "회사 번호",
            requested_company_name: "유지보수 기업",
            requested_company_phone: "회사 번호",
            machine_name: "플랜트",
            start_time: "과거 작업일",
            end_time: "종료 시간",
            maintenance_details: "유지보수 내용",
            special_notes: "특이사항",
            images: "작업사진",
            request_date: "요청날짜",
            accepted_date: "수락날짜",
        }
        setIsLoading(true);

        try{
            const response = await fetch(`https://${backendServer}/get-histories/technician?user_id=${user_id}`, {
                method: 'GET',
                credentials: 'include'
            });

            if (response.ok) {
                const data  = await response.json();
                setActiveHistory({ user_id: user_id, user_name: name, datas: [ historyHeader , ...data ] });
            } else {
                const errorData = await response.json();
                if(errorData.error === 'SessionExpired') {
                    setUserData(null);
                    setIsInit(true);
                    throw new Error('Session has expired.'); // 이후의 처리를 중단하고 catch로 이동하기 위해 에러를 발생시킵니다.
                }else if(errorData.error === 'NotFound'){
                    setAlertMsg("사용자를 찾을 수 없습니다.");
                    setIsAlertWithTimeout(2);
                }else if(errorData.error === 'NotLoggedIn'){
                    setUserData(null);
                    setIsInit(true);
                }
            }
        }catch (e){
            setAlertMsg("서버에 연결할 수 없습니다. 관리자에게 문의해보세요.");
            setIsAlertWithTimeout(2);
        }finally {
            setIsLoading(false);
        }


    }

    return (
        <div className="full-size column align-center">
            <div className="seperator-half"></div>
            <div className="row align-center-left main-table-header full-size-width">
                <FontAwesomeIcon className="menu" icon={faUsers} onClick={() => setActiveHistory(null)}/>
                <div className="seperator-horz"></div>
                <p className="medium-title bold">유지보수작업자 관리</p>
            </div>
            <div className="row full-size-width">
                <div className="column grow-size align-center search-info-container">
                    <p className="normal-info bold text-align-left full-size-width">정보 기준</p>
                    <div className="row full-size-width">
                        <BaseInput
                            name="name"
                            aria-description="condition"
                            onChange={handleChange}
                            value={searchValues.name}
                            placeholder="유지보수 작업자"
                        />
                        <div className="seperator-horz"></div>
                        <BaseInput
                            name="phone"
                            aria-description="condition"
                            onChange={handleChange}
                            value={searchValues.phone}
                            placeholder="연락처"
                        />
                    </div>
                </div>
                <div className="column grow-size align-center search-info-container">
                    <p className="normal-info bold text-align-left full-size-width">등록일 기준</p>
                    <div className="row full-size-width">
                        <BaseInput
                            type="date"
                            data-placeholder="검색시작일"
                            aria-description="condition"
                            aria-required="true"
                            name="search_date_start"
                            onChange={handleChange}
                            value={searchValues.search_date_start}
                        />
                        <div className="seperator-horz"></div>
                        <BaseInput
                            type="date"
                            data-placeholder="검색종료일"
                            aria-description="condition"
                            aria-required="true"
                            name="search_date_end"
                            onChange={handleChange}
                            value={searchValues.search_date_end}
                        />
                    </div>

                </div>
                <div className="column align-center buttons">
                    <BaseButtonAwesome onClick={getTechnicians} icon={faSearch}/>
                </div>
            </div>
            <div className="column grow-size full-size-width">
                <div className="row full-size-width">
                    <div className="column full-size-width align-left-center" style={{paddingLeft: "1.5rem"}}>
                        <div className="buttons">
                            <BaseButtonField
                                onClick={async () => {
                                    const technicianIds = currentTechnicians.slice(1)
                                        .filter(technician => technician.check)
                                        .map(technician => technician.user_id);

                                    const technicianNames = currentTechnicians.slice(1)
                                        .filter(technician => technician.check)
                                        .map(technician => technician.name);

                                    if(technicianIds.length === 0 ) {
                                        setAlertMsg("선택된 설비가 없습니다.");
                                        setIsAlertWithTimeout(1);
                                    }else {
                                        let confirmLogout;
                                        if(technicianNames.length > 1) {
                                            confirmLogout = window.confirm(`선택한 ${technicianNames[0]} 외 ${technicianNames.length - 1} 명의 작업자를 삭제하시겠습니까?`);
                                        }else {
                                            confirmLogout = window.confirm(`선택한 ${technicianNames[0]} 작업자를 삭제하시겠습니까?`);
                                        }

                                        if (confirmLogout) {
                                            setIsRegLoading(true);

                                            try {
                                                const sendData = {company_id: userData.company_id, technicians: technicianIds};

                                                const response = await fetch(`https://${backendServer}/delete/technicians`, {
                                                    method: 'POST',
                                                    headers: { 'Content-Type': 'application/json' },
                                                    body: JSON.stringify(sendData),
                                                    credentials: 'include',
                                                });

                                                if (response.ok) {
                                                    setAlertMsg("삭제를 완료했습니다.");
                                                    setIsAlertWithTimeout(0);
                                                    handleCloseModal();
                                                    getTechnicians();
                                                    timeRemainingRef.current = (60 * 60);
                                                } else {
                                                    const errorData = await response.json();
                                                    if(errorData.error === 'SessionExpired') {
                                                        setUserData(null);
                                                        setIsInit(true);
                                                        throw new Error('Session has expired.'); // 이후의 처리를 중단하고 catch로 이동하기 위해 에러를 발생시킵니다.
                                                    }else if(errorData.error === 'NotFound'){
                                                        setAlertMsg("사용자를 찾을 수 없습니다.");
                                                        setIsAlertWithTimeout(2);
                                                    }else if(errorData.error === 'NotLoggedIn'){
                                                        setUserData(null);
                                                        setIsInit(true);
                                                    }
                                                }
                                            } catch (error) {
                                                setAlertMsg("서버에 연결할 수 없습니다. 관리자에게 문의해보세요.");
                                                setIsAlertWithTimeout(2);
                                            } finally {
                                                setIsRegLoading(false);
                                            }
                                        } else {
                                            return;
                                        }
                                    }
                                }}
                                className="column align-center" >
                                <p style={{margin: "0 0.5rem"}}>일괄 삭제</p>
                            </BaseButtonField>
                        </div>
                    </div>
                    <div className="column full-size-width align-right-center">
                        <div className="buttons">
                            <BaseButtonAwesome onClick={() => handleOpenModal(0)} className="column align-center button-base-awesome" aria-description="attach" icon={faAdd}/>
                        </div>
                    </div>
                </div>
                <div className="column main-table-body" ref={containerRef}>
                    {isLoading && (
                        <>
                            <BaseLoading/>
                        </>
                    )}
                    <div className="table-container-h1" ref={divRef}>
                        <>
                            {currentTechnicians.length === 1 ? (
                                <>
                                    <BaseNoData/>
                                </>
                            ) : (
                                <>
                                    <div className="row table-body-container" >
                                        <List
                                            width={containerWidth}
                                            height={listHeight}
                                            className="table full-size-width"
                                            itemCount={currentTechnicians.length}
                                            itemSize={ITEM_SIZE}
                                            itemData={currentTechnicians}
                                        >
                                            {TableRow}
                                        </List>
                                    </div>
                                </>
                            )}
                        </>
                    </div>
                </div>
            </div>
            <div className="seperator"></div>
            { isModal && modalType === 0 && (
                <>
                    <div className="modal full-size column align-center">
                        <div className="register-form">
                            {isRegLoading ? (
                                <>
                                    <BaseLoadingInContainer/>
                                </>
                            ):(
                                <>
                                    <RegModal
                                        values={values}
                                        closeClick={() => {
                                            handleCloseModal()
                                            setValues({
                                                ID: '', PWD: '', name: "", phone: "", email: "", user_id: ""
                                            })
                                        }}
                                        handleChange={handleChange}
                                        handleSubmit={handleSubmit}
                                    />
                                </>
                            )}

                        </div>
                    </div>
                </>
            )}
            { isModal && modalType === 1 && (
                <>
                    <div className="modal full-size column align-center">
                        <div className="register-form">
                            {isRegLoading ? (
                                <>
                                    <BaseLoadingInContainer/>
                                </>
                            ):(
                                <>
                                    <ModifyModal
                                        values={selectedValues}
                                        closeClick={() => {
                                            handleCloseModal()
                                            setSelectedValues({
                                                ID: '', PWD: '', name: "", phone: "", email: "", user_id: 0,
                                            })
                                            setSelectedTechnician(null);
                                        }}
                                        handleChange={handleChange}
                                        handleModify={handleModify}
                                        handleDelete={handleDelete}
                                    />
                                </>
                            )}

                        </div>
                    </div>
                </>
            )}
            { isModal && modalType === 2 && selectedTechnician && (
                <>
                    <div className="modal full-size column align-center">
                        <div className="register-form">
                            {isRegLoading ? (
                                <>
                                    <BaseLoadingInContainer/>
                                </>
                            ):(
                                <>
                                    <InfoModal
                                        selectedTechnician = {selectedTechnician}
                                        closeClick={() => {
                                            handleCloseModal()
                                            setSelectedTechnician(null)
                                        }}
                                    />
                                </>
                            )}

                        </div>
                    </div>
                </>
            )}
            {isAlert === 0 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                <>
                    <BaseAlert msg={alertMsg}></BaseAlert>
                </>
            )}
            {isAlert === 1 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                <>
                    <BaseAlertWarn msg={alertMsg}></BaseAlertWarn>
                </>
            )}
            {isAlert === 2 && (  // 인증번호가 전송되면 인증번호 입력 필드 렌더링
                <>
                    <BaseAlertDanger msg={alertMsg}></BaseAlertDanger>
                </>
            )}
        </div>
    );
}

export default Main;
